import { useEffect, useState, useContext } from "react";

import { useSearchParams } from "react-router-dom";

import { filterContext } from "features/filter/context/filterContext";
import { authContext } from "features/auth";

import { useRequest, isResponseErrorPredicate } from "api";

import { FilterList } from "../FilterList";

import { LoaderOverlay } from "components/UI/LoaderOverlay";

import { LOAD_PRODUCTS_COUNT_TIMEOUT } from "features/filter/data/constants";

import { FilterProps, FilterParamState, RangeState } from "./types";

import "./Filter.styles.scss";

let isInit = true;
let minPrice = 0;
let maxPrice = 0;

function Filter({
	searchParamsRequired,
	authRequired,
	getFilterParamsService,
	getFilteredItemsCountService,
	getFilteredItemsService,
}: FilterProps) {
	const [filterParams, setFilterParams] = useState<FilterParamState[]>([]);
	const [filteredItemsCount, setFilteredItemsCount] = useState(0);

	const { authToken, authenticated } = useContext(authContext);

	const {
		addedFilterParams,
		onApplyFilterParams,
		onResetFilterParams,
		onCloseFilterMenu,
	} = useContext(filterContext);

	const [searchParams] = useSearchParams();

	const searchCatalogType = searchParams.get("type");

	const { request: requestFilterPearams, loading: loadingFilterParams } =
		useRequest();
	const { request } = useRequest();

	useEffect(() => {
		if (
			(authRequired && !authenticated) ||
			// filterParams.length ||
			(searchParamsRequired && !searchCatalogType)
		) {
			return;
		}

		const fetchFilterParams = async () => {
			const result = await requestFilterPearams({
				service: () =>
					getFilterParamsService({
						token: authToken,
						params: { type: [searchCatalogType!] },
					}),
			});

			if (result && !isResponseErrorPredicate(result)) {
				const formattedResult = result.map((param) => {
					if (param.type === "checkbox") {
						const formattedItems = param.items.map((item) => ({
							...item,
							checked: false,
						}));

						return {
							...param,
							items: formattedItems,
						};
					} else if (param.type === "range") {
						const formattedItems = param.items.map((item) => ({
							...item,
							value: 0,
						}));

						return {
							...param,
							items: formattedItems,
						};
					}

					return param;
				});

				setFilterParams(formattedResult);
			}
		};

		fetchFilterParams();
	}, [
		requestFilterPearams,
		getFilterParamsService,
		authToken,
		authenticated,
		authRequired,
		searchCatalogType,
		searchParamsRequired,
	]);

	useEffect(() => {
		if (
			(authRequired && !authenticated) ||
			(!searchCatalogType && searchParamsRequired)
		) {
			return;
		}

		const fetchFilteredItemsCount = async () => {
			const result = await request({
				service: () =>
					getFilteredItemsCountService({
						params: { ...addedFilterParams, type: [searchCatalogType!] },
						token: authToken,
					}),
			});

			if (result && !isResponseErrorPredicate(result)) {
				setFilteredItemsCount(result.count);
			}
		};

		if (isInit) {
			isInit = false;

			fetchFilteredItemsCount();
		}

		const timerId = setTimeout(() => {
			fetchFilteredItemsCount();
		}, LOAD_PRODUCTS_COUNT_TIMEOUT);

		return () => {
			clearTimeout(timerId);
		};
	}, [
		request,
		getFilteredItemsCountService,
		addedFilterParams,
		authToken,
		authenticated,
		authRequired,
		searchCatalogType,
		searchParamsRequired,
	]);

	useEffect(() => {
		if (
			(authRequired && !authenticated) ||
			(!searchCatalogType && searchParamsRequired)
		) {
			return;
		}

		let page = 1;
		let lastPage = 1;

		const fetchFilterParams = async () => {
			while (lastPage >= page) {
				const result = await request({
					service: () =>
						getFilteredItemsService({
							params: { perPage: [10000], type: [searchCatalogType!] },
							token: authToken,
						}),
				});

				if (result && !isResponseErrorPredicate(result)) {
					lastPage = result.lastPage;
					page++;

					result.data.forEach((item, index) => {
						if (!("minPrice" in item) || !("maxPrice" in item)) return;
						if (index === 0) {
							minPrice = item.minPrice;
							maxPrice = item.maxPrice;
							return;
						}

						minPrice = Math.min(minPrice, item.minPrice);
						maxPrice = Math.max(maxPrice, item.maxPrice);
					});

					setFilterParams((prevState) =>
						prevState.map((item) => {
							if (item.type === "range") {
								const newItems: RangeState[] = [
									{ ...item.items[0], value: minPrice },
									{ ...item.items[1], value: maxPrice },
								];

								return { ...item, items: newItems };
							}

							return item;
						}),
					);
				} else {
					return;
				}
			}
		};

		fetchFilterParams();
	}, [
		request,
		getFilteredItemsService,
		authToken,
		authenticated,
		authRequired,
		searchCatalogType,
		searchParamsRequired,
	]);

	const applyFilterParamsHandler = () => {
		onApplyFilterParams();
		onCloseFilterMenu();
	};

	const resetFilterParamsHandler = () => {
		onResetFilterParams();

		setFilterParams((prevState) => {
			return prevState.map((param) => {
				if (param.type === "checkbox") {
					return {
						...param,
						items: param.items.map((item) => ({ ...item, checked: false })),
					};
				}
				if (param.type === "range") {
					const newItems: RangeState[] = [
						{ ...param.items[0], value: minPrice },
						{ ...param.items[1], value: maxPrice },
					];

					return {
						...param,
						items: newItems,
					};
				}

				return param;
			});
		});
	};

	const changeCheckboxStateHandler = (name: string, id: number) => {
		setFilterParams((prevState) => {
			return prevState.map((param) => {
				if (param.type === "checkbox") {
					const foundFilterOptionIndex = param.items.findIndex(
						(item) => item.id === id && param.name === name,
					);

					if (foundFilterOptionIndex === -1) return param;

					const oldFilterOption = param.items[foundFilterOptionIndex];
					const newFilterOption = {
						...oldFilterOption,
						checked: !oldFilterOption.checked,
					};
					const newItems = param.items.slice();

					newItems.splice(foundFilterOptionIndex, 1, newFilterOption);

					return { ...param, items: newItems };
				}

				return param;
			});
		});
	};

	if (loadingFilterParams || isInit) {
		return <LoaderOverlay />;
	}

	return (
		<div className="filter-wrapper">
			<div className="filter">
				<div className="filter__wrap">
					<div className="filter__head">
						<h3 className="filter__title">Фильтры</h3>
					</div>
					<FilterList
						params={filterParams}
						onChangeCheckboxState={changeCheckboxStateHandler}
					/>
				</div>
			</div>
			<div className="filter-buttons">
				<button
					type="button"
					className="filter-show"
					onClick={applyFilterParamsHandler}
				>
					Показать ({filteredItemsCount})
				</button>
				<button
					type="button"
					className="filter-reset"
					onClick={resetFilterParamsHandler}
				>
					Сбросить
				</button>
			</div>
		</div>
	);
}

export { Filter };
