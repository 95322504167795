import { createContext, useCallback, useState } from "react";

import { INIT_PAGES_SEO } from "features/seo/data/constants";

import {
	SeoContextProviderProps,
	InitSeoContext,
	SetSeoPayload,
	PageSeoData,
} from "./types";

const initSeoContext: InitSeoContext = {
	seo: INIT_PAGES_SEO,
	onSetSeo: () => {},
};

const seoContext = createContext(initSeoContext);

function SeoContextProvider({ children }: SeoContextProviderProps) {
	const [seo, setSeo] = useState<Record<string, PageSeoData>>(INIT_PAGES_SEO);

	const setSeoHandler = useCallback((payload: SetSeoPayload) => {
		const { seo } = payload;

		setSeo((prevState) => ({ ...prevState, ...seo }));
	}, []);

	return (
		<seoContext.Provider
			value={{
				seo,
				onSetSeo: setSeoHandler,
			}}
		>
			{children}
		</seoContext.Provider>
	);
}

export { SeoContextProvider, seoContext };
