import { useState, useContext, useEffect } from "react";

import {
	useRequest,
	getFilteredArticleList,
	ShortArticleItemData,
} from "api";

import { filterContext } from "features/filter";

import { ArticleList } from "../ArticleList";
import { InfiniteScroll } from "components/common/InfiniteScroll";

import { ARTICLES_COUNT_PER_PAGE } from "features/blog/data/constants";

function Articles() {
	const [articles, setArticles] = useState<ShortArticleItemData[]>([]);
	const [page, setPage] = useState(1);
	const [isPageLast, setIsPageLast] = useState(false);

	const { appliedFilterParams, onResetFilterParams } =
		useContext(filterContext);

	const { request, loading } = useRequest();

	useEffect(() => {
		return () => {
			onResetFilterParams();
		};
	}, [onResetFilterParams]);

	useEffect(() => {
		if (appliedFilterParams) {
			setPage(1);
			setIsPageLast(false);
			setArticles([]);
		}
	}, [appliedFilterParams]);

	const fetchArticles = async () => {
		const result = await request({
			service: () =>
				getFilteredArticleList({
					params: {
						...appliedFilterParams,
						page: [page],
						perPage: [ARTICLES_COUNT_PER_PAGE],
					},
				}),
		});

		if (result) {
			setArticles((prevState) => {
				return [...prevState, ...result.data];
			});
			setIsPageLast(page === result.lastPage);
			setPage((prevState) => prevState + 1);
			return;
		}
		setIsPageLast(true);
	};

	return (
		<ArticleList
			Wrapper={(children) => (
				<InfiniteScroll
					loading={loading}
					last={isPageLast}
					fetcher={fetchArticles}
				>
					{children}
				</InfiniteScroll>
			)}
			articles={articles}
		/>
	);
}

export { Articles };
