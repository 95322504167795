import cn from "classnames";

import { useAccordion } from "hooks/useAccordion";

import { FilterCheckbox } from "../FilterCheckbox";
import { FilterRange } from "../FilterRange";

import { FilterItemProps } from "./types";

import { ReactComponent as Cross } from "assets/vectors/cross.svg";

function FilterItem({ param, onChangeCheckboxState }: FilterItemProps) {
	const isDefaultExpanded = param.label === null;

	const {
		accordionDescriptionRef,
		isExpanded,
		currentAccordionBodyHeight,
		toggleAccordionHandler,
	} = useAccordion({ defaultExpanded: isDefaultExpanded });

	let filterContent: JSX.Element;

	if (param.type === "checkbox") {
		const { name, items: options } = param;

		filterContent = (
			<FilterCheckbox
				options={options}
				name={name}
				onChangeState={onChangeCheckboxState}
			/>
		);
	} else if (param.type === "range") {
		const { items: options } = param;

		filterContent = <FilterRange options={options} />;
	} else {
		filterContent = <div>Unknown type</div>;
	}

	return (
		<div
			className={cn(
				"filter__block",
				(isExpanded || isDefaultExpanded) && "active",
			)}
		>
			{!isDefaultExpanded && (
				<div
					className={cn(
						"filter__block-head",
						isExpanded && "active",
					)}
					onClick={toggleAccordionHandler}
				>
					<div className="filter__block-row">
						<span className="filter__block-name">{param.label}</span>
					</div>
					<div className="filter__plus">
						<Cross />
					</div>
				</div>
			)}
			<div
				className="filter__block-body"
				style={{ maxHeight: currentAccordionBodyHeight }}
			>
				<div className="filter__block-descr" ref={accordionDescriptionRef}>
					{filterContent}
				</div>
			</div>
		</div>
	);
}

export { FilterItem };
