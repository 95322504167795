import { ProductOption } from "api";

export function getFormattedOptions(
	options: ProductOption[],
	optionKey: "weight" | "grind",
	disabledCallback?: (id: number) => boolean,
) {
	const isOptionExist = options.every( option => option[optionKey] !== null );

	if (!isOptionExist) {
		return [];
	}

	return options.map((option) => {
		const optionObj = option[optionKey]!;

		return {
			value: optionObj.id,
			label: optionObj.title,
			isDisabled: disabledCallback && disabledCallback(optionObj.id),
		};
	});
}
