import { useContext, useEffect, useState } from "react";

import { favoritesContext } from "features/favorites";
import { authContext } from "features/auth";

import { useRequest, getProductDetails, ProductItemData } from "api";

import { Loader } from "components/UI/Loader";

import { ProductList } from "features/catalog";

let isInit = true;

function Favorites() {
	const [products, setProducts] = useState<ProductItemData[]>([]);
	const [loadingProducts, setLoadingProducts] = useState(false);

	const { favoritesProducts, favoritesLoading } = useContext(favoritesContext);
	const { loading: authLoading } = useContext(authContext);

	const { request } = useRequest();

	useEffect(() => {
		const fetchProductDetails = async (productId: number) => {
			const result = await request({
				service: () => getProductDetails({ productId }),
			});

			return result;
		};

		const requests = favoritesProducts.map((productId) =>
			fetchProductDetails(productId),
		);

		const fetchAllProductDetails = async () => {
			setLoadingProducts(true);
			const productsResult = await Promise.all(requests);

			const truthyResult = productsResult.filter(
				(product) => product !== undefined,
			) as ProductItemData[];

			isInit = false;
			setLoadingProducts(false);
			setProducts(truthyResult);
		};

		fetchAllProductDetails();
	}, [request, favoritesProducts]);

	useEffect(() => {
		return () => {
			isInit = true;
		};
	}, []);

	const isLoading =
		favoritesLoading || isInit || authLoading || loadingProducts;

	if (isLoading) {
		return <Loader />;
	}

	return (
		<ProductList Wrapper={(children) => <>{children}</>} products={products} />
	);
}

export { Favorites };
