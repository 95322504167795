import { useContext } from "react";

import { cartContext } from "features/cart";

import { ProductItem } from "../ProductItem";

import { ProductListProps } from "./types";

function ProductList({ products, Wrapper }: ProductListProps) {
	const { cartProducts } = useContext(cartContext);

	return Wrapper(
		<div className="product-row">
			{products.map((product) => (
				<ProductItem key={"product-" + product.id} productData={product} cartData={cartProducts} />
			))}
		</div>,
	);
}

export { ProductList };
