export const FREE_DELIVERY_SCALE_SIZE = 8;

export enum Step {
	CartProducts = 1,
	Delivery,
}

export const DELIVERY_PRICES = {
	1: 500,
	2: 2000,
} as Record<number, number>;

export const DELIVERY_DEPENDENCIES: Record<
	number,
	{
		deliveryCompanyIds: number[];
		paymentMethodIds: number[];
		deliveryCityCodes: number[];
	}
> = {
	1: {
		deliveryCompanyIds: [1, 2],
		paymentMethodIds: [1, 2],
		deliveryCityCodes: [270, 728, 525],
	},
	2: {
		deliveryCompanyIds: [1],
		paymentMethodIds: [1],
		deliveryCityCodes: [],
	},
};

export const LOCAL_DELIVERY_CITY_CODES = [270, 728, 525];

export const FREE_DELIVERY_DEPENDENCIES: {
	cities: number[];
	deliveryMethodIds: number[];
	priceFrom: number | null | string;
}[] = [
	// {
	// 	cities: LOCAL_DELIVERY_CITY_CODES,
	// 	deliveryMethodIds: [1],
	// 	priceFrom: 1500,
	// },
	{
		cities: LOCAL_DELIVERY_CITY_CODES,
		deliveryMethodIds: [2],
		priceFrom: "delivery_free_from_nsk_courier",
	},
	{
		cities: LOCAL_DELIVERY_CITY_CODES,
		deliveryMethodIds: [1],
		priceFrom: "delivery_free_from_nsk",
	},
	{
		cities: [],
		deliveryMethodIds: [1],
		priceFrom: "delivery_free_from",
	},
	{
		cities: [],
		deliveryMethodIds: [2],
		priceFrom: null,
	},
];
