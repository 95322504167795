import { ReactComponent as Time } from "assets/vectors/time.svg";

import "./ProductImagePlaceholder.styles.scss";

function ProductImagePlaceholder() {
	return (
		<div className="product__placeholder">
			<Time />
			<span className="product__placeholder-text">Фото готовится</span>
		</div>
	);
}

export { ProductImagePlaceholder };
