import { useContext, FormEvent } from "react";

import cn from "classnames";

import {
	useRequest,
	toggleCommentLike,
	isResponseOperationSuccessPredicate,
} from "api";

import { authContext } from "features/auth";

import { AddCommentForm } from "../AddCommentForm";

import { CommentItemProps } from "./types";

import { ReactComponent as LikeComm } from "assets/vectors/like-comm.svg";

import "./CommentItem.styles.scss";

function CommentItem({
	id,
	parentId,
	commentData,
	isReply,
	isUnauthorized,
	activeReplyId,
	onChangeActiveReplyId,
	onReloadComments,
}: CommentItemProps) {
	const {
		id: commentId,
		name,
		loyaltyTitle,
		text,
		countLikes,
		date,
		isLike,
		childs,
	} = commentData;

	const { authToken } = useContext(authContext);

	const { request, loading } = useRequest();

	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (isUnauthorized) {
			return;
		}

		const result = await request({
			service: () =>
				toggleCommentLike({
					token: authToken,
					id: commentId,
				}),
		});

		if (result && isResponseOperationSuccessPredicate(result)) {
			onReloadComments();
		}
	};

	const changeActiveReplyHandler = () => {
		if (isUnauthorized) {
			return;
		}

		if (activeReplyId === commentId) {
			onChangeActiveReplyId(null);
			return;
		}

		onChangeActiveReplyId(commentId);
	};

	let replyButtonContent = "Ответить";

	if (activeReplyId === commentId) {
		replyButtonContent = "Отмена";
	}

	const firstNameChar = name[0];
	const subCommentsCount = childs?.length ?? 0;

	return (
		<div className={cn("comments", { "comments-reply": isReply })}>
			<div className="comments__block">
				<div className="comments__row comments__head">
					<div className="comments__avatar">{firstNameChar}</div>
					<div className="comments__col">
						<div className="comments__name">{name}</div>
						<div className="comments__status">{loyaltyTitle}</div>
					</div>
				</div>

				<p className="comments__text">{text}</p>
				<div className="comments__row comments__opt">
					<form onSubmit={submitFormHandler}>
						<div className="comments__like-wrap">
							<button
								className={cn("comments__like", { active: isLike, disabled: isUnauthorized })}
								disabled={loading || isUnauthorized}
							>
								<LikeComm />
							</button>
							{countLikes !== 0 && <span className="comments__like-num">{countLikes}</span>}
						</div>
					</form>
					<button
						className={cn("comments__reply", { disabled: isUnauthorized })}
						onClick={changeActiveReplyHandler}
						disabled={isUnauthorized}
					>
						{replyButtonContent}
					</button>
					<div className="comments__date">{date}</div>
				</div>
				<div className="comments__reply-form">
					{activeReplyId === commentId && (
						<AddCommentForm
							id={id}
							model="catalog"
							parentId={parentId}
							onSuccessAddingComment={onReloadComments}
							onChangeActiveReplyId={onChangeActiveReplyId}
						/>
					)}
				</div>
			</div>

			{subCommentsCount !== 0 && (
				<div>
					{childs?.map((comment) => (
						<CommentItem
							key={"comment-" + comment.id}
							id={id}
							parentId={parentId}
							isReply={true}
							isUnauthorized={isUnauthorized}
							activeReplyId={activeReplyId}
							commentData={comment}
							onChangeActiveReplyId={onChangeActiveReplyId}
							onReloadComments={onReloadComments}
						/>
					))}
				</div>
			)}
		</div>
	);
}

export { CommentItem };
