import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";
import { getStringFormattedQueryParams } from "api/helpers/queryParams";

import { GET_COMMENTS_URL, ADD_COMMENT_URL, TOGGLE_COMMENT_LIKE_URL } from "./constants";

import {
	ResponseError,
	ResponseValidationError,
	// FilteredItemsResponse,
	ResponseOperationSuccess,
} from "api/types";
import {
	// CommentData,
	AddComment,
	GetComments,
	ToggleCommentLike,
	ResponseAddComment,
	ResponseToggleCommentLike,
	ResponseComments,
} from "./types";
import { isResponseValidateErrorPredicate } from "api/helpers/predicates";

export async function getComments({ params, token }: GetComments) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<ResponseComments>({
			url: GET_COMMENTS_URL(formattedQueryParams),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		throw new Error("Comments loading error!");
	}
}

export async function addComment({
	token,
	id,
	message,
	model,
	parentId,
}: AddComment): Promise<
	ResponseValidationError | ResponseOperationSuccess | ResponseError
> {
	const requestBody: Record<string, string | number> = {
		model,
		id,
		message,
	};

	if (parentId) {
		requestBody.parentId = parentId;
	}

	try {
		const result = await axiosRequest<ResponseAddComment>({
			url: ADD_COMMENT_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: requestBody,
		});

		return {
			message: result.message,
			status: result.status,
		};
	} catch (err) {
		if (isAxiosError<ResponseValidationError | ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData && isResponseValidateErrorPredicate(errorData)) {
				return errorData;
			} else if (errorData) {
				return {
					message: errorData.message,
				};
			}
		}
		throw new Error("Adding comment error!");
	}
}


export async function toggleCommentLike({
	token,
	id,
}: ToggleCommentLike): Promise<
	ResponseOperationSuccess | ResponseError
> {
	try {
		const result = await axiosRequest<ResponseToggleCommentLike>({
			url: TOGGLE_COMMENT_LIKE_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: {
				id,
			},
		});

		return {
			message: result.message,
			status: result.status,
		};
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err;
		}
		throw new Error("Toggling like comment error!");
	}
}
