import { useState, useEffect } from "react";

import { validateInputValue } from "../../helpers/validation";

import { UseInputProps } from "./types";

function useInput({ checks, serverValidationErrors, defaultValue }: UseInputProps) {
	const [value, setValue] = useState(defaultValue || "");
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	let errors = validateInputValue({ value, checks });

	if (serverValidationErrors) {
		if (serverValidationErrors.length) {
			errors = [];
		}
		errors.unshift(...serverValidationErrors);
	}

	const invalid = !!errors.length;
	const errorVisible = touched && invalid;

	const changeValueHandler = (newValue: string) => {
		setValue(newValue);
	};

	const blurHandler = () => {
		setTouched(true);
	};

	const touchHandler = () => {
		setTouched(true);
	};

	const resetHandler = () => {
		setValue("");
		setTouched(false);
	};

	return {
		value,
		invalid,
		errorVisible,
		errors,
		onChangeValue: changeValueHandler,
		onBlur: blurHandler,
		onTouch: touchHandler,
		onReset: resetHandler,
	};
}

export { useInput };
