import { createContext, useState, useCallback, useEffect } from "react";

import {
	useRequest,
	UserData,
	getUserData,
	isResponseErrorPredicate,
} from "api";

import {
	addLocalStorageItem,
	deleteLocalStorageItem,
	getLocalStorageItem,
} from "utils/storage";

import {
	AuthContextProviderProps,
	InitAuthContext,
	LoginPayload,
} from "./types";

const initUserData: UserData = {
	id: 0,
	username: null,
	email: "",
	phone: "",
	role: "",
	roleName: "",
	cityId: null,
	regionId: null,
	cityTitle: null,
	regionTitle: null,
	fname: "",
	lname: "",
	pname: null,
	avatar: null,
	status: 0,
	loyalty: {
		title: "",
		discount: 0,
	},
	apiToken: "",
	personalDiscount: null,
	telegramUsername: null,
};

const initAuthContext: InitAuthContext = {
	authToken: "",
	authenticated: false,
	loading: false,
	userData: initUserData,
	onLogin: () => {},
	onLogout: () => {},
	onSetUserData: () => {},
};

const authContext = createContext(initAuthContext);

function AuthContextProvider({ children }: AuthContextProviderProps) {
	const savedAuthToken = getLocalStorageItem<string>("authToken");

	const [authToken, setAuthToken] = useState("");
	const [userData, setUserData] = useState<UserData | null>(null);

	const { request, loading } = useRequest();

	const isAuthenticated = !!authToken;

	useEffect(() => {
		if (savedAuthToken) {
			const fetchUserData = async () => {
				const result = await request({
					service: () => getUserData({ token: savedAuthToken }),
				});

				if (result && !isResponseErrorPredicate(result)) {
					setAuthToken(result.apiToken);
					setUserData(result);
					return;
				}
				deleteLocalStorageItem("authToken");
			};

			fetchUserData();
		}
	}, [savedAuthToken, request]);

	const loginHandler = useCallback((payload: LoginPayload) => {
		const { token, userData } = payload;

		addLocalStorageItem("authToken", token);

		setAuthToken(token);
		setUserData(userData);
	}, []);

	const logoutHandler = useCallback(() => {
		deleteLocalStorageItem("authToken");

		setAuthToken("");
		setUserData(null);
	}, []);

	const setUserDataHandler = useCallback((data: UserData) => {
		setUserData(data);
	}, []);

	return (
		<authContext.Provider
			value={{
				authToken,
				authenticated: isAuthenticated,
				loading,
				userData,
				onLogin: loginHandler,
				onLogout: logoutHandler,
				onSetUserData: setUserDataHandler,
			}}
		>
			{children}
		</authContext.Provider>
	);
}

export { authContext, AuthContextProvider };
