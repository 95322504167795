import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";

import { GET_FAVORITES_URL, SET_FAVORITES_URL } from "./constants";

import {
	Response,
	ResponseError,
	ResponseOperationSuccess,
	ResponseValidationError,
} from "api/types";

import {
	GetFavorites,
	FavoritesData,
	SetFavorites,
	SettingFavoritesData,
} from "./types";

export async function getFavorites({
	token,
	model,
}: GetFavorites): Promise<FavoritesData | ResponseError | undefined> {
	try {
		const result = await axiosRequest<Response<FavoritesData>>({
			url: GET_FAVORITES_URL(model),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return {
				message: err.response!.data.message,
			};
		}
		throw new Error("Favorites loading error!");
	}
}

export async function setFavorites({
	token,
	model,
	id,
}: SetFavorites): Promise<
	ResponseOperationSuccess | ResponseValidationError | undefined
> {
	try {
		const result = await axiosRequest<
			Response<SettingFavoritesData > & ResponseOperationSuccess
		>({
			url: SET_FAVORITES_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: { model, id }, 
		});

		return { status: "success", message: result.message };
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Setting favorites error!");
	}
}
