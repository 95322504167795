import { axiosRequest } from "api/lib/axios";
import { getStringFormattedQueryParams } from "api/helpers/queryParams";

import {
	GET_ARTICLE_DETAILS_URL,
	GET_FILTERED_ARTICLE_LIST_URL,
	GET_BLOG_FILTER_URL,
	GET_FILTERED_ARTICLES_COUNT_URL,
} from "./constants";

import {
	ResponseList,
	Response,
	FilterParams,
	FilteredItemsResponse,
	FilterParamData,
	FilteredItemsCountData
} from "api/types";
import {
	AritcleItemData,
	ShortArticleItemData,
	GetArticleDetailsThroughSlug,
} from "./types";

export async function getFilteredArticleList({ params }: FilterParams) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<FilteredItemsResponse<ShortArticleItemData>>({
			url: GET_FILTERED_ARTICLE_LIST_URL(formattedQueryParams),
		});

		return {
			data: result.data,
			lastPage: result.meta.last_page,
		};
	} catch (err) {
		throw new Error("Article list loading error!");
	}
}

export async function getAriclesFilterParams() {
	try {
		const result = await axiosRequest<ResponseList<FilterParamData>>({
			url: GET_BLOG_FILTER_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Article params loading error!");
	}
}

export async function getFilteredArticlesCount({ params }: FilterParams) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<Response<FilteredItemsCountData>>({
			url: GET_FILTERED_ARTICLES_COUNT_URL(formattedQueryParams),
		});

		return result.data;
	} catch (err) {
		throw new Error("Filtered articles count loading error!");
	}
}

export async function getArticleDetailsThroughSlug({ articleSlug }: GetArticleDetailsThroughSlug) {
	try {
		const result = await axiosRequest<Response<AritcleItemData>>({
			url: GET_ARTICLE_DETAILS_URL(articleSlug),
		});

		return result.data;
	} catch (err) {
		throw new Error("Article details loading error!");
	}
}
