import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page";

import { Link } from "components/common/Link";

function Banner() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<div className="intro-wrap">
			<div className="intro-wrap__row">
				<div className="intro-wrap__info">
					<h1 className="intro-wrap__title">
						{parse(mainPageData!.banner.title)}
					</h1>
					<p className="intro-wrap__text">
						{parse(mainPageData!.banner.text)}
					</p>
				</div>
				<div className="intro-wrap__img">
					<img src={mainPageData!.banner.image.file} alt="" />
				</div>
			</div>
			<Link
				to={mainPageData!.banner.buttonLink}
				autoScrollable={true}
				nav={false}
			>
				<div className="rhombus">
					<div className="rhombus__nums">
						<span className="rhombus__num">0</span>
						<span className="rhombus__num">1</span>
						<span className="rhombus__num">5</span>
						<span className="rhombus__num">2</span>
					</div>
					<span className="rhombus__text">
						{mainPageData?.banner.buttonTitle}
					</span>
				</div>
			</Link>
		</div>
	);
}

export { Banner };
