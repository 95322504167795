import { useContext, useState } from "react";

import { cartContext } from "features/cart";

import { CartProduct } from "../../CartProduct";

import { CartProductsStepProps } from "./types";

function CartProductsStep({
	cartProductsCount,
	fetchedProducts,
	cartProducts,
}: CartProductsStepProps) {
	const [productsSelected, setProductsSelected] = useState(false);

	const { onClearCart } = useContext(cartContext);

	const toggleProductsSelectionHandler = () => {
		setProductsSelected((prevState) => !prevState);
	};

	const deleteAllCartProductsHandler = () => {
		if (productsSelected) {
			onClearCart();
			setProductsSelected(false);
		}
	};

	return (
		<div className="cart">
			<div className="cart__head">
				<div className="section-title">
					<span className="section-title__text">Корзина</span>
					<span className="section-title__num">{cartProductsCount}</span>
				</div>
			</div>
			<div className="cart__option-wrap">
				<div className="cart__option">
					<div className="checkbox">
						<input
							className="checkbox__input hidden"
							type="checkbox"
							id="all3"
							checked={productsSelected}
							onChange={toggleProductsSelectionHandler}
						/>
						<label htmlFor="all3" className="checkbox__label">
							<div className="checkbox__label-squer"></div>
							<span className="checkbox__label-text">Выбрать все</span>
						</label>
					</div>
				</div>
				<div className="cart__option">
					<button
						className="cart__option-btn"
						onClick={deleteAllCartProductsHandler}
					>
						Удалить выбранные
					</button>
				</div>
			</div>
			<div className="cart__product-wrap">
				{fetchedProducts.length !== 0 &&
					cartProducts.map((product) => {
						const isLoading = !fetchedProducts.find(
							(fetchedProduct) => fetchedProduct.id === product.productId,
						);

						if (isLoading) {
							return null;
						}

						return (
							<CartProduct
								key={product.productId + "-" + product.catalogProductId}
								cartProductData={product}
								productsData={fetchedProducts}
								selected={productsSelected}
							/>
						);
					})}
			</div>
		</div>
	);
}

export { CartProductsStep };
