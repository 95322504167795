import { KOF_BASE_API_URL } from "api/constants";

const CART_API = "/api/v1/carts";

export const SET_CART_URL = () => KOF_BASE_API_URL + CART_API + "/setCart";
export const SET_CART_PRODUCT_URL = () =>
	KOF_BASE_API_URL + CART_API + "/setProduct";
export const REMOVE_CART_PRODUCT_URL = (
	catalogId: number,
	catalogItemId: number,
) =>
	KOF_BASE_API_URL +
	CART_API +
	"/removeProduct/" +
	catalogId +
	"/" +
	catalogItemId;
export const GET_CART_URL = () => KOF_BASE_API_URL + CART_API + "/get";
export const CLEAR_CART_URL = () => KOF_BASE_API_URL + CART_API + "/clear";
