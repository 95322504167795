import { memo } from "react";

import cn from "classnames";

import { INTENSIVITY_SCALE_SIZE } from "features/catalog/data/constants";

import { IntensivityScaleProps } from "./types";

const IntensivityScale = memo(
	({
		productId,
		propertyTitle,
		propertyName,
		filledSize,
	}: IntensivityScaleProps) => {
		const scaleArr = new Array(INTENSIVITY_SCALE_SIZE).fill(1);

		const getKey = (index: number) =>
			"product-" + productId + "-scale" + "-" + propertyName + "-" + index;

		return (
			<div className="product__line">
				<span className="product__line-text">{propertyTitle}</span>
				<div className="product__line-degree">
					{scaleArr.map((_, index) => (
						<span
							key={getKey(index)}
							className={cn(
								"product__degree",
								index <= filledSize - 1 && "active",
							)}
						></span>
					))}
				</div>
			</div>
		);
	},
);

export { IntensivityScale };
