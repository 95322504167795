import { Loader } from "../Loader/Loader";

import "./LoaderOverlay.styles.scss";

function LoaderOverlay() {
	return (
		<div className="loader-overlay">
			<Loader />
		</div>
	);
}
export { LoaderOverlay };
