import { useEffect } from "react";

import InfiniteScroller from "react-infinite-scroller";

import { Loader } from "components/UI/Loader";

import { InfiniteScrollProps } from "./types";

let isInit = true;

const InfiniteScroll = ({
	children,
	loading,
	last,
	fetcher,
}: InfiniteScrollProps) => {
	useEffect(() => {
		isInit = false;
	}, []);

	const loadNextPageHandler = () => {
		if (!loading && !isInit && !last && fetcher) {
			fetcher();
		}
	};

	return (
		<InfiniteScroller
			loadMore={loadNextPageHandler}
			hasMore={!last}
			loader={<Loader key={0} classes="infinite-scroll" />}
		>
			{children}
		</InfiniteScroller>
	);
};
export { InfiniteScroll };
