import { Link } from "components/common/Link";

import { MenuItemProps } from "./types";

function MenuItem({ path, text, onClick }: MenuItemProps) {
	return (
		<li className="nav__item">
			<Link
				nav={true}
				to={"/" + path}
				classes="nav__link"
				autoScrollable={true}
				onClick={onClick}
			>
				{text}
			</Link>
		</li>
	);
}

export { MenuItem };
