import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

import { DescriptionSection } from "components/common/DescriptionSection";

function UnderBanner() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<DescriptionSection
			title={
				<>
					{parse(mainPageData!.underBanner.title)}
				</>
			}
			step="01"
			text={parse(mainPageData!.underBanner.text) as string}
			classes="section-first"
		/>
	);
}

export { UnderBanner };
