// import { useEffect, useState } from "react";

import cn from "classnames";

// import { useRequest, getPaymentTypes, OptionData } from "api";

import { OptionsTabProps } from "./types";

function OptionsTab({
	listKey,
	options,
	activeIndex,
	onChangeActiveIndex,
}: OptionsTabProps) {
	// const [paymentOptions, setPaymentOptions] = useState<OptionData[]>([]);

	// const { request } = useRequest();

	// useEffect(() => {
	// 	const fetchPaymentOptions = async () => {
	// 		const result = await request({
	// 			service: getPaymentTypes,
	// 		});

	// 		if (result) {
	// 			setPaymentOptions(result);
	// 		}
	// 	};

	// 	fetchPaymentOptions();
	// }, [request]);

	return (
		<div className="order__tab-wrap">
			{options.map((option) => (
				<button
					type="button"
					key={listKey + "-option-" + option.id}
					className={cn(
						"order__tab",
						activeIndex === option.id && "active",
					)}
					onClick={() => onChangeActiveIndex(option.id)}
				>
					{option.label}
				</button>
			))}
		</div>
	);
}

export { OptionsTab };
