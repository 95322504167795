import { KOF_BASE_API_URL } from "api/constants";

const PAGE_API = "/api/v1/pages";

export const GET_PAGE_MENU_LIST_URL = () =>
	KOF_BASE_API_URL + PAGE_API + "/menu";
export const GET_FAQ_LIST_URL = (pageId: number) =>
	KOF_BASE_API_URL + PAGE_API + "/faq/" + pageId;
export const GET_CONTACT_LIST_URL = (pageId: number) =>
	KOF_BASE_API_URL + PAGE_API + "/contacts/" + pageId;
export const GET_MAIN_PAGE_DATA_URL = () =>
	KOF_BASE_API_URL + PAGE_API + "/main";
export const GET_PAGE_HTML_URL = (pageId: number) =>
	KOF_BASE_API_URL + PAGE_API + "/html/" + pageId;
