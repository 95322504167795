import { useContext, useState, FormEvent } from "react";

import {
	ServerValidationError,
	useRequest,
	registerUser,
	isResponseValidateErrorPredicate,
	isResponseRegistrationSuccessPredicate,
} from "api";

import {
	authModalContext,
	registrationContext,
	authContext,
} from "features/auth";

import { useInput, Input } from "components/UI/Input";
import { Loader } from "components/UI/Loader";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function RegistrationCode() {
	const [serverValidationErrors, setServerValidationErrors] =
		useState<ServerValidationError>({ errors: {} });
	const [sending, setSending] = useState(false);

	const { onChangeActiveModal } = useContext(authModalContext);
	const { registrationData, onSetRegistrationData } =
		useContext(registrationContext);
	const { onLogin } = useContext(authContext);

	const { phone, email, firstName, lastName, password } = registrationData;

	const { request } = useRequest();

	useBodyOverflow({ active: true });
	useScrollToTop();

	const {
		value: emailCodeValue,
		errorVisible: emailCodeErrorVisible,
		errors: emailCodeErrors,
		onChangeValue: onEmailCodeValueChange,
		onBlur: onEmailCodeBlur,
		onTouch: onEmailCodeTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Код email является обязательным.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{7,}$/,
				errorMessage:
					"Количество символов в поле code email не может превышать 6.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{1,3}$/,
				errorMessage:
					"Количество символов в поле code email должно быть не менее 4.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["codeEmail"],
	});

	const {
		value: phoneCodeValue,
		errorVisible: phoneCodeErrorVisible,
		errors: phoneCodeErrors,
		onChangeValue: onPhoneCodeValueChange,
		onBlur: onPhoneCodeBlur,
		onTouch: onPhoneCodeTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Код phone является обязательным.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{6,}$/,
				errorMessage:
					"Количество символов в поле code phone не может превышать 6.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{1,3}$/,
				errorMessage:
					"Количество символов в поле code phone должно быть не менее 4.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["codePhone"],
	});

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onEmailCodeTouch();
		onPhoneCodeTouch();

		setSending(true);

		const excludedPhoneValueArr = phone.match(/\d+/g);

		const newPhone = excludedPhoneValueArr ? excludedPhoneValueArr.join("") : "";

		const result = await request({
			service: () =>
				registerUser({
					phone: newPhone,
					email,
					password,
					codePhone: phoneCodeValue,
					codeEmail: emailCodeValue,
					firstName,
					lastName,
				}),
		});

		setSending(false);

		if (result && isResponseValidateErrorPredicate(result)) {
			setServerValidationErrors(result);
			return;
		}

		if (result && isResponseRegistrationSuccessPredicate(result)) {
			onLogin({ token: result.apiToken, userData: result });
			onChangeActiveModal({ modal: null });
		}
	};

	const closeRegistrationCodeModalHandler = () => {
		onChangeActiveModal({ modal: null });
		onSetRegistrationData({
			data: {
				email: "",
				phone: "",
				password: "",
				firstName: "",
				lastName: "",
			},
		});
	};

	return (
		<div className="popup popup--recovery-code active">
			<button
				className="close-cross"
				onClick={closeRegistrationCodeModalHandler}
			>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">введите код</span>
					</div>

					<div className="popup__block">
						<span className="popup__block-text">
							На почту {email} и телефон {phone} отправлены коды подтверждения
						</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={formSubmitHandler}>
						<Input
							id="codeEmail"
							type="number"
							classes="popup-form__input"
							placeholder="Код email*"
							value={emailCodeValue}
							invalid={emailCodeErrorVisible}
							errors={emailCodeErrors}
							onChangeValue={onEmailCodeValueChange}
							onBlur={onEmailCodeBlur}
						/>
						<Input
							id="codePhone"
							type="number"
							classes="popup-form__input"
							placeholder="Код телефона*"
							value={phoneCodeValue}
							invalid={phoneCodeErrorVisible}
							errors={phoneCodeErrors}
							onChangeValue={onPhoneCodeValueChange}
							onBlur={onPhoneCodeBlur}
						/>
						<div className="popup-form__btns">
							<button type="submit" className="btn primary" disabled={sending}>
								{sending ? <Loader /> : "Продолжить"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { RegistrationCode };
