import { KOF_BASE_API_URL } from "api/constants";

const COMMENTS_API = "/api/v1/comments";
const LIKES_API = "/api/v1/likes";

export const GET_COMMENTS_URL = (queryParams: string) =>
	KOF_BASE_API_URL + COMMENTS_API + "/get" + queryParams;
export const ADD_COMMENT_URL = () => KOF_BASE_API_URL + COMMENTS_API + "/set";
export const TOGGLE_COMMENT_LIKE_URL = () =>
	KOF_BASE_API_URL + LIKES_API + "/set";
