import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";
import cn from "classnames";

import { getStyleObjectFromString } from "helpers/style-formatter";

import { LinkProps } from "./types";

function Link({
	nav,
	to,
	classes,
	styles,
	autoScrollable,
	children,
	onClick,
}: LinkProps) {
	const formattedStylesObj = styles ? getStyleObjectFromString(styles) : {};

	const scrollToTopHandler = () => {
		if (!autoScrollable) return;

		scroll({ top: 0, left: 0, behavior: "smooth" });
	};

	if (nav) {
		return (
			<RouterNavLink
				to={to}
				onClick={() => {
					scrollToTopHandler();
					onClick && onClick();
				}}
				className={({ isActive }) =>
					isActive ? cn(classes, "active") : classes
				}
				style={formattedStylesObj}
			>
				{children}
			</RouterNavLink>
		);
	}

	return (
		<RouterLink
			to={to}
			onClick={() => {
				scrollToTopHandler();
				onClick && onClick();
			}}
			className={classes}
			style={formattedStylesObj}
		>
			{children}
		</RouterLink>
	);
}

export { Link };
