import "components/pages/Cart/CartSection/CartSectionRoot/CartSectionRoot.styles.scss";

function OrderFail() {
	return (
		<section className="cart-section">
			<div className="container">
				<div className="empty-cart">
					<div className="cart">
						<div className="cart__head">
							<div className="section-title">
								<span className="section-title__text">ошибка</span>
								<span className="section-title__num">01</span>
							</div>
						</div>
						<div className="cart__empty">
							Во время оплаты произошла ошибка.
						</div>
						<div className="cart__empty" style={{ marginTop: "2rem", maxWidth: "100%" }}>
							Если у вас возникли проблемы с заказом —{" "}
							<a className="contact-us" href="mailto:bp@red-kof.ru">
								напишите нам
							</a>
							.
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export { OrderFail };
