import { useContext, useState, FormEvent } from "react";

import {
	useRequest,
	resetPasswordThroughEmail,
	resetPasswordThroughPhone,
	isResponseValidateErrorPredicate,
	ServerValidationError,
	ResponseValidationError,
	ResponseOperationSuccess,
} from "api";

import {
	authModalContext,
	forgotPasswordContext,
	ModalType,
} from "features/auth";

import { Input, useInput } from "components/UI/Input";
import { Loader } from "components/UI/Loader";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function ForgotPassword() {
	const [serverValidationErrors, setServerValidationErrors] =
		useState<ServerValidationError>({ errors: {} });
	const [sending, setSending] = useState(false);

	const { onChangeActiveModal } = useContext(authModalContext);
	const { onSetForgotPasswordPhone, onSetForgotPasswordEmail } = useContext(
		forgotPasswordContext,
	);

	const { request } = useRequest();

	const {
		value: loginValue,
		errorVisible: loginErrorVisible,
		errors: loginErrors,
		onChangeValue: onLoginValueChange,
		onBlur: onLoginBlur,
		onTouch: onLoginTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Телефон / email является обязательным.",
			},
		],
		serverValidationErrors:
			serverValidationErrors.errors["phone"] ||
			serverValidationErrors.errors["email"],
	});

	useBodyOverflow({ active: true });
	useScrollToTop();

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onLoginTouch();

		setSending(true);

		let result: ResponseValidationError | ResponseOperationSuccess | undefined;

		const isEmailLogin = loginValue.includes("@");

		if (isEmailLogin) {
			result = await request({
				service: () =>
					resetPasswordThroughEmail({
						email: loginValue,
					}),
			});
		} else {
			const formattedPhone = loginValue.replace("+", "");

			result = await request({
				service: () =>
					resetPasswordThroughPhone({
						phone: formattedPhone,
					}),
			});
		}

		setSending(false);

		if (result && isResponseValidateErrorPredicate(result)) {
			setServerValidationErrors(result);
			return;
		}

		if (result) {
			if (isEmailLogin) {
				onSetForgotPasswordEmail({ email: loginValue });
			} else {
				onSetForgotPasswordPhone({ phone: loginValue });
			}
			onChangeActiveModal({ modal: ModalType.CreatePassword });
		}
	};

	const closeRegistrationModalHandler = () => {
		onChangeActiveModal({ modal: ModalType.Login });
		onSetForgotPasswordPhone({ phone: "" });
		onSetForgotPasswordEmail({ email: "" });
	};

	return (
		<div className="popup popup--forgot-pass active">
			<button
				className="close-cross"
				onClick={closeRegistrationModalHandler}
			>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">Забыли пароль?</span>
					</div>

					<div className="popup__block">
						<span className="popup__block-text">
							Введите email или номер мобильного телефона <br></br> для
							восстановления
						</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={formSubmitHandler}>
						<Input
							id="login"
							type="text"
							classes="popup-form__input"
							placeholder=""
							value={loginValue}
							invalid={loginErrorVisible}
							errors={loginErrors}
							onChangeValue={onLoginValueChange}
							onBlur={onLoginBlur}
						/>

						<div className="popup-form__btns">
							<button type="submit" className="btn secondary">
								{sending ? <Loader /> : "Получить код"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { ForgotPassword };
