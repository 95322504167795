import {
	createContext,
	useCallback,
	useState,
	useEffect,
	useContext,
} from "react";

import { authContext } from "features/auth";

import {
	useRequest,
	getCart,
	setCartProduct,
	removeCartProduct,
	clearCart,
	setCart,
	isResponseOperationSuccessPredicate,
	isResponseErrorPredicate,
	ResponseOperationSuccess,
	ResponseError,
	ResponseCartProductUpdate,
	Nullable,
} from "api";

import { getLocalStorageItem, addLocalStorageItem } from "utils/storage";

import {
	CartContextProviderProps,
	InitCartContext,
	CartProduct,
	AddProductToCartPayload,
	RemoveProductFromCartPayload,
	ClearProductFromCartPayload,
	ChangeProductOptionInCartPayload,
	RemoveOutdatedCartProductsPayload,
	ChangeDeliveryCostPayload,
	// ChangeDeliveryPricePayload,
	ChangeDeliveryCompanyIdPayload,
	ChangeFreeDeliveryFromPayload,
	ChangePromocodePayload,
	ChangePromocodeDiscountPayload,
	ChangeProcessingOrderPayload,
	ChangeFreeDeliveryOptionNamePayload,
	// ChangeDeliveryCityCodePayload,
} from "./types";

const initCartContext: InitCartContext = {
	// deliveryCityCode: null,
	freeDeliveryOptionName: "",
	processingOrder: false,
	promocodeDiscount: 0,
	promocode: "",
	deliveryTypeId: 1,
	deliveryCompanyId: 1,
	freeDeliveryFrom: null,
	deliveryCost: 0,
	paymentOptionId: 1,
	cartInitLoading: true,
	cartLoading: false,
	cartStep: 1,
	cartProductsCount: 0,
	cartProducts: [],
	onChangeCartStep: () => { },
	onResetCartStep: () => { },
	onAddProductToCart: () => { },
	onRemoveProductFromCart: () => { },
	onClearProductFromCart: () => { },
	onChangeProductOptionInCart: () => { },
	onRemoveOutdatedCartProducts: () => { },
	onClearCart: () => { },
	onResetCart: () => { },
	onChangeFreeDeliveryFrom: () => { },
	onChangeDeliveryCompanyId: () => { },
	onChangeDeliveryTypeId: () => { },
	onChangePaymentOptionId: () => { },
	// onChangeDeliveryCityCode: () => {},
	onChangeDeliveryCost: () => { },
	onChangePromocode: () => { },
	onChangePromocodeDiscount: () => { },
	onChangeProcessingOrder: () => { },
	onRefreshCart: () => { },
	onChangeFreeDeliveryOptionName: () => { },
};

const cartContext = createContext(initCartContext);

function CartContextProvider({ children }: CartContextProviderProps) {
	const localSavedAuthToken = getLocalStorageItem<string>("authToken");
	const localSavedCart = getLocalStorageItem<CartProduct[]>("cart");

	let initCartState: CartProduct[] = [];

	if (localSavedCart && !localSavedAuthToken) {
		initCartState = localSavedCart;
	}

	const [freeDeliveryOptionName, setFreeDeliveryOptionName] = useState<Nullable<string>>(null);
	const [cartStep, setCartStep] = useState(1);
	const [freeDeliveryFrom, setFreeDeliveryFrom] = useState<number | null>(null);
	const [deliveryCost, setDeliveryCost] = useState(0);
	const [cartProducts, setCartProducts] =
		useState<CartProduct[]>(initCartState);
	const [isInitLoad, setIsInitLoad] = useState(true);

	const [processingOrder, setProcessingOrder] = useState(false);

	const [deliveryCompanyId, setDeliveryCompanyId] = useState(1);
	const [deliveryTypeId, setDeliveryTypeId] = useState(1);
	const [paymentOptionId, setPaymentOptionId] = useState(1);

	const [promocode, setPromocode] = useState("");
	const [promocodeDiscount, setPromocodeDiscount] = useState(0);

	// const [deliveryCityCode, setDeliveryCityCode] = useState<number | null>(null);

	const { authToken, authenticated } = useContext(authContext);

	const { request, loading: cartLoading } = useRequest();
	const { request: sendCartRequest } = useRequest();

	const fetchCart = useCallback(async () => {
		const result = await request({
			service: () => getCart({ token: authToken }),
		});

		if (result) {
			const formattedCart = result.map((product) => ({
				productId: product.catalogId,
				catalogProductId: product.catalogItemId,
				count: product.count,
			}));

			setCartProducts(formattedCart);
			setIsInitLoad(false);
		}
	}, [authToken, request]);

	useEffect(() => {
		if (authenticated) {
			const localSavedCart = getLocalStorageItem<CartProduct[]>("cart");

			const sendCart = async (cart: CartProduct[]) => {
				const formattedCart = cart.map((product) => ({
					catalogId: product.productId,
					catalogItemId: product.catalogProductId,
					count: product.count,
				}));

				const result = await sendCartRequest({
					service: () =>
						setCart({ token: authToken, cartProducts: formattedCart }),
				});

				if (!result) return;

				addLocalStorageItem("cart", []);
			};

			const updateCart = async () => {
				if (localSavedCart?.length) {
					await sendCart(localSavedCart);
				}
				await fetchCart();
			};

			updateCart();
		} else {
			setTimeout(() => {
				setIsInitLoad(false);
			}, 1000);
		}
	}, [authToken, authenticated, request, sendCartRequest, fetchCart]);

	useEffect(() => {
		const localSavedAuthToken = getLocalStorageItem<string>("authToken");

		if (!localSavedAuthToken) {
			setIsInitLoad(false);
		}
	}, []);

	useEffect(() => {
		if (!authenticated) {
			addLocalStorageItem("cart", cartProducts);
		}
	}, [authenticated, cartProducts]);

	const cartProductsCount = cartProducts.reduce(
		(prev, product) => prev + product.count,
		0,
	);

	const changeCartStepHandler = useCallback(
		(behavior: boolean | number = true) => {
			if (typeof behavior === "number") {
				setCartStep(behavior);
				return;
			}

			if (behavior) {
				setCartStep((prevState) => prevState + 1);
				return;
			}

			setCartStep((prevState) => prevState - 1);
		},
		[],
	);

	const resetCartStepHandler = useCallback(() => {
		setCartStep(1);
	}, []);

	const removeOutdatedCartProductsHandler = useCallback(
		(payload: RemoveOutdatedCartProductsPayload) => {
			const { receivedProductsData } = payload;

			setCartProducts((prevState) => {
				const filteredActualProducts = prevState.filter((product) => {
					const foundProduct = receivedProductsData.find(
						(item) => item.id === product.productId,
					);

					if (!foundProduct) return false;

					const foundCatalogProduct = foundProduct.items.find(
						(item) => item.id === product.catalogProductId,
					);

					if (!foundCatalogProduct) return false;

					return true;
				});

				if (prevState.length !== filteredActualProducts.length) {
					const updateCart = async () => {
						const formattedCart = filteredActualProducts.map((product) => ({
							catalogId: product.productId,
							catalogItemId: product.catalogProductId,
							count: product.count,
						}));

						await sendCartRequest({
							service: () =>
								setCart({
									cartProducts: formattedCart,
									token: authToken,
								}),
						});
					};

					updateCart();

					return filteredActualProducts;
				}
				return prevState;
			});
		},
		[sendCartRequest, authToken],
	);

	const addProductItemToCartHandler = useCallback(
		async (payload: AddProductToCartPayload) => {
			const { productId, catalogProductId } = payload;

			if (authenticated) {
				const result = await request({
					service: () =>
						setCartProduct({
							productId,
							catalogProductId,
							token: authToken,
							count: 1,
						}),
					loadingDelay: 200,
				});

				if (!result || isResponseErrorPredicate(result)) return;
			}

			setCartProducts((prevState) => {
				const cartProductsCopy = prevState.slice();

				const foundProductIndex = cartProductsCopy.findIndex(
					(product) =>
						product.productId === productId &&
						product.catalogProductId === catalogProductId,
				);

				if (foundProductIndex !== -1) {
					const foundProduct = cartProductsCopy[foundProductIndex];
					const prevProductCount = foundProduct.count;

					const newProduct = {
						...foundProduct,
						count: prevProductCount + 1,
					};

					cartProductsCopy.splice(foundProductIndex, 1, newProduct);

					return cartProductsCopy;
				}

				const newProduct = { ...payload, count: 1 };
				cartProductsCopy.push(newProduct);

				return cartProductsCopy;
			});
		},
		[authToken, authenticated, request],
	);

	const removeProductItemFromCartHandler = useCallback(
		async (payload: RemoveProductFromCartPayload) => {
			const { productId, catalogProductId, count } = payload;

			if (authenticated) {
				let result:
					| ResponseOperationSuccess
					| ResponseCartProductUpdate
					| ResponseError
					| undefined;

				if (count < 2) {
					result = await request({
						service: () =>
							removeCartProduct({
								productId,
								catalogProductId,
								token: authToken,
							}),
						loadingDelay: 200,
					});
				} else {
					result = await request({
						service: () =>
							setCartProduct({
								productId,
								catalogProductId,
								token: authToken,
								count: -1,
							}),
						loadingDelay: 200,
					});
				}

				if (
					!result ||
					(isResponseErrorPredicate(result) &&
						!isResponseOperationSuccessPredicate(result))
				)
					return;
			}

			setCartProducts((prevState) => {
				const cartProductsCopy = prevState.slice();

				const foundProductIndex = cartProductsCopy.findIndex(
					(product) =>
						product.productId === productId &&
						product.catalogProductId === catalogProductId,
				);

				const foundProduct = cartProductsCopy[foundProductIndex];
				const prevProductCount = foundProduct.count;

				if (prevProductCount > 1) {
					const newProduct: CartProduct = {
						...foundProduct,
						count: prevProductCount - 1,
					};
					cartProductsCopy.splice(foundProductIndex, 1, newProduct);

					return cartProductsCopy;
				}

				cartProductsCopy.splice(foundProductIndex, 1);

				return cartProductsCopy;
			});
		},
		[request, authToken, authenticated],
	);

	const clearProductItemFromCartHandler = useCallback(
		async (payload: ClearProductFromCartPayload) => {
			const { productId, catalogProductId } = payload;

			if (authenticated) {
				const result = await request({
					service: () =>
						removeCartProduct({
							productId,
							catalogProductId,
							token: authToken,
						}),
					loadingDelay: 200,
				});

				if (
					!result ||
					(isResponseErrorPredicate(result) &&
						!isResponseOperationSuccessPredicate(result))
				)
					return;
			}

			setCartProducts((prevState) => {
				const cartProductsCopy = prevState.slice();

				const foundProductIndex = cartProductsCopy.findIndex(
					(product) =>
						product.productId === productId &&
						product.catalogProductId === catalogProductId,
				);

				cartProductsCopy.splice(foundProductIndex, 1);

				return cartProductsCopy;
			});
		},
		[request, authToken, authenticated],
	);

	const changeProductOptionInCartHandler = useCallback(
		async (payload: ChangeProductOptionInCartPayload) => {
			const { productId, oldCatalogProductId, catalogProductId } = payload;

			const existingProductIndex = cartProducts.findIndex(
				(product) =>
					product.productId === productId &&
					product.catalogProductId === oldCatalogProductId,
			);

			const currentProductIndex = cartProducts.findIndex(
				(product) =>
					product.productId === productId &&
					product.catalogProductId === catalogProductId,
			);

			const existingProduct = cartProducts[existingProductIndex];
			const currentProduct = cartProducts[currentProductIndex];

			if (existingProductIndex === currentProductIndex) return cartProducts;

			if (authenticated) {
				if (currentProductIndex !== -1 && existingProductIndex !== -1) {
					const updatingResult = await request({
						service: () =>
							setCartProduct({
								productId: currentProduct.productId,
								catalogProductId: currentProduct.catalogProductId,
								token: authToken,
								count: existingProduct.count,
							}),
						loadingDelay: 200,
					});

					const removingResult = await request({
						service: () =>
							removeCartProduct({
								productId: existingProduct.productId,
								catalogProductId: existingProduct.catalogProductId,
								token: authToken,
							}),
						loadingDelay: 200,
					});

					if (
						!updatingResult ||
						(isResponseErrorPredicate(updatingResult) &&
							!isResponseOperationSuccessPredicate(updatingResult)) ||
						!removingResult ||
						(isResponseErrorPredicate(removingResult) &&
							!isResponseOperationSuccessPredicate(removingResult))
					)
						return;
				} else if (currentProductIndex === -1 && existingProductIndex !== -1) {
					const removingResult = await request({
						service: () =>
							removeCartProduct({
								productId: existingProduct.productId,
								catalogProductId: existingProduct.catalogProductId,
								token: authToken,
							}),
						loadingDelay: 200,
					});

					const updatingResult = await request({
						service: () =>
							setCartProduct({
								productId: existingProduct.productId,
								catalogProductId: catalogProductId,
								token: authToken,
								count: existingProduct.count,
							}),
						loadingDelay: 200,
					});

					if (
						!updatingResult ||
						(isResponseErrorPredicate(updatingResult) &&
							!isResponseOperationSuccessPredicate(updatingResult)) ||
						!removingResult ||
						(isResponseErrorPredicate(removingResult) &&
							!isResponseOperationSuccessPredicate(removingResult))
					)
						return;
				} else {
					return;
				}
			}

			setCartProducts(() => {
				const cartProductsCopy = cartProducts.slice();

				if (currentProductIndex !== -1) {
					const updatedProduct = {
						...existingProduct,
						catalogProductId,
						count: currentProduct.count + existingProduct.count,
					};

					cartProductsCopy.splice(currentProductIndex, 1, updatedProduct);
					cartProductsCopy.splice(existingProductIndex, 1);

					return cartProductsCopy;
				}

				const updatedProduct = {
					...existingProduct,
					catalogProductId,
				};

				cartProductsCopy.splice(existingProductIndex, 1, updatedProduct);

				return cartProductsCopy;
			});
		},
		[request, authToken, cartProducts, authenticated],
	);

	const clearCartHandler = useCallback(async () => {
		if (authenticated) {
			const result = await request({
				service: () => clearCart({ token: authToken }),
				loadingDelay: 200,
			});

			if (!result) return;
		}

		setCartProducts([]);
	}, [request, authToken, authenticated]);

	const resetCartHandler = useCallback(() => {
		setCartProducts([]);
	}, []);

	const changeDiscountPriceHandler = useCallback(
		(payload: ChangeFreeDeliveryFromPayload) => {
			setFreeDeliveryFrom(payload.price);
		},
		[],
	);

	const changeDeliveryCompanyIdHandler = useCallback(
		(payload: ChangeDeliveryCompanyIdPayload) => {
			setDeliveryCompanyId(payload.id);
		},
		[],
	);

	const changeDeliveryTypeIdHandler = useCallback(
		(payload: ChangeDeliveryCompanyIdPayload) => {
			setDeliveryTypeId(payload.id);
		},
		[],
	);

	const changePaymentOptionIdHandler = useCallback(
		(payload: ChangeDeliveryCompanyIdPayload) => {
			setPaymentOptionId(payload.id);
		},
		[],
	);

	const changeDeliveryCostHandler = useCallback(
		(payload: ChangeDeliveryCostPayload) => {
			setDeliveryCost(payload.cost);
		},
		[],
	);

	const changePromocodeHandler = useCallback(
		(payload: ChangePromocodePayload) => {
			setPromocode(payload.code);
		},
		[],
	);

	const changePromocodeDiscountHandler = useCallback(
		(payload: ChangePromocodeDiscountPayload) => {
			setPromocodeDiscount(payload.discount);
		},
		[],
	);

	const changeProcessingOrderHandler = useCallback(
		(payload: ChangeProcessingOrderPayload) => {
			setProcessingOrder(payload.processing);
		},
		[],
	);

	const changeFreeDeliveryOptionNameHandler = useCallback(
		(payload: ChangeFreeDeliveryOptionNamePayload) => {
			setFreeDeliveryOptionName(payload.optionName);
		},
		[],
	);

	// const changeDeliveryCityCodeHandler = useCallback(
	// 	(payload: ChangeDeliveryCityCodePayload) => {
	// 		setDeliveryCityCode(payload.code);
	// 	},
	// 	[],
	// );

	return (
		<cartContext.Provider
			value={{
				// deliveryCityCode,
				freeDeliveryOptionName,
				processingOrder,
				promocodeDiscount,
				promocode,
				deliveryTypeId,
				deliveryCompanyId,
				freeDeliveryFrom,
				deliveryCost,
				paymentOptionId,
				cartInitLoading: isInitLoad,
				cartLoading,
				cartStep,
				cartProductsCount,
				cartProducts,
				onChangeCartStep: changeCartStepHandler,
				onResetCartStep: resetCartStepHandler,
				onAddProductToCart: addProductItemToCartHandler,
				onRemoveProductFromCart: removeProductItemFromCartHandler,
				onClearProductFromCart: clearProductItemFromCartHandler,
				onChangeProductOptionInCart: changeProductOptionInCartHandler,
				onRemoveOutdatedCartProducts: removeOutdatedCartProductsHandler,
				onClearCart: clearCartHandler,
				onResetCart: resetCartHandler,
				onChangeFreeDeliveryFrom: changeDiscountPriceHandler,
				onChangeDeliveryCompanyId: changeDeliveryCompanyIdHandler,
				onChangeDeliveryTypeId: changeDeliveryTypeIdHandler,
				onChangePaymentOptionId: changePaymentOptionIdHandler,
				onChangeDeliveryCost: changeDeliveryCostHandler,
				onChangePromocode: changePromocodeHandler,
				onChangePromocodeDiscount: changePromocodeDiscountHandler,
				// onChangeDeliveryCityCode: changeDeliveryCityCodeHandler,
				onChangeProcessingOrder: changeProcessingOrderHandler,
				onRefreshCart: fetchCart,
				onChangeFreeDeliveryOptionName: changeFreeDeliveryOptionNameHandler,
			}}
		>
			{children}
		</cartContext.Provider>
	);
}

export { CartContextProvider, cartContext };
