// import { useContext } from "react";

import cn from "classnames";

// import { siteOptionsContext } from "features/site-options";

import { FREE_DELIVERY_SCALE_SIZE } from "features/cart/data/constants";

import { FreeShippingScaleProps } from "./types";

function FreeShippingScale({
	productCount,
	totalPrice,
	freeDeliveryFrom,
}: FreeShippingScaleProps) {
	// const { siteOptions } = useContext(siteOptionsContext);

	// const freeShippingStartPrice = siteOptions.find(
	// 	(option) => option.key === "delivery_free_from",
	// )?.value || 0;
	const scaleArr = new Array(FREE_DELIVERY_SCALE_SIZE).fill(1);

	const scaleSegementStep = freeDeliveryFrom / FREE_DELIVERY_SCALE_SIZE;
	const lastSegmentIndex = Math.floor(totalPrice / scaleSegementStep) - 1;
	const priceUntilFreeShipping = freeDeliveryFrom - totalPrice;

	return (
		<div className="order__free-wrap">
			<span className="order__free-value">
				{productCount} товар на <span>{totalPrice},00 ₽</span>
			</span>
			<div className="order__free-row">
				<span className="order__free-info">
					до бесплатной доставки осталось{" "}
					{priceUntilFreeShipping < 0 ? 0 : priceUntilFreeShipping} ₽
				</span>
				<div className="order__free-status">
					{scaleArr.map((_, index) => (
						<div
							key={"free-shipping-" + index}
							className={cn(
								"order__free-rb",
								index <= lastSegmentIndex && "active",
							)}
						></div>
					))}
				</div>
			</div>
		</div>
	);
}

export { FreeShippingScale };
