import { useEffect, useState } from "react";

import { useRequest, getFilteredProductList, ProductItemData } from "api";

import { Loader } from "components/UI/Loader";

import { ProductList } from "../ProductList";

function RecommendationCatalog() {
	const [products, setProducts] = useState<ProductItemData[]>([]);

	const { request, loading } = useRequest();

	useEffect(() => {
		const fetchProducts = async () => {
			const result = await request({
				service: () =>
					getFilteredProductList({
						params: { page: [1], perPage: [4], isRecommendations: [1] },
					}),
			});

			if (result) {
				setProducts(result.data);
			}
		};

		fetchProducts();
	}, [request]);

	if (loading) {
		return <Loader />;
	}

	return (
		<ProductList Wrapper={(children) => <>{children}</>} products={products} />
	);
}

export { RecommendationCatalog };
