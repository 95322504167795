import { useEffect, useState, useRef, useCallback } from "react";

export function useAccordion({
	defaultExpanded,
}: {
	defaultExpanded: boolean;
}) {
	const [isExpanded, setIsExpanded] = useState(false);
	const [descriptionHeight, setDescriptionHeight] = useState(0);

	const accordionDescriptionRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (accordionDescriptionRef.current) {
			setDescriptionHeight(accordionDescriptionRef.current.clientHeight);
		}
	}, []);

	useEffect(() => {
		const accordionResizeHandler = () => {
			if (accordionDescriptionRef.current) {
				setDescriptionHeight(accordionDescriptionRef.current.clientHeight);
			}
		};

		window.addEventListener("resize", accordionResizeHandler);

		return () => {
			window.removeEventListener("resize", accordionResizeHandler);
		};
	}, []);

	const toggleAccordionHandler = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const recalculateAccordionHandler = useCallback(() => {
		if (accordionDescriptionRef.current) {
			setDescriptionHeight(accordionDescriptionRef.current.clientHeight);
		}
	}, []);

	const currentAccordionBodyHeight =
		isExpanded || defaultExpanded ? descriptionHeight + "px" : "0px";

	return {
		isExpanded,
		accordionDescriptionRef,
		currentAccordionBodyHeight,
		toggleAccordionHandler,
		recalculateAccordionHandler,
	};
}
