import { useContext } from "react";

import cn from "classnames";
import { useSearchParams } from "react-router-dom";

import { burgerContext } from "features/burger";
import { authContext } from "features/auth";
import { cartContext } from "features/cart";

import { Link } from "components/common/Link";

import { ReactComponent as Like } from "assets/vectors/like.svg";
import { ReactComponent as LikeWhite } from "assets/vectors/like-white.svg";
import { ReactComponent as Cart } from "assets/vectors/cart.svg";
import { ReactComponent as CartWhite } from "assets/vectors/cart-white.svg";
import { ReactComponent as Profile } from "assets/vectors/profile.svg";
import { ReactComponent as ProfileWhite } from "assets/vectors/profile-white.svg";

import "./MobileNav.styles.scss";

function MobileNav() {
	const { isOpen, onOpenBurger, onCloseBurger } = useContext(burgerContext);
	const { authenticated } = useContext(authContext);
	const { cartProductsCount } = useContext(cartContext);

	const [searchParams] = useSearchParams();

	const searchCatalogType = searchParams.get("type");

	const isRedGrade = searchCatalogType === "3";

	const cartCountContent = cartProductsCount !== 0 && (
		<div className="mobile-nav__count">{cartProductsCount}</div>
	);

	const toggleBurgerHandler = () => {
		if (isOpen) {
			onCloseBurger();
			return;
		}

		onOpenBurger();
	};

	return (
		<div className={cn("mobile-nav", isRedGrade && "white")}>
			<div className="mobile-nav__item">
				<Link
					nav={true}
					to="/my"
					classes={cn("mobile-nav__btn", authenticated && "filled")}
					autoScrollable={false}
				>
					{isRedGrade ? <ProfileWhite /> : <Profile />}
				</Link>
			</div>
			<div className="mobile-nav__item">
				<Link
					nav={true}
					to="/favorites"
					classes="mobile-nav__btn"
					autoScrollable={false}
				>
					{isRedGrade ? <LikeWhite /> : <Like />}
				</Link>
			</div>
			<div className="mobile-nav__item">
				<Link
					nav={true}
					to="/cart"
					classes="mobile-nav__btn"
					autoScrollable={false}
				>
					<>
						{isRedGrade ? <CartWhite /> : <Cart />}
						{cartCountContent}
					</>
				</Link>
			</div>
			<div className="mobile-nav__item">
				<button className="mobile-nav__btn" onClick={toggleBurgerHandler}>
					<div className={cn("burger", isOpen && "active")}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
			</div>
		</div>
	);
}

export { MobileNav };
