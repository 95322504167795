import { useContext } from "react";

import { authModalContext, ModalType } from "features/auth";

import { ReactComponent as Lock } from "assets/vectors/lock.svg";

import "./UnauthorizedPlaceholder.styles.scss";

function UnauthorizedPlaceholder() {
	const { onChangeActiveModal } = useContext(authModalContext);

	return (
		<div className="comments-auth">
			<Lock />
			<div className="comments-auth__info">
				<span className="comments-auth__text">
					Чтобы оставить комментарий, необходимо
				</span>
				<button className="comments-auth__btn comments-auth__text" onClick={() => onChangeActiveModal({ modal: ModalType.Login })}>
					войти
				</button>
				<span className="comments-auth__text">
					в личный кабинет или
				</span>
				<button className="comments-auth__btn comments-auth__text"
					onClick={() => onChangeActiveModal({ modal: ModalType.Registration })}
				>
					зарегистрироваться
				</button>
				<span className="comments-auth__text">
					.
				</span>
			</div>
		</div>
	);
}

export { UnauthorizedPlaceholder };
