import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";
import { getStringFormattedQueryParams } from "api/helpers/queryParams";

import {
	GET_ORDERS_FILTER_URL,
	GET_FILTERED_ORDERS_HISTORY_URL,
	GET_FILTERED_ORDERS_COUNT_URL,
	GET_ORDER_DETAILS_URL,
	REPEAT_ORDER_URL,
	CANCEL_ORDER_URL,
	GET_PAYMENT_TYPES_URL,
	GET_DELIVERY_TYPES_URL,
	GET_DELIVERY_RECEIPT_TYPES_URL,
	GET_DELIVERY_COMPANIES_URL,
	MAKE_ORDER_URL,
	CHECK_PROMOCODE_URL,
} from "./constants";

import { isResponseErrorPredicate } from "api";

import {
	FilterParams,
	FilterParamData,
	FilteredItemsResponse,
	FilteredItemsCountData,
	Response,
	ResponseList,
	ResponseError,
	ResponseOperationSuccess,
} from "api/types";
import {
	OptionData,
	ShortOrderItemData,
	OrderItemData,
	RepeatOrder,
	CancelOrder,
	GetOrdersFilterParams,
	GetOrderDetails,
	MakeOrder,
	SuccessOrder,
	SuccessCardOrder,
	ErrorOrder,
	CheckPromocode,
	SuccessPromocode,
} from "./types";

export async function getFilteredOrderList({
	params,
	token,
}: FilterParams & { token: string }): Promise<
	| {
			data: ShortOrderItemData[];
			lastPage: number;
	  }
	| ResponseError
> {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<
			FilteredItemsResponse<ShortOrderItemData>
		>({
			url: GET_FILTERED_ORDERS_HISTORY_URL(formattedQueryParams),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return {
			data: result.data,
			lastPage: result.meta.last_page,
		};
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData && isResponseErrorPredicate(errorData)) {
				return { message: errorData.message };
			}
		}
		throw new Error("Orders loading error!");
	}
}

export async function getOrdersFilterParams({ token }: GetOrdersFilterParams) {
	try {
		const result = await axiosRequest<ResponseList<FilterParamData>>({
			url: GET_ORDERS_FILTER_URL(),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Filter params loading error!");
	}
}

export async function getFilteredOrdersCount({
	params,
	token,
}: FilterParams & { token: string }) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<Response<FilteredItemsCountData>>({
			url: GET_FILTERED_ORDERS_COUNT_URL(formattedQueryParams),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Filtered orders count loading error!");
	}
}

export async function getOrdersDetails({ orderId, token }: GetOrderDetails) {
	try {
		const result = await axiosRequest<Response<OrderItemData>>({
			url: GET_ORDER_DETAILS_URL(orderId),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Order details loading error!");
	}
}

export async function repeatOrder({
	orderId,
	token,
}: RepeatOrder): Promise<ResponseOperationSuccess | ResponseError | undefined> {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: REPEAT_ORDER_URL(orderId),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Repeating order error!");
	}
}

export async function cancelOrder({
	orderId,
	token,
}: CancelOrder): Promise<ResponseOperationSuccess | ResponseError | undefined> {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: CANCEL_ORDER_URL(orderId),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Canceling order error!");
	}
}

export async function makeOrder({ token, orderData }: MakeOrder) {
	const {
		paymentType,
		deliveryType,
		deliveryCompanyType,
		deliveryRegion,
		deliveryCity,
		deliveryPostcode,
		deliveryAddress,
		deliveryPointCode,
		deliveryPointAddress,
		deliveryReceiptType,
		deliveryCost,
		discountTotal,
		discountCost,
		totalCost,
		promocode,
		weight,
		cdekCityCode,
		firstName,
		lastName,
		email,
		phone,
		comment,
		cartProducts,
	} = orderData;

	const formattedProducts = cartProducts.map((product) => ({
		catalogId: product.productId,
		catalogItemId: product.catalogProductId,
		count: product.count,
	}));

	try {
		const result = await axiosRequest<
			SuccessCardOrder | SuccessOrder | ErrorOrder
		>({
			url: MAKE_ORDER_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: {
				paymentType,
				deliveryType,
				deliveryCompanyType,
				deliveryRegion,
				deliveryCity,
				deliveryPostcode,
				deliveryAddress,
				deliveryPointCode,
				deliveryPointAddress,
				deliveryReceiptType,
				deliveryCost,
				discountTotal,
				discountCost,
				totalCost,
				promocode,
				weight,
				cdekCityCode,
				fname: firstName,
				lname: lastName,
				email,
				phone,
				comment,
				items: formattedProducts,
			},
		});

		return result;
	} catch (err) {
		throw new Error("Ordering error!");
	}
}

export async function getPaymentTypes() {
	try {
		const result = await axiosRequest<OptionData[]>({
			url: GET_PAYMENT_TYPES_URL(),
		});

		return result;
	} catch (err) {
		throw new Error("Payment types loading error!");
	}
}

export async function getDeliveryTypes() {
	try {
		const result = await axiosRequest<OptionData[]>({
			url: GET_DELIVERY_TYPES_URL(),
		});

		return result;
	} catch (err) {
		throw new Error("Delivery types loading error!");
	}
}

export async function getDeliveryReceiptTypes() {
	try {
		const result = await axiosRequest<OptionData[]>({
			url: GET_DELIVERY_RECEIPT_TYPES_URL(),
		});

		return result;
	} catch (err) {
		throw new Error("Delivery receipt types loading error!");
	}
}

export async function getDeliveryCompanies() {
	try {
		const result = await axiosRequest<OptionData[]>({
			url: GET_DELIVERY_COMPANIES_URL(),
		});

		return result;
	} catch (err) {
		throw new Error("Delivery companies loading error!");
	}
}

export async function checkPromocode({ promocode }: CheckPromocode) {
	try {
		const result = await axiosRequest<
			Response<SuccessPromocode> | ResponseError
		>({
			url: CHECK_PROMOCODE_URL(),
			method: "post",
			body: {
				code: promocode,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Checking promocode error!");
	}
}
