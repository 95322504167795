import { useContext, useEffect, useState, useRef, useMemo } from "react";

import { cartContext } from "features/cart";
import { authContext } from "features/auth";

import {
	useRequest,
	getProductDetails,
	ProductItemData,
	getFilteredProductList,
} from "api";

import { LoaderOverlay } from "components/UI/LoaderOverlay";
import { Loader } from "components/UI/Loader";

import { ProductList } from "features/catalog/components/ProductList";

import { CartProductsStep } from "../Steps/CartProductsStep";
import { DeliveryStep } from "../Steps/DeliveryStep";
import { OrderTotal } from "../OrderTotal";
import { CartEmptyPlaceholder } from "../CartEmptyPlaceholder";

import { Step } from "features/cart/data/constants";

import { DeliveryStepRef } from "../Steps/DeliveryStep/types";

import "./Cart.styles.scss";

let isInit = true;

function Cart() {
	const formControlRef = useRef<DeliveryStepRef>(null);

	const {
		cartInitLoading,
		cartLoading,
		cartProducts,
		cartStep,
		cartProductsCount,
		onChangeCartStep,
		onRemoveOutdatedCartProducts,
	} = useContext(cartContext);
	const { loading: authLoading } = useContext(authContext);

	const [paymentId, setPaymentId] = useState(1);
	const [products, setProducts] = useState<ProductItemData[]>([]);
	const [recommendedProducts, setRecommendedProducts] = useState<
		ProductItemData[]
	>([]);

	const { request: requestProductDetails } = useRequest();
	const {
		request: requestRecommendedProducts,
		loading: recommendedProductsLoading,
	} = useRequest();

	const dedupedProducts = useMemo(() => {
		const existingProductsIds: number[] = [];

		return cartProducts.filter((product) => {
			if (existingProductsIds.includes(product.productId)) {
				return false;
			}
			existingProductsIds.push(product.productId);
			return true;
		});
	}, [cartProducts]);

	useEffect(() => {
		const fetchProductDetails = async (productId: number) => {
			const result = await requestProductDetails({
				service: () => getProductDetails({ productId }),
			});

			return result;
		};

		const requests = dedupedProducts.map((product) =>
			fetchProductDetails(product.productId),
		);

		const fetchAllProductDetails = async () => {
			const productsResult = await Promise.all(requests);

			const truthyResult = productsResult.filter(
				(product) => product !== undefined,
			) as ProductItemData[];

			isInit = false;
			onRemoveOutdatedCartProducts({ receivedProductsData: truthyResult });
			setProducts(truthyResult);
		};

		fetchAllProductDetails();
	}, [dedupedProducts, requestProductDetails, onRemoveOutdatedCartProducts]);

	useEffect(() => {
		return () => {
			isInit = true;
			onChangeCartStep(1);
		};
	}, [onChangeCartStep]);

	useEffect(() => {
		const fetchRecommendedProducts = async () => {
			const result = await requestRecommendedProducts({
				service: () =>
					getFilteredProductList({
						params: { page: [1], perPage: [4], isRecommendations: [1] },
					}),
			});

			if (result) {
				setRecommendedProducts(result.data);
			}
		};

		fetchRecommendedProducts();
	}, [requestRecommendedProducts]);

	const submitFormHandler = () => {
		formControlRef.current?.submit();
	};

	const changePaymentIdHandler = (id: number) => {
		setPaymentId(id);
	};

	let step = (
		<CartProductsStep
			cartProductsCount={cartProductsCount}
			fetchedProducts={products}
			cartProducts={cartProducts}
		/>
	);

	const loading =
		cartInitLoading ||
		cartLoading ||
		authLoading ||
		isInit ||
		(cartProducts.length !== 0 && products.length === 0);

	const isRecommendationVisible = cartStep === Step.CartProducts;

	switch (cartStep) {
		case Step.Delivery:
			step = (
				<DeliveryStep
					ref={formControlRef}
					fetchedProducts={products}
					activePaymentId={paymentId}
				/>
			);
			break;
	}

	let cartContent = (
		<div className="cart-wrap">
			<div className="cart-col">
				{loading && <LoaderOverlay />}
				{step}
			</div>
			<div className="cart-col">
				{loading && <LoaderOverlay />}
				<OrderTotal
					cartProductsCount={cartProductsCount}
					fetchedProducts={products}
					cartProducts={cartProducts}
					onSubmitForm={submitFormHandler}
					onChangePaymentId={changePaymentIdHandler}
				/>
			</div>
		</div>
	);

	if (
		!cartInitLoading &&
		!cartLoading &&
		!authLoading &&
		!isInit &&
		cartProducts.length === 0
	) {
		cartContent = <CartEmptyPlaceholder />;
	}

	return (
		<>
			{cartContent}
			{isRecommendationVisible && (
				<div className="recommendation-wrap">
					<div className="section-title">
						<h2 className="section-title__text">Рекомендуем</h2>
						<span className="section-title__num">02</span>
					</div>

					{recommendedProductsLoading ? (
						<Loader />
					) : (
						<ProductList
							Wrapper={(children) => <>{children}</>}
							products={recommendedProducts}
						/>
					)}
				</div>
			)}
		</>
	);
}

export { Cart };
