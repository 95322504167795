import { ProductOption } from "api";

export function getDeduplicatedOptions(
	options: ProductOption[],
	optionKey: "weight" | "grind",
) {
	const existingOptions: number[] = [];

	return options.filter((option) => {
		const optionObj = option[optionKey];

		if (!optionObj) {
			return true;
		}

		const optionId = optionObj.id;
		const isExist = existingOptions.includes(optionId);

		if (isExist) return false;

		existingOptions.push(optionId);
		return true;
	});
}
