import { useCallback, useState } from "react";

import { UsePaginationProps } from "./types";

export function usePagination({ defaultPerPage }: UsePaginationProps) {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = useState(defaultPerPage);
	const [totalCountPages, setTotalCountPages] = useState(0);

	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(defaultPerPage);
	const [totalItemsCount, setTotalItemsCount] = useState(0);

	const changePageHandler = useCallback((page: number) => {
		setPage(page);
	}, []);

	const changePerPageHandler = useCallback((perPage: number, depPrevState: boolean) => {
		if (depPrevState) {
			setPerPage((prevState) => prevState + perPage);
			return;
		}

		setPerPage(perPage);
	}, []);

	const changeTotalCountPagesHandler = useCallback((pages: number) => {
		setTotalCountPages(pages);
	}, []);

	const changeOffsetHandler = useCallback((offset: number) => {
		setOffset(offset);
	}, []);

	const changeLimitHandler = useCallback((limit: number, depPrevState: boolean) => {
		if (depPrevState) {
			setLimit((prevState) => prevState + limit);
			return;
		}

		setLimit(limit);
	}, []);

	const changeTotalItemsCountHandler = useCallback((pages: number) => {
		setTotalItemsCount(pages);
	}, []);


	return {
		page,
		perPage,
		totalCountPages,
		offset,
		limit,
		totalItemsCount,
		onChangePage: changePageHandler,
		onChangePerPage: changePerPageHandler,
		onChangeTotalCountPages: changeTotalCountPagesHandler,
		onChangeOffset: changeOffsetHandler,
		onChangeLimit: changeLimitHandler,
		onChangeTotalItemsCount: changeTotalItemsCountHandler,
	};
}
