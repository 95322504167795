import { useContext } from "react";
import { createPortal } from "react-dom";

import { authModalContext } from "features/auth";

import { ModalType } from "features/auth/data/constants";

import { AuthOffer } from "../AuthOffer";
import { Login } from "../Login";
import { Registration } from "../Registration";
import { RegistrationCode } from "../RegistrationCode";
import { ForgotPassword } from "../ForgotPassword";
import { CreatePassword } from "../CreatePassword";

import { Overlay } from "components/UI/Overlay";

import "./AuthModals.styles.scss";

function AuthModals() {
	const { activeModal } = useContext(authModalContext);

	let modal: JSX.Element | null = null;
	let overlay: JSX.Element | null = null;

	if (activeModal) {
		overlay = <Overlay />;
	}

	switch (activeModal) {
		case ModalType.AuthOffer:
			modal = <AuthOffer />;
			break;
		case ModalType.Registration:
			modal = <Registration />;
			break;
		case ModalType.RegistrationCode:
			modal = <RegistrationCode />;
			break;
		case ModalType.Login:
			modal = <Login />;
			break;
		case ModalType.ForgotPassword:
			modal = <ForgotPassword />;
			break;
		case ModalType.CreatePassword:
			modal = <CreatePassword />;
			break;
	}

	return (
		<>
			{createPortal(modal, document.body)}
			{createPortal(overlay, document.body)}
		</>
	);
}

export { AuthModals };
