import { createContext, useState, useEffect } from "react";

import { useRequest, getSiteOptions, SiteOption } from "api";

import {
	SiteOptionsContextProviderProps,
	InitSiteOptionsContext,
} from "./types";

const initSiteOptionsContext: InitSiteOptionsContext = {
	siteOptions: [],
};

const siteOptionsContext = createContext(initSiteOptionsContext);

function SiteOptionsContextProvider({
	children,
}: SiteOptionsContextProviderProps) {
	const [siteOptions, setSiteOptions] = useState<SiteOption[]>([]);

	const { request } = useRequest();

	useEffect(() => {
		const fetchUserData = async () => {
			const result = await request({
				service: getSiteOptions,
			});

			if (result) {
				setSiteOptions(result);
			}
		};

		fetchUserData();
	}, [request]);

	return (
		<siteOptionsContext.Provider
			value={{
				siteOptions,
			}}
		>
			{children}
		</siteOptionsContext.Provider>
	);
}

export { siteOptionsContext, SiteOptionsContextProvider };
