import { createContext, useCallback, useState } from "react";

import {
	FilterContextProviderProps,
	InitFilterContext,
	FilterParam,
} from "./types";

const initFilterContext: InitFilterContext = {
	visible: false,
	addedFilterParams: {},
	appliedFilterParams: {},
	onApplyFilterParams: () => {},
	onChangeFilterParam: () => {},
	onResetFilterParams: () => {},
	onCloseFilterMenu: () => {},
	onOpenFilterMenu: () => {},
};

const filterContext = createContext(initFilterContext);

function FilterContextProvider({ children }: FilterContextProviderProps) {
	const [visible, setVisible] = useState(false);
	const [addedFilterParams, setAddedFilterParams] = useState<FilterParam>({});
	const [appliedFilterParams, setAppliedFilterParams] = useState<FilterParam>(
		{},
	);

	const applyFilterParamsHandler = () => {
		setAppliedFilterParams(addedFilterParams);
	};

	const openFilterMenuHandler = () => {
		setVisible(true);
	};

	const closeFilterMenuHandler = () => {
		setVisible(false);
	};

	const changeFilterParamHandler = useCallback(
		(filterKey: string, value: number, overwritable = false) => {
			setAddedFilterParams((prevState) => {
				const isKeyExist = filterKey in prevState;
				const isParamExist = prevState[filterKey]?.includes(value);

				const newParams = prevState[filterKey]?.slice();

				if (!isKeyExist) {
					return {
						...prevState,
						[filterKey]: [value],
					};
				} else if (isKeyExist && overwritable) {
					return {
						...prevState,
						[filterKey]: [value],
					};
				} else if (isKeyExist && !isParamExist) {
					newParams.push(value);

					return { ...prevState, [filterKey]: newParams };
				} else if (isKeyExist && isParamExist) {
					const foundParamValueIndex = newParams.findIndex(
						(optionId) => value === optionId,
					);

					newParams.splice(foundParamValueIndex, 1);

					return { ...prevState, [filterKey]: newParams };
				}

				return prevState;
			});
		},
		[],
	);

	const resetFilterParamsHandler = useCallback(() => {
		setAddedFilterParams({});
		setAppliedFilterParams({});
	}, []);

	return (
		<filterContext.Provider
			value={{
				visible,
				addedFilterParams,
				appliedFilterParams,
				onChangeFilterParam: changeFilterParamHandler,
				onApplyFilterParams: applyFilterParamsHandler,
				onResetFilterParams: resetFilterParamsHandler,
				onOpenFilterMenu: openFilterMenuHandler,
				onCloseFilterMenu: closeFilterMenuHandler,
			}}
		>
			{children}
		</filterContext.Provider>
	);
}

export { FilterContextProvider, filterContext };
