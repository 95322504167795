import { useState, useCallback } from "react";

import { RequestParams } from "api/types";

function useRequest() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<null | string>(null);

	const request = useCallback(
		async <T>({ service, loadingDelay }: RequestParams<T>) => {
			let timerId: NodeJS.Timeout | undefined = undefined;

			if (!loadingDelay) {
				setLoading(true);
			} else {
				timerId = setTimeout(() => {
					setLoading(true);
				}, loadingDelay);
			}

			try {
				return await service();
			} catch (err) {
				if (err instanceof Error) {
					setError(err.message);
				}
			} finally {
				if (timerId) {
					clearTimeout(timerId);
				}
				setLoading(false);
			}
		},
		[],
	);

	return {
		loading,
		error,
		request,
	};
}

export { useRequest };
