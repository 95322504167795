import { createContext, useState, useEffect } from "react";

import { useRequest, getMainPageData, MainPageData } from "api";

import { InitMainPageContext, MainPageContextProviderProps } from "./types";

const initMainPageContext: InitMainPageContext = {
	mainPageData: null,
	mainPageDataLoading: false,
};

const mainPageContext = createContext(initMainPageContext);

function MainPageContextProvider({ children }: MainPageContextProviderProps) {
	const [mainPageData, setMainPageData] = useState<MainPageData | null>(null);

	const { request, loading } = useRequest();

	useEffect(() => {
		const fetchMainPageData = async () => {
			const result = await request({
				service: getMainPageData,
			});

			if (result) {
				setMainPageData(result);
			}
		};

		fetchMainPageData();
	}, [request]);

	return (
		<mainPageContext.Provider
			value={{
				mainPageData,
				mainPageDataLoading: loading,
			}}
		>
			{children}
		</mainPageContext.Provider>
	);
}

export { mainPageContext, MainPageContextProvider };
