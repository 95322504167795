import { useContext, useRef } from "react";

import cn from "classnames";

import { favoritesContext } from "features/favorites";

import { useProductConfig } from "features/catalog/hooks/useProductConfig";

import { IntensivityScale } from "../IntensivityScale";
import { ProductVolume } from "../ProductVolume";
import { ProductImagePlaceholder } from "../ProductImagePlaceholder";
import { ProductQuantity, cartContext } from "features/cart";

import { Select } from "components/UI/Select";
import { Link } from "components/common/Link";

import { ProductItemProps } from "./types";

import { ReactComponent as Like } from "assets/vectors/like.svg";
import { ReactComponent as Cart } from "assets/vectors/cart-white.svg";
import { ReactComponent as NewBadge } from "assets/vectors/new-badge.svg";

import "./ProductItem.styles.scss";

function ProductItem({ productData, cartData }: ProductItemProps) {
	const addToCartButtonRef = useRef<SVGSVGElement>(null);

	const {
		id,
		type,
		title,
		isNew,
		image,
		cooking,
		acidity,
		sheetSize,
		descriptionShort,
		items: options,
	} = productData;

	const { onAddProductToCart, onRemoveProductFromCart } = useContext(cartContext);

	const {
		dedupedWeightOptions,
		formattedGrindMethods,
		availableWeightOptions,
		defaultSelectedGrindMethod,
		selectedWeight,
		selectedGrindMethod,
		changeSelectedGrindMethodHandler,
		changeSelectedWeightHandler,
		addProductToCartHandler: onAddFirstProductToCart,
	} = useProductConfig({ id, options });

	const foundOption = options.find(option => option.weight.id === selectedWeight?.id && option.grind?.id === selectedGrindMethod?.value);

	const existCartProduct = cartData.find(data => foundOption?.id === data.catalogProductId);

	const { favoritesProducts, onToggleFavoriteProduct } =
		useContext(favoritesContext);

	const isFavorites = favoritesProducts.includes(id);

	const imageContent = image?.thumb ? (
		<img src={image.thumb} alt="" />
	) : (
		<ProductImagePlaceholder />
	);

	const toggleFavoritesProductHandler = () => {
		onToggleFavoriteProduct({ id });
	};

	const animateButtonHandler = () => {
		if (!addToCartButtonRef.current) return;

		const newspaperSpinning = [
			{ transform: "scale(1)" },
			{ transform: "scale(1.5)" },
			{ transform: "scale(1)" },
		];

		const newspaperTiming = {
			duration: 200,
			iterations: 1,
		};

		addToCartButtonRef.current.animate(newspaperSpinning, newspaperTiming);
	};

	const isProductTea = type === 2;

	const descriptionContent = descriptionShort;
	let cookingTypeContent = cooking;

	if (isProductTea) {
		// descriptionContent = descriptionShort;
		cookingTypeContent = sheetSize;
	}

	const addProductToCartHandler = () => {
		if (!existCartProduct) {
			return;
		}

		onAddProductToCart({
			productId: id,
			catalogProductId: existCartProduct.catalogProductId,
		});
	};

	const removeProductFromCartHandler = () => {
		if (!existCartProduct) {
			return;
		}

		onRemoveProductFromCart({
			productId: id,
			catalogProductId: existCartProduct.catalogProductId,
			count: existCartProduct.count,
		});
	};

	return (
		<div className="product-col">
			<div className="product">
				<Link
					nav={false}
					classes="product__name"
					to={`/catalog/${id}`}
					autoScrollable={true}
				>
					{title}
				</Link>
				<div className="product__row">

					<div className="product__info-wrap">
						<span className="product__type">{cookingTypeContent}</span>
						{isNew && <div className="product__new-wrap">
							<NewBadge />
						</div>}
					</div>
				</div>

				<div className="product__head">

					{!!acidity && <IntensivityScale
						productId={id}
						propertyName="acidity"
						propertyTitle="Кислотность"
						filledSize={acidity}
					/>}
					{/* <IntensivityScale
						productId={id}
						propertyName="chocolate"
						propertyTitle="Шоколадность"
						filledSize={chocolate}
					/> */}
				</div>
				<Link nav={false} to={`/catalog/${id}`} autoScrollable={true}>
					<div className="product__img">{imageContent}</div>
				</Link>
				<div className="product__info">
					<Link nav={false} to={`/catalog/${id}`} autoScrollable={true}>
						<p className="product__desc">{descriptionContent}</p>
					</Link>
					<div className="product__price-wrap">
						{dedupedWeightOptions.map((option) => {
							let currentPrice = availableWeightOptions.find(
								(availableWeight) =>
									availableWeight.weight.id === option.weight.id,
							)?.price;

							if (!availableWeightOptions.length) {
								currentPrice = option.price;
							}

							return (
								<ProductVolume
									key={"option" + option.id}
									productId={id}
									checked={
										!!selectedWeight && selectedWeight.id === option.weight.id
									}
									disabled={currentPrice === undefined}
									currentPrice={currentPrice}
									isSingleOption={dedupedWeightOptions.length === 1}
									productOptionData={option}
									onChangeWeight={changeSelectedWeightHandler}
								/>
							);
						})}
					</div>
				</div>
				<div className="product__footer">
					{defaultSelectedGrindMethod && (
						<Select
							options={formattedGrindMethods}
							defaultValue={defaultSelectedGrindMethod}
							onChangeValue={changeSelectedGrindMethodHandler}
						/>
					)}
					<div className="product__btn-wrap">
						{existCartProduct &&
							foundOption?.weight.id === selectedWeight?.id &&
							foundOption?.grind?.id === selectedGrindMethod?.value ?
							<>
								<Link
									to="/cart"
									autoScrollable={true}
									nav={false}
									classes="filter-show"
								>В корзине</Link>
								<ProductQuantity
									count={existCartProduct.count}
									onIncrease={addProductToCartHandler}
									onDecrease={removeProductFromCartHandler}
								/> </> :
							<button
								type="button"
								className="product__btn"
								onClick={() => {
									onAddFirstProductToCart();
									animateButtonHandler();
								}}
							>
								<Cart ref={addToCartButtonRef} />
								<span>Добавить в корзину</span>
							</button>}

						<button
							type="button"
							className={cn("product__fav", isFavorites && "active")}
							onClick={toggleFavoritesProductHandler}
						>
							<Like />
						</button>
					</div>
				</div>
			</div>
		</div >
	);
}

export { ProductItem };
