import { createContext, useState, useCallback } from "react";

import {
	InitRegistrationContext,
	RegistrationContextProviderProps,
	SetRegistrationDataPayload,
	RegistrationData,
} from "./types";

const initRegistrationContext: InitRegistrationContext = {
	registrationData: {
		email: "",
		phone: "",
		firstName: "",
		lastName: "",
		password: "",
	},
	onSetRegistrationData: () => {},
};

const registrationContext = createContext(initRegistrationContext);

function RegistrationContextProvider({
	children,
}: RegistrationContextProviderProps) {
	const [registrationData, setRegistrationData] =
		useState<RegistrationData>(initRegistrationContext.registrationData);

	const setRegistrationDataHandler = useCallback(
		(payload: SetRegistrationDataPayload) => {
			setRegistrationData(payload.data);
		},
		[],
	);

	return (
		<registrationContext.Provider
			value={{
				registrationData,
				onSetRegistrationData: setRegistrationDataHandler,
			}}
		>
			{children}
		</registrationContext.Provider>
	);
}

export { registrationContext, RegistrationContextProvider };
