import cn from "classnames";

import { DescriptionSectionProps } from "./types";

import "./DescriptionSection.styles.scss";

function DescriptionSection({
	title,
	step,
	text,
	classes,
	render,
}: DescriptionSectionProps) {
	return (
		<section className={cn("sub-section", classes)}>
			<div className="container">
				<div className="section-wrap">
					<div className="section-row">
						<div className="section-block">
							<div className="section-title">
								<h2 className="section-title__text">{title}</h2>
								<span className="section-title__num">{step}</span>
							</div>
						</div>
						<div className="section-block">
							<p className="section-desc">{text}</p>
							{render && render()}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export { DescriptionSection };
