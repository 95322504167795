import axios from "axios";

import { AxiosParams } from "api/types";

export async function axiosRequest<T>({
	method = "get",
	url,
	body,
	headers = {},
}: AxiosParams): Promise<T> {
	const { data } = await axios<T>({
		method,
		url,
		headers: {
			"Content-Type": "application/json",
			"Accept": "application/json",
			...headers,
		},
		data: JSON.stringify(body),
	});

	return data;
}
