import { useContext, useState, FormEvent } from "react";

import cn from "classnames";

import {
	useRequest,
	authorizeUserThroughEmail,
	authorizeUserThroughPhone,
	isResponseValidateErrorPredicate,
	isResponseErrorPredicate,
	ServerValidationError,
	ResponseValidationError,
	ResponseError,
	UserData,
} from "api";

import { authModalContext, authContext } from "features/auth";

import { Input, useInput } from "components/UI/Input";
import { Loader } from "components/UI/Loader";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ModalType } from "features/auth/data/constants";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function Login() {
	const [serverValidationErrors, setServerValidationErrors] =
		useState<ServerValidationError>({ errors: {} });
	const [sending, setSending] = useState(false);

	const { onChangeActiveModal } = useContext(authModalContext);
	const { onLogin } = useContext(authContext);

	const { request } = useRequest();

	const {
		value: loginValue,
		errorVisible: loginErrorVisible,
		errors: loginErrors,
		onChangeValue: onLoginValueChange,
		onBlur: onLoginBlur,
		onTouch: onLoginTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Телефон / email является обязательным.",
			},
		],
		serverValidationErrors:
			serverValidationErrors.errors["phone"] ||
			serverValidationErrors.errors["email"],
	});

	const {
		value: passwordValue,
		errorVisible: passwordErrorVisible,
		errors: passwordErrors,
		onChangeValue: onPasswordValueChange,
		onBlur: onPasswordBlur,
		onTouch: onPasswordTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Пароль является обязательным.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["password"],
	});

	useBodyOverflow({ active: true });
	useScrollToTop();

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onLoginTouch();
		onPasswordTouch();

		setSending(true);

		let result: ResponseValidationError | ResponseError | UserData | undefined;

		if (loginValue.includes("@")) {
			result = await request({
				service: () =>
					authorizeUserThroughEmail({
						email: loginValue,
						password: passwordValue,
					}),
			});
		} else {
			result = await request({
				service: () =>
					authorizeUserThroughPhone({
						phone: loginValue,
						password: passwordValue,
					}),
			});
		}

		setSending(false);

		if (result && isResponseValidateErrorPredicate(result)) {
			setServerValidationErrors(result);
			return;
		}

		if (result && isResponseErrorPredicate(result)) {
			setServerValidationErrors({ errors: {} });
			return;
		};

		if (result) {
			onLogin({ token: result.apiToken, userData: result });
			onChangeActiveModal({ modal: null });
		}
	};

	return (
		<div className="popup popup--login active">
			<button
				className="close-cross"
				onClick={() => onChangeActiveModal({ modal: null })}
			>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">Вход в личный кабинет</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={formSubmitHandler}>
						<div className="popup-form__input-wrap">
							<Input
								id="login"
								type="text"
								classes="popup-form__input"
								placeholder="Телефон / e-mail"
								value={loginValue}
								invalid={loginErrorVisible}
								errors={loginErrors}
								onChangeValue={onLoginValueChange}
								onBlur={onLoginBlur}
							/>

							<Input
								id="password"
								type="password"
								classes="popup-form__input"
								placeholder="Пароль"
								value={passwordValue}
								invalid={passwordErrorVisible}
								errors={passwordErrors}
								onChangeValue={onPasswordValueChange}
								onBlur={onPasswordBlur}
							/>
						</div>
						<div className="popup-form__password-recover">
							<button
								type="button"
								className="popup-form__text popup-form__text--underline"
								onClick={() =>
									onChangeActiveModal({ modal: ModalType.ForgotPassword })
								}
							>
								Забыли пароль?
							</button>
						</div>

						<div className="popup-form__btns">
							<button
								type="submit"
								className={cn("btn primary")}
								disabled={sending}
							>
								{sending ? <Loader /> : "Войти"}
							</button>
							<button
								type="button"
								className="btn secondary"
								onClick={() =>
									onChangeActiveModal({ modal: ModalType.Registration })
								}
							>
								Зарегистрироваться
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { Login };
