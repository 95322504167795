import React from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";

import { router } from "routes";

import { SeoContextProvider } from "features/seo";
import { SiteOptionsContextProvider } from "features/site-options";
import { BurgerContextProvider } from "features/burger";
import { FilterContextProvider } from "features/filter";
import { CartContextProvider } from "features/cart";
import {
	AuthContextProvider,
	AuthModalContextProvider,
	RegistrationContextProvider,
	ForgotPasswordContextProvider,
} from "features/auth";
import { FavoritesContextProvider } from "features/favorites";

import "normalize.css";
import "styles/index.scss";

const rootContainer = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(rootContainer);

root.render(
	<React.StrictMode>
		<SeoContextProvider>
			<SiteOptionsContextProvider>
				<AuthContextProvider>
					<AuthModalContextProvider>
						<CartContextProvider>
							<FavoritesContextProvider>
								<FilterContextProvider>
									<BurgerContextProvider>
										<ForgotPasswordContextProvider>
											<RegistrationContextProvider>
												<RouterProvider router={router} />
											</RegistrationContextProvider>
										</ForgotPasswordContextProvider>
									</BurgerContextProvider>
								</FilterContextProvider>
							</FavoritesContextProvider>
						</CartContextProvider>
					</AuthModalContextProvider>
				</AuthContextProvider>
			</SiteOptionsContextProvider>
		</SeoContextProvider>
	</React.StrictMode>,
);
