import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";

import {
	GET_CART_URL,
	SET_CART_PRODUCT_URL,
	REMOVE_CART_PRODUCT_URL,
	CLEAR_CART_URL,
	SET_CART_URL,
} from "./constants";

import {
	Response,
	ResponseList,
	ResponseOperationSuccess,
	ResponseError,
} from "api/types";

import {
	CartProductData,
	GetCart,
	SetCartProduct,
	RemoveCartProduct,
	ClearCart,
	ResponseCartProductUpdate,
	SetCart,
} from "./types";

export async function getCart({
	token,
}: GetCart) {
	try {
		const result = await axiosRequest<ResponseList<CartProductData>>({
			url: GET_CART_URL(),
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		throw new Error("Cart loading error!");
	}
}

export async function setCartProduct({
	productId,
	catalogProductId,
	count,
	token,
}: SetCartProduct){
	try {
		const result = await axiosRequest<Response<ResponseCartProductUpdate>>({
			url: SET_CART_PRODUCT_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: { catalogId: productId, catalogItemId: catalogProductId, count },
		});

		return result.data;
	} catch (err) {
		throw new Error("Adding product to cart error!");
	}
}

export async function removeCartProduct({
	productId,
	catalogProductId,
	token,
}: RemoveCartProduct): Promise<
	| ResponseCartProductUpdate
	| ResponseOperationSuccess
	| ResponseError
	| undefined
> {
	try {
		const result = await axiosRequest<
			ResponseCartProductUpdate | ResponseOperationSuccess
		>({
			url: REMOVE_CART_PRODUCT_URL(productId, catalogProductId),
			method: "delete",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			return err.response?.data;
		}
		throw new Error("Removing product from cart error!");
	}
}

export async function clearCart({
	token,
}: ClearCart) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: CLEAR_CART_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		throw new Error("Clearing cart error!");
	}
}

export async function setCart({
	token,
	cartProducts,
}: SetCart) {
	try {
		const result = await axiosRequest<ResponseList<CartProductData>>({
			url: SET_CART_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: { items: cartProducts },
		});

		return result.data;
	} catch (err) {
		throw new Error("Sending cart error!");
	}
}
