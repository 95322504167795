import { UserData } from "api/services/user";

import {
	ResponseValidationError,
	ResponseOperationSuccess,
	ResponseError,
} from "api/types";
import { SuccessCardOrder, SuccessOrder } from "api/services/order/types";

export function isResponseValidateErrorPredicate(
	object: object,
): object is ResponseValidationError {
	return "message" in object && "errors" in object;
}

export function isResponseOperationSuccessPredicate(
	object: object,
): object is ResponseOperationSuccess {
	return "message" in object && "status" in object && object.status === "success";
}

export function isResponseRegistrationSuccessPredicate(
	object: object,
): object is UserData {
	return "apiToken" in object;
}

export function isResponseErrorPredicate(
	object: object,
): object is ResponseError {
	return "message" in object && Object.keys(object).length === 1;
}

export const isFulfilledPromisePredicate = <T>(
	p: PromiseSettledResult<T>,
): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

export function isSuccessCardOrderPredicate(
	object: object,
): object is SuccessCardOrder {
	return (
		"data" in object &&
		typeof object.data === "object" &&
		object.data !== null &&
		"payment" in object.data &&
		typeof object.data.payment === "object" &&
		object.data.payment !== null &&
		"orderId" in object.data.payment &&
		"formUrl" in object.data.payment
	);
}

export function isSuccessOrderPredicate(
	object: object,
): object is SuccessOrder {
	return (
		"data" in object &&
		typeof object.data === "object" &&
		object.data !== null &&
		"id" in object.data
	);
}
