import { useState, useContext, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import { useRequest, getFilteredProductList, ProductItemData } from "api";

import { filterContext } from "features/filter";

import { ProductList } from "../ProductList";
import { InfiniteScroll } from "components/common/InfiniteScroll";

import { PRODUCTS_COUNT_PER_PAGE } from "features/catalog/data/constants";

function Catalog() {
	const [products, setProducts] = useState<ProductItemData[]>([]);
	const [page, setPage] = useState(1);
	const [isPageLast, setIsPageLast] = useState(false);

	const { appliedFilterParams, onResetFilterParams } =
		useContext(filterContext);

	const { request, loading } = useRequest();

	const [searchParams] = useSearchParams();

	const searchCatalogType = searchParams.get("type");

	useEffect(() => {
		return () => {
			onResetFilterParams();
		};
	}, [onResetFilterParams]);

	useEffect(() => {
		if (appliedFilterParams) {
			setPage(1);
			setIsPageLast(false);
			setProducts([]);
		}
	}, [appliedFilterParams]);

	const fetchProducts = async () => {
		if (!searchCatalogType) {
			return;
		}

		const result = await request({
			service: () =>
				getFilteredProductList({
					params: {
						...appliedFilterParams,
						page: [page],
						perPage: [PRODUCTS_COUNT_PER_PAGE],
						type: [searchCatalogType],
					},
				}),
		});

		if (result) {
			setProducts((prevState) => {
				return [...prevState, ...result.data];
			});
			setIsPageLast(page === result.lastPage);
			setPage((prevState) => prevState + 1);
			return;
		}
		setIsPageLast(true);
	};

	return (
		<ProductList
			Wrapper={(children) => (
				<InfiniteScroll
					loading={loading}
					last={isPageLast}
					fetcher={fetchProducts}
				>
					{children}
				</InfiniteScroll>
			)}
			products={products}
		/>
	);
}

export { Catalog };
