import { useContext, useState, FormEvent } from "react";

import cn from "classnames";

import InputMask from "react-input-mask";

import {
	useRequest,
	isResponseValidateErrorPredicate,
	isFulfilledPromisePredicate,
	sendRegistrationPhoneCode,
	sendRegistrationEmailCode,
	ServerValidationError,
} from "api";

import {
	authModalContext,
	registrationContext,
	ModalType,
} from "features/auth";

import { Input, useInput } from "components/UI/Input";
import { Loader } from "components/UI/Loader";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function Registration() {
	const [serverValidationErrors, setServerValidationErrors] =
		useState<ServerValidationError>({ errors: {} });
	const [sending, setSending] = useState(false);

	const { onChangeActiveModal } = useContext(authModalContext);
	const { onSetRegistrationData } = useContext(registrationContext);

	const { request } = useRequest();

	const {
		value: firstNameValue,
		invalid: firstNameInvalid,
		errorVisible: firstNameErrorVisible,
		errors: firstNameErrors,
		onChangeValue: onFirstNameValueChange,
		onBlur: onFirstNameBlur,
		onTouch: onFirstNameTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Имя является обязательным.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["firstName"],
	});

	const {
		value: lastNameValue,
		invalid: lastNameInvalid,
		errorVisible: lastNameErrorVisible,
		errors: lastNameErrors,
		onChangeValue: onLastNameValueChange,
		onBlur: onLastNameBlur,
		onTouch: onLastNameTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Фамилия является обязательным.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["lastName"],
	});

	const {
		value: phoneValue,
		errorVisible: phoneErrorVisible,
		errors: phoneErrors,
		onChangeValue: onPhoneValueChange,
		onBlur: onPhoneBlur,
		onTouch: onPhoneTouch,
	} = useInput({
		checks: [
			// {
			// 	reverseCheck: true,
			// 	regexp: /^[0-9]{12,}$/,
			// 	errorMessage:
			// 		"Количество символов в поле Телефон не может превышать 11.",
			// },
			// {
			// 	reverseCheck: true,
			// 	regexp: /^[0-9]{1,10}$/,
			// 	errorMessage:
			// 		"Количество символов в поле Телефон должно быть не менее 11.",
			// },
			// {
			// 	reverseCheck: false,
			// 	regexp: /^.+$/i,
			// 	errorMessage: "Поле Телефон является обязательным.",
			// },
			{
				reverseCheck: false,
				regexp: /^\+7 \d{3} \d{3}-\d{2}-\d{2}/i,
				errorMessage: "Поле Телефон является обязательным.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["phone"],
	});

	const {
		value: emailValue,
		errorVisible: emailErrorVisible,
		errors: emailErrors,
		onChangeValue: onEmailValueChange,
		onBlur: onEmailBlur,
		onTouch: onEmailTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Email является обязательным.",
			},
			{
				reverseCheck: false,
				regexp: /(.+)@(.+).(.+)/i,
				errorMessage: "Email указан неверно.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["email"],
	});

	const {
		value: passwordValue,
		invalid: passwordInvalid,
		errorVisible: passwordErrorVisible,
		errors: passwordErrors,
		onChangeValue: onPasswordValueChange,
		onBlur: onPasswordBlur,
		onTouch: onPasswordTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Пароль является обязательным.",
			},
			{
				reverseCheck: false,
				regexp: /^.{8,}$/,
				errorMessage:
					"Количество символов в поле Пароль должно быть не менее 8.",
			},
			{
				reverseCheck: false,
				regexp: /^[a-zA-Z0-9@$!%*#?&]+$/,
				errorMessage: "Некорректные символы в поле Пароль.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["password"],
	});

	const formIsInvalid = firstNameInvalid || lastNameInvalid || passwordInvalid;

	useBodyOverflow({ active: true });
	useScrollToTop();

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onFirstNameTouch();
		onLastNameTouch();
		onPhoneTouch();
		onEmailTouch();
		onPasswordTouch();

		const excludedPhoneValueArr = phoneValue.match(/\d+/g);

		const sendPhoneCode = async () =>
			await request({
				service: () =>
					sendRegistrationPhoneCode({
						phone: excludedPhoneValueArr ? excludedPhoneValueArr.join("") : "",
					}),
			});

		const sendEmailCode = async () =>
			await request({
				service: () => sendRegistrationEmailCode({ email: emailValue }),
			});

		setSending(true);

		const [sendingPhoneCodeResult, sendingEmailCodeResult] =
			await Promise.allSettled([sendPhoneCode(), sendEmailCode()]);

		setSending(false);

		if (
			isFulfilledPromisePredicate(sendingPhoneCodeResult) &&
			sendingPhoneCodeResult.value
		) {
			if (!isResponseValidateErrorPredicate(sendingPhoneCodeResult.value)) {
				setServerValidationErrors((prevState) => ({
					errors: {
						...prevState.errors,
						phone: [],
					},
				}));
			} else {
				setServerValidationErrors(sendingPhoneCodeResult.value);
			}
		} else {
			setServerValidationErrors({
				errors: { ...serverValidationErrors.errors, phone: [] },
			});
		}

		if (
			isFulfilledPromisePredicate(sendingEmailCodeResult) &&
			sendingEmailCodeResult.value
		) {
			if (!isResponseValidateErrorPredicate(sendingEmailCodeResult.value)) {
				setServerValidationErrors((prevState) => ({
					errors: {
						...prevState.errors,
						email: [],
					},
				}));
			} else {
				const errors = sendingEmailCodeResult.value.errors;

				setServerValidationErrors((prevState) => {
					return {
						errors: { ...prevState.errors, ...errors },
					};
				});
			}
		} else {
			setServerValidationErrors({
				errors: { ...serverValidationErrors.errors, email: [] },
			});
		}

		if (
			!formIsInvalid &&
			isFulfilledPromisePredicate(sendingPhoneCodeResult) &&
			sendingPhoneCodeResult.value &&
			!isResponseValidateErrorPredicate(sendingPhoneCodeResult.value) &&
			isFulfilledPromisePredicate(sendingEmailCodeResult) &&
			sendingEmailCodeResult.value &&
			!isResponseValidateErrorPredicate(sendingEmailCodeResult.value)
		) {
			onSetRegistrationData({
				data: {
					firstName: firstNameValue,
					lastName: lastNameValue,
					phone: phoneValue,
					email: emailValue,
					password: passwordValue,
				},
			});
			onChangeActiveModal({ modal: ModalType.RegistrationCode });
		}
	};

	const closeRegistrationModalHandler = () => {
		onChangeActiveModal({ modal: null });
		onSetRegistrationData({
			data: {
				email: "",
				phone: "",
				password: "",
				firstName: "",
				lastName: "",
			},
		});
	};

	return (
		<div className="popup popup--registration active">
			<button className="close-cross" onClick={closeRegistrationModalHandler}>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">Регистрация</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={formSubmitHandler}>
						<Input
							id="firstName"
							type="text"
							classes="popup-form__input"
							placeholder="Имя*"
							value={firstNameValue}
							invalid={firstNameErrorVisible}
							errors={firstNameErrors}
							onChangeValue={onFirstNameValueChange}
							onBlur={onFirstNameBlur}
						/>
						<Input
							id="lastName"
							type="text"
							classes="popup-form__input"
							placeholder="Фамилия*"
							value={lastNameValue}
							invalid={lastNameErrorVisible}
							errors={lastNameErrors}
							onChangeValue={onLastNameValueChange}
							onBlur={onLastNameBlur}
						/>
						{/* <Input
							id="phone"
							type="number"
							classes="popup-form__input"
							placeholder="Телефон*"
							value={phoneValue}
							invalid={phoneErrorVisible}
							errors={phoneErrors}
							onChangeValue={onPhoneValueChange}
							onBlur={onPhoneBlur}
						/> */}
						<div
							className={cn(
								"popup-form__valid",
								phoneErrorVisible && "invalid",
							)}
						>
							<InputMask
								mask="+7 999 999-99-99"
								id={"phone"}
								type={"text"}
								placeholder="+7 ___ ___-__-__"
								className={cn(
									"popup-form__input",
									phoneErrorVisible && "invalid",
								)}
								value={phoneValue}
								onChange={(event) => onPhoneValueChange(event.target.value)}
								onBlur={onPhoneBlur}
							/>
							{phoneErrors.map((error, index) => (
								<span key={"phone" + index} className="popup-form__invalid">
									Ошибка: {error}
								</span>
							))}
						</div>
						<Input
							id="email"
							type="text"
							classes="popup-form__input"
							placeholder="E-mail*"
							value={emailValue}
							invalid={emailErrorVisible}
							errors={emailErrors}
							onChangeValue={onEmailValueChange}
							onBlur={onEmailBlur}
						/>
						<Input
							id="password"
							type="password"
							classes="popup-form__input"
							placeholder="Придумайте пароль*"
							value={passwordValue}
							invalid={passwordErrorVisible}
							errors={passwordErrors}
							onChangeValue={onPasswordValueChange}
							onBlur={onPasswordBlur}
						/>
						<div className="popup-form__descr">
							<span className="popup-form__text">
								Нажимая кнопку зарегестрироваться, вы подтверждаете, что
								принимаете
								<a href="/" className="popup-form__text popup-form__policy">
									Политику конфиденциальности
								</a>
							</span>
						</div>

						<div className="popup-form__btns">
							<button
								type="submit"
								className={cn("btn secondary")}
								disabled={sending}
							>
								{sending ? <Loader /> : "Зарегистрироваться"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { Registration };
