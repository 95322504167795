import { memo } from "react";

import { FilterItem } from "../FilterItem";

import { FilterParamState } from "../Filter";
import { FilterListProps } from "./types";

const FilterList = memo(({ params, onChangeCheckboxState }: FilterListProps) => {
	const getKey = (param: FilterParamState, index: number) => {
		if (param.type === "range") {
			return param.items.reduce((prev, item) => prev + item.name, "");
		}

		return param.name + "-" + index;
	};

	return (
		<div className="filter__scroll-wrap">
			{params.map((param, index) => (
				<FilterItem
					key={getKey(param, index)}
					param={param}
					onChangeCheckboxState={onChangeCheckboxState}
				/>
			))}
		</div>
	);
});

export { FilterList };
