import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { WeightExceededModalProps } from "./types";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function WeightExceededModal({ onConfirmExceeded }: WeightExceededModalProps) {
	useBodyOverflow({ active: true });
	useScrollToTop();

	return (
		<div className="popup popup--registration active">
			<button className="close-cross" onClick={onConfirmExceeded}>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">Адепт, остановись!</span>
					</div>

					<div className="popup__block">
						<span className="popup__block-text">
							Допустимый вес заказа для отправки — не более 18 кг
						</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={onConfirmExceeded}>
						<div className="popup-form__btns">
							<button
								type="submit"
								className="btn secondary"
							>
								Понятно
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { WeightExceededModal };
