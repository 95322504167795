import { KOF_BASE_API_URL } from "api/constants";

const BLOG_API = "/api/v1/pages/blog";

export const GET_BLOG_FILTER_URL = () =>
	KOF_BASE_API_URL + BLOG_API + "/filter";
export const GET_FILTERED_ARTICLE_LIST_URL = (queryParams: string) =>
	KOF_BASE_API_URL + BLOG_API + "/list" + queryParams;
export const GET_FILTERED_ARTICLES_COUNT_URL = (queryParams: string) =>
	KOF_BASE_API_URL + BLOG_API + "/listCount" + queryParams;
export const GET_ARTICLE_DETAILS_URL = (articleSlug: string) =>
	KOF_BASE_API_URL + BLOG_API + "/oneSlug/" + articleSlug;