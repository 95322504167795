import { useEffect, useState } from "react";

import {
	useRequest,
	getFilteredArticleList,
	ShortArticleItemData,
	isResponseErrorPredicate,
} from "api";

import { Loader } from "components/UI/Loader";

import { ArticleItem } from "../ArticleItem";

function RecommendedArticles() {
	const [recommendedAritcles, setRecommendedAritcles] = useState<
		ShortArticleItemData[]
	>([]);

	const { request, loading } = useRequest();

	useEffect(() => {
		const fetchRecommendedArticles = async () => {
			const result = await request({
				service: () =>
					getFilteredArticleList({ params: { page: [1], perPage: [4] } }),
			});

			if (result && !isResponseErrorPredicate(result)) {
				setRecommendedAritcles(result.data);
			}
		};

		fetchRecommendedArticles();
	}, [request]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div className="blog-row">
			{recommendedAritcles.map((blog) => (
				<ArticleItem key={"blog" + blog.id} articleData={blog} />
			))}
		</div>
	);
}

export { RecommendedArticles };
