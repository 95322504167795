import cn from "classnames";

import { COMMENTS_COUNT_PER_PAGE } from "features/comments/data/constants";

import { PaginationProps } from "./types";

function Pagination({
	// currentOffset,
	currentPage,
	pagesCount,
	onChangePage,
}: PaginationProps) {
	const pagesArr = Array.from(new Array(pagesCount).keys()).map((i) => i + 1);

	return (
		<div className="comments-pagination__pages">
			{pagesArr.map((page, index) => (
				<button
					key={"pagination-btn-" + page}
					disabled={currentPage === page}
					className={cn("comments-pagination__page", { active: currentPage === page })}
					onClick={() => onChangePage(COMMENTS_COUNT_PER_PAGE * index)}
				>
					{page}
				</button>
			))}
		</div>
	);
}

export { Pagination };
