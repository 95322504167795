import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";

import {
	AUTHORIZE_USER_THROUGH_EMAIL_URL,
	AUTHORIZE_USER_THROUGH_PHONE_URL,
	REGISTER_USER_URL,
	GET_USER_DATA_URL,
	SEND_REGISTRATION_PHONE_CODE_URL,
	SEND_REGISTRATION_EMAIL_CODE_URL,
	RESET_PASSWORD_THROUGH_EMAIL,
	RESET_PASSWORD_THROUGH_PHONE,
	SET_NEW_PASSWORD_THROUGH_EMAIL,
	SET_NEW_PASSWORD_THROUGH_PHONE,
	SEND_UPDATE_EMAIL_CODE_URL,
	SEND_UPDATE_PHONE_CODE_URL,
	UPDATE_USER_DATA_URL,
} from "./constants";

import {
	isResponseValidateErrorPredicate,
	isResponseErrorPredicate,
} from "api";

import {
	Response,
	ResponseValidationError,
	ResponseOperationSuccess,
	ResponseError,
} from "api/types";
import {
	AuthorizeUserThroughEmail,
	AuthorizeUserThroughPhone,
	RegisterUser,
	GetUserData,
	UserData,
	SendRegistrationPhoneCode,
	SendRegistrationEmailCode,
	ResetPasswordThroughEmail,
	ResetPasswordThroughPhone,
	SetNewPasswordThroughEmail,
	SetNewPasswordThroughPhone,
	SendUpdatePhoneCode,
	SendUpdateEmailCode,
	UpdateUserData,
} from "./types";

export async function authorizeUserThroughEmail({
	email,
	password,
}: AuthorizeUserThroughEmail): Promise<
	UserData | ResponseValidationError | ResponseError
> {
	try {
		const result = await axiosRequest<Response<UserData>>({
			url: AUTHORIZE_USER_THROUGH_EMAIL_URL(),
			method: "post",
			body: { email, password, remember: true },
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseValidationError | ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData && isResponseValidateErrorPredicate(errorData)) {
				return errorData;
			} else if (errorData) {
				return {
					message: errorData.message,
				};
			}
		}
		throw new Error("Authorizing user through email error!");
	}
}

export async function authorizeUserThroughPhone({
	phone,
	password,
}: AuthorizeUserThroughPhone): Promise<
	UserData | ResponseValidationError | ResponseError
> {
	try {
		const result = await axiosRequest<Response<UserData>>({
			url: AUTHORIZE_USER_THROUGH_PHONE_URL(),
			method: "post",
			body: { phone, password, remember: true },
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseValidationError | ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData && isResponseValidateErrorPredicate(errorData)) {
				return errorData;
			} else if (errorData) {
				return {
					message: errorData.message,
				};
			}
		}
		throw new Error("Authorizing user through phone error!");
	}
}

export async function sendRegistrationPhoneCode({
	phone,
}: SendRegistrationPhoneCode) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SEND_REGISTRATION_PHONE_CODE_URL(),
			method: "post",
			body: {
				phone,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Sending registration phone code error!");
	}
}

export async function sendRegistrationEmailCode({
	email,
}: SendRegistrationEmailCode) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SEND_REGISTRATION_EMAIL_CODE_URL(),
			method: "post",
			body: {
				email,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Sending registration email code error!");
	}
}

export async function sendUpdatePhoneCode({ token }: SendUpdatePhoneCode) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SEND_UPDATE_PHONE_CODE_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		throw new Error("Sending update phone code error!");
	}
}

export async function sendUpdateEmailCode({ token }: SendUpdateEmailCode) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SEND_UPDATE_EMAIL_CODE_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result;
	} catch (err) {
		throw new Error("Sending update email code error!");
	}
}

export async function updateUserData({
	token,
	newUserData,
	emailCode,
	phoneCode,
	telegramUsername,
}: UpdateUserData) {
	let requestBody: Record<string, string> = {
		...newUserData,
		codeEmail: emailCode,
		codePhone: phoneCode,
		// telegramUsername,
	};

	if (telegramUsername) {
		requestBody = {
			telegramUsername,
		};
	}

	try {
		const result = await axiosRequest<Response<UserData>>({
			url: UPDATE_USER_DATA_URL(),
			method: "put",
			headers: {
				Authorization: "Bearer " + token,
			},
			body: requestBody,
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Updating user data error!");
	}
}

export async function registerUser({
	phone,
	email,
	password,
	codePhone,
	codeEmail,
	firstName,
	lastName,
}: RegisterUser) {
	try {
		const result = await axiosRequest<Response<UserData>>({
			url: REGISTER_USER_URL(),
			method: "post",
			body: {
				phone,
				email,
				password,
				codePhone,
				codeEmail,
				firstName,
				lastName,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Registration user error!");
	}
}

export async function getUserData({
	token,
}: GetUserData): Promise<UserData | ResponseError> {
	try {
		const result = await axiosRequest<Response<UserData>>({
			url: GET_USER_DATA_URL(),
			method: "post",
			headers: {
				Authorization: "Bearer " + token,
			},
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData && isResponseErrorPredicate(errorData)) {
				return { message: errorData.message };
			}
		}
		throw new Error("Getting user data error!");
	}
}

export async function resetPasswordThroughEmail({
	email,
}: ResetPasswordThroughEmail) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: RESET_PASSWORD_THROUGH_EMAIL(),
			method: "post",
			body: {
				email,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Sending reset password code error!");
	}
}

export async function resetPasswordThroughPhone({
	phone,
}: ResetPasswordThroughPhone) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: RESET_PASSWORD_THROUGH_PHONE(),
			method: "post",
			body: {
				phone,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Sending reset password code error!");
	}
}

export async function setNewPasswordThroughPhone({
	phone,
	password,
	passwordConfirmation,
	code,
}: SetNewPasswordThroughPhone) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SET_NEW_PASSWORD_THROUGH_PHONE(),
			method: "post",
			body: {
				phone,
				password,
				password_confirmation: passwordConfirmation,
				code,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Setting new password through phone error!");
	}
}

export async function setNewPasswordThroughEmail({
	email,
	password,
	passwordConfirmation,
	code,
}: SetNewPasswordThroughEmail) {
	try {
		const result = await axiosRequest<ResponseOperationSuccess>({
			url: SET_NEW_PASSWORD_THROUGH_EMAIL(),
			method: "post",
			body: {
				email,
				password,
				password_confirmation: passwordConfirmation,
				code,
			},
		});

		return result;
	} catch (err) {
		if (isAxiosError<ResponseValidationError>(err)) {
			return err.response?.data;
		}
		throw new Error("Setting new password through email error!");
	}
}
