import { memo } from "react";

import ReactSelect from "react-select";

import { SelectProps } from "./types";

import "./Select.styles.scss";

const Select = memo(({ options, defaultValue, onChangeValue }: SelectProps) => {
	return (
		<ReactSelect
			className="product-select"
			classNamePrefix="product-selecast"
			components={{ IndicatorSeparator: () => null }}
			isSearchable={false}
			options={options}
			defaultValue={defaultValue}
			onChange={onChangeValue}
		/>
	);
});

export { Select };
