import { axiosRequest } from "api/lib/axios";
import { getStringFormattedQueryParams } from "api/helpers/queryParams";

import {
	GET_FILTERED_PRODUCT_LIST_URL,
	GET_CATALOG_FILTER_URL,
	GET_FILTERED_PRODUCTS_COUNT_URL,
	GET_PRODUCT_DETAILS_URL,
	GET_CATALOG_TYPES_URL,
} from "./constants";

import {
	ResponseList,
	Response,
	FilterParams,
	FilterParamData,
	FilteredItemsCountData,
	FilteredItemsResponse,
} from "api/types";
import { ProductItemData, GetProductDetails, CatalogTypeData } from "./types";

export async function getFilteredProductList({
	params,
}: FilterParams) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<FilteredItemsResponse<ProductItemData>>({
			url: GET_FILTERED_PRODUCT_LIST_URL(formattedQueryParams),
		});

		return {
			data: result.data,
			lastPage: result.meta.last_page,
		};
	} catch (err) {
		throw new Error("Product list loading error!");
	}
}

export async function getProductsFilterParams({ params }: FilterParams) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<ResponseList<FilterParamData>>({
			url: GET_CATALOG_FILTER_URL(formattedQueryParams),
		});

		return result.data;
	} catch (err) {
		throw new Error("Filter params loading error!");
	}
}

export async function getFilteredProductsCount({ params }: FilterParams) {
	const formattedQueryParams = getStringFormattedQueryParams(params);

	try {
		const result = await axiosRequest<Response<FilteredItemsCountData>>({
			url: GET_FILTERED_PRODUCTS_COUNT_URL(formattedQueryParams),
		});

		return result.data;
	} catch (err) {
		throw new Error("Filtered products count loading error!");
	}
}

export async function getProductDetails({ productId }: GetProductDetails) {
	try {
		const result = await axiosRequest<Response<ProductItemData>>({
			url: GET_PRODUCT_DETAILS_URL(productId),
		});

		return result.data;
	} catch (err) {
		throw new Error("Product details loading error!");
	}
}

export async function getCatalogTypes() {
	try {
		const result = await axiosRequest<Response<CatalogTypeData[]>>({
			url: GET_CATALOG_TYPES_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Catalog types loading error!");
	}
}
