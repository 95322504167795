import { ArticleItem } from "../ArticleItem";

import { ArticleListProps } from "./types";

function ArticleList({ articles, Wrapper }: ArticleListProps) {
	return Wrapper(
		<div className="blog-content">
			{articles.map((article) => (
				<ArticleItem key={"article-" + article.id} articleData={article} />
			))}
		</div>,
	);
}

export { ArticleList };
