import { useEffect, useState, useCallback, useContext, memo } from "react";

import { useRequest, getComments, CommentData, Nullable } from "api";

import { authContext } from "features/auth";

import { usePagination, Pagination } from "features/pagination";

import { CommentItem } from "../CommentItem";
import { AddCommentForm } from "../AddCommentForm";
import { UnauthorizedPlaceholder } from "../UnauthorizedPlaceholder";

import { Loader } from "components/UI/Loader";

import {
	COMMENTS_COUNT_PER_PAGE,
	COMMENTS_COUNT_DEPS,
} from "features/comments/data/constants";

import { CommentsProps, FetchCommentsProps } from "./types";

const Comments = memo(({ model, id, commentsCount }: CommentsProps) => {
	const [comments, setComments] = useState<CommentData[]>([]);
	const [activeReplyId, setActiveReplyId] = useState<Nullable<number>>(null);

	const { authToken, authenticated } = useContext(authContext);

	const {
		// page,
		// perPage,
		offset,
		limit,
		totalItemsCount,
		// totalCountPages,
		// onChangePage,
		// onChangePerPage,
		// onChangeTotalCountPages,
		onChangeOffset,
		onChangeLimit,
		onChangeTotalItemsCount,
	} = usePagination({
		defaultPerPage: COMMENTS_COUNT_PER_PAGE,
	});

	const { request, loading } = useRequest();

	const fetchComments = useCallback(
		// async ({ perPage }: FetchCommentsProps) => {
		// 	return await request({
		// 		service: () =>
		// 			getComments({
		// 				token: authToken,
		// 				params: {
		// 					page: [page],
		// 					perPage: [perPage],
		// 					model: [model],
		// 					id: [id],
		// 				},
		// 			}),
		// 	});
		// },

		async ({ limit }: FetchCommentsProps) => {
			return await request({
				service: () =>
					getComments({
						token: authToken,
						params: {
							offset: [offset],
							limit: [limit],
							model: [model],
							id: [id],
						},
					}),
			});
		},
		[request, model, id, offset, authToken],
	);

	useEffect(() => {
		const updateComments = async () => {
			// const result = await fetchComments({ perPage: COMMENTS_COUNT_PER_PAGE });
			const result = await fetchComments({ limit: COMMENTS_COUNT_PER_PAGE });

			if (result) {
				// const { data, meta } = result;
				const { data, total } = result;

				setComments(data);
				// onChangeTotalCountPages(meta.last_page);
				onChangeTotalItemsCount(total);
			}
		};

		if (typeof commentsCount === "number" && commentsCount > 0) {
			updateComments();
		}
	}, [commentsCount, fetchComments, onChangeTotalItemsCount]);

	useEffect(() => {
		const updateComments = async () => {
			// const result = await fetchComments({ perPage });
			const result = await fetchComments({ limit });

			if (result) {
				const { data } = result;

				setComments(data);
			}
		};

		if (typeof commentsCount === "number" && commentsCount > 0) {
			updateComments();
		}
	}, [commentsCount, limit, fetchComments]);

	const reloadCommentsHandler = async (paginateToLastPage = false) => {
		// const result = await fetchComments({ perPage });
		const result = await fetchComments({ limit });

		if (result) {
			// const { data, meta } = result;
			const { data, total } = result;

			setComments(data);

			if (paginateToLastPage) {
				// onChangePage(meta.last_page);
				// onChangeTotalCountPages(meta.last_page);
				const pagesCount = Math.ceil(total / COMMENTS_COUNT_PER_PAGE);

				onChangeOffset(pagesCount * COMMENTS_COUNT_PER_PAGE - COMMENTS_COUNT_PER_PAGE);
				onChangeTotalItemsCount(total);
			}
		}
	};

	const changeActiveReplyIdHandler = (id: Nullable<number>) => {
		setActiveReplyId(id);
	};

	const loadMoreCommentsHandler = async () => {
		// onChangePerPage(COMMENTS_COUNT_PER_PAGE, true);
		onChangeLimit(COMMENTS_COUNT_PER_PAGE, true);
	};

	const changePageHandler = (offset: number) => {
		onChangeLimit(COMMENTS_COUNT_PER_PAGE, false);
		onChangeOffset(offset);
	};

	// let actualCurrentPage = perPage / COMMENTS_COUNT_PER_PAGE;

	// if (page > actualCurrentPage) {
	// 	actualCurrentPage = page;
	// }

	// if (
	// 	perPage / COMMENTS_COUNT_PER_PAGE === page &&
	// 	COMMENTS_COUNT_PER_PAGE === page
	// ) {
	// 	actualCurrentPage += 1;
	// }

	const currentPage =
		Math.floor(offset / COMMENTS_COUNT_PER_PAGE + limit / COMMENTS_COUNT_PER_PAGE);

	// if (limit) {
	// 	currentPage = limit / COMMENTS_COUNT_PER_PAGE;
	// }

	// if (
	// 	perPage / COMMENTS_COUNT_PER_PAGE === page &&
	// 	COMMENTS_COUNT_PER_PAGE === page
	// ) {
	// 	actualCurrentPage += 1;
	// }

	let currentCommentsCount = commentsCount || 0;

	if (totalItemsCount > currentCommentsCount) {
		currentCommentsCount = totalItemsCount;
	}

	const lastCountNumber = currentCommentsCount?.toString().at(-1);
	let commentsCountLableContent = "";

	if (typeof currentCommentsCount === "number" && currentCommentsCount === 0) {
		commentsCountLableContent = "Еще нет ни одного комментария";
	}

	if (typeof currentCommentsCount === "number" && currentCommentsCount !== 0) {
		commentsCountLableContent = "комментариев";
	}

	if (lastCountNumber && lastCountNumber in COMMENTS_COUNT_DEPS) {
		commentsCountLableContent = COMMENTS_COUNT_DEPS[lastCountNumber];
	}

	const isCommentsFetched = currentCommentsCount !== null;
	const isCommentsExists = isCommentsFetched && currentCommentsCount !== 0;

	const commentsContent = (
		<>
			{isCommentsFetched && (
				<div className="comments-count">
					{currentCommentsCount > 0 && currentCommentsCount} {commentsCountLableContent}
				</div>
			)}
			{authenticated && (
				<AddCommentForm
					id={id}
					model={model}
					onSuccessAddingComment={() => reloadCommentsHandler(true)}
					onChangeActiveReplyId={changeActiveReplyIdHandler}
				/>
			)}
			{!authenticated && <UnauthorizedPlaceholder />}
			{isCommentsExists && (
				<>
					<div>
						{comments.map((comment) => (
							<CommentItem
								key={"comment-" + comment.id}
								id={id}
								parentId={comment.id}
								commentData={comment}
								isUnauthorized={!authenticated}
								activeReplyId={activeReplyId}
								onChangeActiveReplyId={changeActiveReplyIdHandler}
								onReloadComments={reloadCommentsHandler}
							/>
						))}
					</div>

					<div className="comments-pagination">
						{currentPage < totalItemsCount / COMMENTS_COUNT_PER_PAGE && (
							<button className="comments-pagination__load" onClick={loadMoreCommentsHandler}>Загрузить еще</button>
						)}
						<Pagination
							// currentOffset={offset}
							currentPage={currentPage}
							pagesCount={Math.ceil(totalItemsCount / COMMENTS_COUNT_PER_PAGE)}
							onChangePage={changePageHandler}
						/>
					</div>
				</>
			)}
		</>
	);

	return (
		<section style={{ marginBottom: "100px" }}>
			<div className="container">
				<div className="section-title">
					<h2 className="section-title__text">
						комментарии
					</h2>
					<span className="section-title__num">03</span>
				</div>
				{(loading || !isCommentsFetched) && <Loader />}
				{!loading && isCommentsFetched && commentsContent}
			</div>
		</section>
	);
});

export { Comments };
