import { KOF_BASE_API_URL } from "api/constants";

const ORDER_API = "/api/v1/orders";

export const GET_ORDERS_FILTER_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/filter";
export const GET_FILTERED_ORDERS_HISTORY_URL = (queryParams: string) =>
	KOF_BASE_API_URL + ORDER_API + "/list" + queryParams;
export const GET_FILTERED_ORDERS_COUNT_URL = (queryParams: string) =>
	KOF_BASE_API_URL + ORDER_API + "/listCount" + queryParams;
export const GET_ORDER_DETAILS_URL = (orderId: number) =>
	KOF_BASE_API_URL + ORDER_API + "/one/" + orderId;
export const REPEAT_ORDER_URL = (orderId: number) =>
	KOF_BASE_API_URL + ORDER_API + "/repeat/" + orderId;
export const CANCEL_ORDER_URL = (orderId: number) =>
	KOF_BASE_API_URL + ORDER_API + "/cancel/" + orderId;
export const GET_PAYMENT_TYPES_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/paymentsTypes";
export const GET_DELIVERY_TYPES_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/deliveryTypes";
export const GET_DELIVERY_RECEIPT_TYPES_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/deliveryReceiptTypes";
export const GET_DELIVERY_COMPANIES_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/deliveryCompanyTypes";
export const MAKE_ORDER_URL = () => KOF_BASE_API_URL + ORDER_API + "/order";
export const CHECK_PROMOCODE_URL = () =>
	KOF_BASE_API_URL + ORDER_API + "/checkPromocode";
