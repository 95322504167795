import { memo } from "react";

import { ProductVolumeProps } from "./types";

const ProductVolume = memo(
	({
		productId,
		checked,
		disabled,
		currentPrice,
		isSingleOption,
		productOptionData,
		onChangeWeight,
	}: ProductVolumeProps) => {
		const {
			id,
			weight: { id: productWeightId, title, value },
		} = productOptionData;

		const changeRadioButtonHandler = () => {
			onChangeWeight(productWeightId, title, value);
		};

		return (
			<div className="product__price-line">
				<div className="product__price-checkbox">
					{!isSingleOption && (
						<>
							<input
								type="radio"
								id={"option" + id}
								value={id}
								checked={checked}
								disabled={disabled}
								name={"product-" + productId + "-weight"}
								className="product__check-input"
								onChange={changeRadioButtonHandler}
							/>
							<label
								htmlFor={"option" + id}
								className="product__check-label"
							></label>
						</>
					)}
				</div>
				<span className="product__weight">{title}</span>
				<span className="product__dots"></span>
				<span className="product__price">
					{currentPrice ? currentPrice : "-"} ₽{" "}
				</span>
			</div>
		);
	},
);

export { ProductVolume };
