import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

import { DescriptionSection } from "components/common/DescriptionSection";

import "components/pages/Index/PartnerSection/PartnerSectionRoot/PartnerSectionRoot.styles.scss";

function Friend() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<>
			<DescriptionSection
				title={<>{parse(mainPageData!.friend.title)}</>}
				step="04"
				text={parse(mainPageData!.friend.text) as string}
				classes="section-third"
			/>
			<section className="partner-section">
				<div className="container">
					<div className="partner-row">
						{mainPageData!.friend.images.map((image) => (
							<div key={"friend-" + image.id} className="partner">
								<img src={image.file} alt="" />
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	);
}

export { Friend };
