import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

function Postulates() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<>
			<div className="section-title">
				<h2 className="section-title__text">
					{parse(mainPageData!.postulates.title)}
				</h2>
				<span className="section-title__num">02</span>
			</div>
			<div className="postulate-content">
				{/* <div className="postulate-row"> */}
				{parse(mainPageData!.postulates.html)}
				{/* <div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Свежая обжарка</h3>
							<p className="postulate__desc">
								Все заказы мы собираем прямиком из-под рук мастеров. Даже если
								вы покупаете одну пачку, зерно обжаривается для вас перед
								отправкой.
							</p>
						</div>
					</div>
					<div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Честная цена</h3>
							<p className="postulate__desc">
								Мы работаем без посредников: закупаем зерно у одного из
								крупнейших поставщиков мира Olam International и обжариваем на
								своём производстве. Поэтому вы можете купить кофе без наценки.
							</p>
						</div>
					</div>
					<div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Глубинное знание кофе</h3>
							<p className="postulate__desc">
								Основатель KOF. — второй человек в России со званием Q-грейдера,
								сертифицированного специалиста по оценке кофе. А наша команда
								состоит из кофейных экспертов мирового уровня.
							</p>
						</div>
					</div>
					<div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Только лучшие сорта</h3>
							<p className="postulate__desc">
								Каждый из более 30 сортов в нашем каталоге — 100% арабика. И все
								они были отобраны лично основателем бренда, Даниилом Пановым.
							</p>
						</div>
					</div>
					<div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Контроль качества</h3>
							<p className="postulate__desc">
								Наше производство состоит из 6 точек контроля обжарки, а каждый
								профиль записан при помощи профессиональной программы Cropster.
								Это позволяет нам приблизить процесс обжарки и упаковки кофе к
								идеалу.
							</p>
						</div>
					</div>
					<div className="postulate-col">
						<div className="postulate">
							<h3 className="postulate__title">Комфорт для вас</h3>
							<p className="postulate__desc">
								Оплачивайте заказы по карте на сайте или наличными при
								получении. Выбирайте удобный для вас способ доставки: курьером
								по Новосибирску, почтой или СДЭКом по России.
							</p>
						</div>
					</div> */}
				{/* </div> */}
			</div>
		</>
	);
}

export { Postulates };
