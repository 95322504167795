import { useSearchParams } from "react-router-dom";
import cn from "classnames";

import { Link } from "components/common/Link";

import { DesktopNav, MobileNav } from "features/burger";

import logo from "assets/vectors/logo.svg";
import logoWgite from "assets/vectors/logo-white.svg";

import "./Header.styles.scss";

function Header() {
	const [searchParams] = useSearchParams();

	const searchCatalogType = searchParams.get("type");

	const isRedGrade = searchCatalogType === "3";

	return (
		<>
			{isRedGrade && <div className="header-shadow"></div>}

			<header className="header">
				<div className="container">
					<div className={cn("header__wrap", isRedGrade && "white")}>
						<Link nav={false} to="/" classes="logo" autoScrollable={false}>
							<img src={isRedGrade ? logoWgite : logo} alt="" />
						</Link>
						<DesktopNav />
						<MobileNav />
					</div>
				</div>
			</header>
		</>
	);
}

export { Header };
