import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

import { DescriptionSection } from "components/common/DescriptionSection";
import { Link } from "components/common/Link";

import { ArticleItem } from "features/blog";

import { ReactComponent as BlogIcon } from "assets/vectors/blog.svg";

import "./Blog.styles.scss";

function Blog() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<>
			<DescriptionSection
				title={<>{parse(mainPageData!.blog.title)}</>}
				step="06"
				text=""
				classes="section-fifth"
				render={() => (
					<Link
						nav={false}
						to={mainPageData!.blog.link}
						classes="sub-link"
						autoScrollable={true}
					>
						<BlogIcon />
						<span>{parse(mainPageData!.blog.linkTitle)}</span>
					</Link>
				)}
			/>
			<section className="blog-section">
				<div className="container">
					<div className="blog-row">
						{mainPageData!.blog.blog.map((blog) => (
							<ArticleItem key={"blog" + blog.id} articleData={blog} />
						))}
					</div>
				</div>
			</section>
		</>
	);
}

export { Blog };
