import { useState, useEffect } from "react";

import { useRequest, setNewsSubscription } from "api";

function NewsSubscription() {
	const [emailValue, setEmailValue] = useState("");
	const [subscriptionMessage, setSubscriptionMessage] = useState<string | null>(null);

	const { request, loading } = useRequest();

	useEffect(() => {
		let timerId: NodeJS.Timeout;

		if (subscriptionMessage) {
			timerId = setTimeout(() => {
				setSubscriptionMessage(null);
			}, 2000);
		}

		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [subscriptionMessage]);

	const changeEmailValueHandler = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setEmailValue(event.target.value);
	};

	const formSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const isEmailValid = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailValue);

		if (!isEmailValid) {
			setSubscriptionMessage("Некорректная почта!");
			return;
		}

		await request({
			service: () => setNewsSubscription({ email: emailValue }),
		});
		setEmailValue("");
		setSubscriptionMessage("Спасибо!");
	};

	return (
		<form className="footer-form" onSubmit={formSubmitHandler}>
			<div className="footer-form__wrap">
				<div className="footer-form__block">
					<input
						type="text"
						className="footer-form__input"
						placeholder="e-mail"
						value={emailValue}
						onChange={changeEmailValueHandler}
					/>
					{subscriptionMessage !== null && <div className="footer-form__input-message">{subscriptionMessage}</div>}
				</div>
				<div className="footer-form__block">
					<button type="submit" className="footer-form__btn" disabled={loading}>
						Отправить
					</button>
				</div>
			</div>
		</form>
	);
}

export { NewsSubscription };
