import { useState, useRef, useEffect, useContext } from "react";

import Slider from "@mui/material/Slider";

import { filterContext } from "features/filter/context/filterContext";

import { MIN_RANGE_DISTANCE } from "features/filter/data/constants";

import { FilterRangeProps } from "./types";

import "./FilterRange.styles.scss";

function FilterRange({ options }: FilterRangeProps) {
	const [rangeStart, rangeEnd] = options;

	const [rangeValues, setRangeValues] = useState<number[]>([
		rangeStart.value,
		rangeEnd.value,
	]);
	const [inputValueStart, setInputValueStart] = useState(
		rangeStart.value.toString(),
	);
	const [inputValueEnd, setInputValueEnd] = useState(rangeEnd.value.toString());

	const startInputRef = useRef<HTMLInputElement>(null);
	const endInputRef = useRef<HTMLInputElement>(null);

	const { onChangeFilterParam } = useContext(filterContext);

	useEffect(() => {
		setRangeValues([rangeStart.value, rangeEnd.value]);
		setInputValueStart(rangeStart.value.toString());
		setInputValueEnd(rangeEnd.value.toString());
	}, [rangeStart, rangeEnd]);

	const changeSliderStateHandler = (
		event: Event,
		newValue: number | number[],
		activeThumb: number,
	) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		if (activeThumb === 0) {
			setRangeValues([
				Math.min(newValue[0], rangeValues[1] - MIN_RANGE_DISTANCE),
				rangeValues[1],
			]);
			setInputValueStart(newValue[0].toString());
		} else {
			setRangeValues([
				rangeValues[0],
				Math.max(newValue[1], rangeValues[0] + MIN_RANGE_DISTANCE),
			]);
			setInputValueEnd(newValue[1].toString());
		}

		onChangeFilterParam(rangeStart.name, newValue[0], true);
		onChangeFilterParam(rangeEnd.name, newValue[1], true);
	};

	const changeInputValueHandler = (value: string, point: "start" | "end") => {
		let newValue = +value;

		if (isNaN(newValue)) return;

		if (point === "start" && newValue > rangeValues[1]) {
			newValue = rangeValues[1];
			setInputValueStart(newValue.toString());
		} else if (point === "end" && newValue < rangeValues[0]) {
			newValue = rangeValues[0];
			setInputValueEnd(newValue.toString());
		}

		if (point === "start") {
			setRangeValues([newValue, rangeValues[1]]);
			onChangeFilterParam(rangeStart.name, newValue, true);
		} else if (point === "end") {
			setRangeValues([rangeValues[0], newValue]);
			onChangeFilterParam(rangeEnd.name, newValue, true);
		}
	};

	return (
		<>
			<div className="range-slider">
				<Slider
					value={rangeValues}
					onChange={changeSliderStateHandler}
					disableSwap
					min={rangeStart.value}
					max={rangeEnd.value}
				/>
			</div>

			<div className="range-inputs">
				<div className="range-input">
					<div className="range-input__text">{rangeStart.label}</div>
					<input
						className="range-input__input"
						type="text"
						ref={startInputRef}
						value={inputValueStart}
						onKeyUp={(event) => {
							if (event.key === "Enter" && startInputRef.current) {
								changeInputValueHandler(startInputRef.current.value, "start");
							}
						}}
						onChange={(event) => setInputValueStart(event.target.value)}
						onBlur={(event) =>
							changeInputValueHandler(event.target.value, "start")
						}
					/>
				</div>
				<div className="range-input">
					<div className="range-input__text">{rangeEnd.label}</div>
					<input
						className="range-input__input"
						type="text"
						ref={endInputRef}
						value={inputValueEnd}
						onKeyUp={(event) => {
							if (event.key === "Enter" && endInputRef.current) {
								changeInputValueHandler(endInputRef.current.value, "end");
							}
						}}
						onChange={(event) => setInputValueEnd(event.target.value)}
						onBlur={(event) =>
							changeInputValueHandler(event.target.value, "end")
						}
					/>
				</div>
			</div>
		</>
	);
}

export { FilterRange };
