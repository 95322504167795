import { useContext } from "react";

import { Navigate } from "react-router-dom";

import { authContext } from "features/auth";

import { getLocalStorageItem } from "utils/storage";

import { ProtectedRouteProps } from "./types";

function ProtectedRoute({ redirectToPath, children }: ProtectedRouteProps) {
	const localSavedAuthToken = getLocalStorageItem<string>("authToken");
	const { authenticated } = useContext(authContext);

	if (!localSavedAuthToken && !authenticated) {
		return <Navigate to={redirectToPath} replace />;
	}

	return children;
}

export { ProtectedRoute };
