import { useContext } from "react";

import { seoContext } from "features/seo";

import { Helmet } from "components/common/Helmet";

function useSeo({ page }: { page: string }) {
	const { seo } = useContext(seoContext);

	const isPageSeoExist = !!seo[page];
	const pageSeo = seo[page];

	const seoConfigContent = isPageSeoExist && (
		<Helmet
			title={pageSeo.title}
			description={pageSeo.description}
			keywords={pageSeo.keywords}
		/>
	);

	return {
		seoConfigContent,
	};
}

export { useSeo };
