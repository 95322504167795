import { FormEvent, useContext } from "react";

import { useRequest, addComment, isResponseOperationSuccessPredicate } from "api";

import { authContext } from "features/auth";

import { Input, useInput } from "components/UI/Input";

import { AddCommentFormProps } from "./types";

import { ReactComponent as SvgSubmit } from "assets/vectors/submit.svg";

function AddCommentForm({
	id,
	model,
	parentId,
	onSuccessAddingComment,
	onChangeActiveReplyId,
}: AddCommentFormProps) {
	const { authToken } = useContext(authContext);

	const { request, loading } = useRequest();

	const {
		value: commentValue,
		invalid: commentInvalid,
		errorVisible: commentErrorVisible,
		errors: commentErrors,
		onChangeValue: onCommentValueChange,
		onBlur: onCommentBlur,
		onTouch: onCommentTouch,
		onReset: onCommentReset,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.{10,}$/i,
				errorMessage: "Комментарий должен содержать минимум 10 символов.",
			},
		],
	});

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onCommentTouch();

		if (commentInvalid) {
			return;
		}

		const result = await request({
			service: () =>
				addComment({
					token: authToken,
					id,
					model,
					parentId,
					message: commentValue,
				}),
		});

		if (result && isResponseOperationSuccessPredicate(result)) {
			onSuccessAddingComment();
			onCommentReset();

			parentId && onChangeActiveReplyId(null);
		}
	};

	return (
		<form onSubmit={formSubmitHandler}>
			<div className="comment-form">
				<Input
					id="comment"
					type="text"
					classes="popup-form__input"
					placeholder="Написать комментарий..."
					value={commentValue}
					invalid={commentErrorVisible}
					errors={commentErrors}
					onChangeValue={onCommentValueChange}
					onBlur={onCommentBlur}
				/>
				<button className="btn primary" type="submit" disabled={loading}>
					Отправить
					<SvgSubmit />
				</button>
			</div>
		</form>
	);
}

export { AddCommentForm };
