import { Helmet as ReactHelmet } from "react-helmet";

import { HelmetProps } from "./types";

function Helmet({ title, description, keywords }: HelmetProps) {
	return (
		<ReactHelmet>
			<title>{title}</title>
			<meta name="description" content={description || undefined} />
			<meta name="keywords" content={keywords || undefined} />
		</ReactHelmet>
	);
}

export { Helmet };
