import { Checkbox } from "../Checkbox";

import { FilterCheckboxProps } from "./types";

function FilterCheckbox({ options, name, onChangeState }: FilterCheckboxProps) {
	return (
		<div className="filter__block-scroll">
			{options.map((option, index) => (
				<Checkbox
					key={"filter-option-" + option.id + "-" + index}
					id={option.id}
					title={option.title}
					name={name}
					checked={option.checked}
					onChangeState={onChangeState}
				/>
			))}
		</div>
	);
}

export { FilterCheckbox };
