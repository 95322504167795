import cn from "classnames";

import { LoaderProps } from "./types";

import "./Loader.styles.scss";

function Loader({ classes }: LoaderProps) {
	return (
		<div className={cn("loader", classes)}>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
}
export { Loader };
