import { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import {
	useRequest,
	getArticleDetailsThroughSlug,
	AritcleItemData,
	Nullable,
} from "api";

import { Comments } from "features/comments";

import { RecomendationCatalogSection } from "components/common/RecomendationCatalogSection";

import { Helmet } from "components/common/Helmet";
import { DescriptionSection } from "components/common/DescriptionSection";

import { Loader } from "components/UI/Loader";

// import article1 from "assets/images/article1.jpg";
// import article2 from "assets/images/article2.jpg";
// import article3 from "assets/images/article3.jpg";
// import article4 from "assets/images/article4.jpg";
// import article5 from "assets/images/article5.jpg";
// import article6 from "assets/images/article6.jpg";

let isInit = true;

function ArticleDetails() {
	const navigate = useNavigate();
	const { blogId } = useParams();

	const [articleDetails, setArticleDetails] = useState<AritcleItemData>();
	const [commentsCount, setCommentsCount] = useState<Nullable<number>>(null);

	const { request, loading } = useRequest();

	useEffect(() => {
		isInit = false;

		const fetchProductDetails = async () => {
			if (!blogId) return;
			const result = await request({
				service: () => getArticleDetailsThroughSlug({ articleSlug: blogId }),
			});

			if (result) {
				setArticleDetails(result);
				return;
			}

			navigate("/not-found");
		};

		fetchProductDetails();
	}, [navigate, request, blogId]);

	useEffect(() => {
		return () => {
			isInit = true;
		};
	}, []);

	useEffect(() => {
		if (!articleDetails) {
			return;
		}

		const commentsCount = articleDetails.allComments;

		setCommentsCount(commentsCount);
	}, [articleDetails]);

	if (loading || isInit || !articleDetails) {
		return <Loader />;
	}

	const { description, seoTitle, seoKeywords, seoDescription } = articleDetails;

	return (
		<>
			<Helmet
				title={seoTitle}
				description={seoDescription}
				keywords={seoKeywords}
			/>
			{/* <section className="secret-section">
				<div className="container">
					<div className="secret-section-wrap">
						<div className="section-wrap">
							<div className="section-row">
								<div className="section-block">
									<div className="section-title">
										<span className="section-title__text">{title}</span>
										<span className="section-title__num">01</span>
									</div>
								</div>
								<div className="section-block">
									<p className="section-desc">{subtitle}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}

			{parse(description)}

			{blogId && (
				<Comments model="blog" id={+articleDetails.id} commentsCount={commentsCount} />
			)}

			<DescriptionSection
				title={<>Рекомендуем</>}
				step="04"
				text=""
				classes="section-rec"
			/>
			<RecomendationCatalogSection />
		</>
	);
}

export { ArticleDetails };
