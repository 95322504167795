import { Link } from "components/common/Link";
import { ProductImagePlaceholder } from "features/catalog";

// import blog from "assets/images/blog.png";
import { ArticleItemProps } from "./types";

import "./ArticleItem.styles.scss";

function ArticleItem({ articleData }: ArticleItemProps) {
	const { slug, title, rubrica, tag, announce, image } = articleData;

	const imageContent = image?.thumb ? (
		<img src={image.thumb} alt="" />
	) : (
		<ProductImagePlaceholder />
	);

	return (
		<div className="blog">
			<div className="blog__wrap">
				<div className="blog__head">
					<Link
						nav={false}
						to={"/blog/" + slug}
						classes="blog__name"
						autoScrollable={true}
					>
						{title}
					</Link>
				</div>
				<div className="blog__body">
					<div className="blog__img">{imageContent}</div>
					<p className="blog__desc">{announce}</p>
				</div>
				<div className="blog__teg-wrap">
					<div className="blog__teg">
						<span className="blog__teg-text">{rubrica}</span>
					</div>
					<div className="blog__teg">
						<span className="blog__teg-text">{tag}</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export { ArticleItem };
