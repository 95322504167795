import { useState, useContext, useMemo, useCallback } from "react";

import { SingleValue } from "react-select";

import { cartContext } from "features/cart";

import { ProductOption } from "api";

import { getDeduplicatedOptions } from "features/catalog/helpers/deduplication";
import { getFormattedOptions } from "features/catalog/helpers/formatting";

function useProductConfig({
	id,
	options,
}: {
	id?: number;
	options?: ProductOption[];
}) {
	const { onAddProductToCart } = useContext(cartContext);

	const dedupedGrindOptions = useMemo(() => {
		if (!options) return [];
		return getDeduplicatedOptions(options, "grind");
	}, [options]);

	const dedupedWeightOptions = useMemo(() => {
		if (!options) return [];
		return getDeduplicatedOptions(options, "weight");
	}, [options]);

	const formattedGrindMethods = useMemo(
		() => getFormattedOptions(dedupedGrindOptions, "grind"),
		[dedupedGrindOptions],
	);

	const defaultSelectedGrindMethod = formattedGrindMethods?.[0] || null;
	const defaultSelectedWeightIndex = useMemo(() => {
		if (!options) return -1;

		if (!defaultSelectedGrindMethod) {
			return 0;
		}
		return options.findIndex(
			(option) => option.grind?.id === defaultSelectedGrindMethod.value,
		);
	}, [defaultSelectedGrindMethod, options]);

	const [selectedWeight, setSelectedWeight] = useState(() => {
		if (defaultSelectedWeightIndex === -1) {
			return null;
		}

		return options?.[defaultSelectedWeightIndex].weight || null;
	});
	const [selectedGrindMethod, setSelectedGrindMethod] = useState(
		defaultSelectedGrindMethod,
	);

	const availableWeightOptions = useMemo(() => {
		if (!options || !selectedGrindMethod) return [];
		return options.filter(
			(option) => option.grind?.id === selectedGrindMethod.value,
		);
	}, [options, selectedGrindMethod]);

	const reinitSelectedOptionsHandler = () => {
		setSelectedWeight(options?.[defaultSelectedWeightIndex]?.weight || null);
		setSelectedGrindMethod(defaultSelectedGrindMethod);
	};

	const changeSelectedGrindMethodHandler = useCallback(
		(
			newValue: SingleValue<{
				value: number;
				label: string;
			}>,
		) => {
			if (!options || !selectedWeight) return;
			if (newValue) {
				const newGrindId = newValue.value;

				let newSelectedWeightIndex = options.findIndex(
					(option) =>
						option.grind?.id === newGrindId &&
						selectedWeight.id === option.weight.id,
				);

				if (newSelectedWeightIndex === -1) {
					newSelectedWeightIndex = options.findIndex(
						(option) => option.grind?.id === newGrindId,
					);
				}

				setSelectedGrindMethod({ ...newValue, isDisabled: false });
				setSelectedWeight(options[newSelectedWeightIndex].weight);
			}
		},
		[options, selectedWeight],
	);

	const changeSelectedWeightHandler = (id: number, title: string, value: number) => {
		setSelectedWeight({ id, title, value });
	};

	const addProductToCartHandler = () => {
		if (!options || !selectedWeight || !id) return;

		const catalogProductIndex = options.findIndex(
			(option) =>
				option.weight.id === selectedWeight.id &&
				option.grind?.id === selectedGrindMethod?.value,
		);
		const catalogProductId = options[catalogProductIndex].id;

		onAddProductToCart({ productId: id, catalogProductId });
	};

	return {
		dedupedWeightOptions,
		formattedGrindMethods,
		availableWeightOptions,
		defaultSelectedGrindMethod,
		selectedWeight,
		selectedGrindMethod,
		changeSelectedGrindMethodHandler,
		changeSelectedWeightHandler,
		addProductToCartHandler,
		reinitSelectedOptionsHandler,
	};
}

export { useProductConfig };
