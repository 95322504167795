import { Link } from "components/common/Link";

import "./CartEmptyPlaceholder.styles.scss";

function CartEmptyPlaceholder() {
	return (
		<div className="empty-cart">
			<div className="cart">
				<div className="cart__head">
					<div className="section-title">
						<span className="section-title__text">корзина</span>
						<span className="section-title__num">01</span>
					</div>
				</div>
				<div className="cart__empty">
					Сейчас корзина — чистый лист.
					<Link
						to="/catalog"
						autoScrollable={true}
						nav={false}
						classes="cart__empty-btn"
					>
						Наполните
					</Link>{" "}
					её смыслом и ароматами.
				</div>
			</div>
		</div>
	);
}

export { CartEmptyPlaceholder };
