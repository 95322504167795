import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

import { DescriptionSection } from "components/common/DescriptionSection";

import { ReactComponent as Eye } from "assets/vectors/eye.svg";

function Partner() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<DescriptionSection
			title={<>{parse(mainPageData!.partner.title)}</>}
			step="05"
			text={parse(mainPageData!.partner.text) as string}
			classes="section-fourth"
			render={() => (
				<a href={mainPageData!.partner.link} className="sub-link">
					<Eye />
					<span>{parse(mainPageData!.partner.linkTitle)}</span>
				</a>
			)}
		/>
	);
}

export { Partner };
