import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";

import cn from "classnames";
import { useSearchParams } from "react-router-dom";

import { seoContext, PageSeoData } from "features/seo";
import { burgerContext } from "features/burger";
import { cartContext } from "features/cart";
import { authModalContext, authContext } from "features/auth";
import { siteOptionsContext } from "features/site-options";

import { useRequest, getMenuList, MenuItemData } from "api";

import { useBodyOverflow } from "hooks/useBodyOverflow";

import { Link } from "components/common/Link";
import { Overlay } from "components/UI/Overlay";
import { LoaderOverlay } from "components/UI/LoaderOverlay";
import { Loader } from "components/UI/Loader";

import { MenuItem } from "../MenuItem";

import { ModalType } from "features/auth/data/constants";

import { ReactComponent as Vk } from "assets/vectors/vk-head.svg";
import { ReactComponent as VkWhite } from "assets/vectors/vk-white.svg";
import { ReactComponent as Like } from "assets/vectors/like-head.svg";
import { ReactComponent as LikeWhite } from "assets/vectors/like-white.svg";
import { ReactComponent as Cart } from "assets/vectors/cart-head.svg";
import { ReactComponent as CartWhite } from "assets/vectors/cart-white.svg";
import { ReactComponent as TgWhite } from "assets/vectors/tg-outline.svg";
import { ReactComponent as Tg } from "assets/vectors/tg-red.svg";
import { ReactComponent as YouTube } from "assets/vectors/youtube-red.svg";
import { ReactComponent as YouTubeWhite } from "assets/vectors/youtube-outline.svg";

import "./DesktopNav.styles.scss";

function DesktopNav() {
	const [menuList, setMenuList] = useState<MenuItemData[]>([]);

	const { isOpen, onCloseBurger } = useContext(burgerContext);
	const { onSetSeo } = useContext(seoContext);
	const { cartProductsCount } = useContext(cartContext);
	const { onChangeActiveModal } = useContext(authModalContext);
	const { authenticated, loading, userData } = useContext(authContext);
	const { siteOptions } = useContext(siteOptionsContext);

	const [searchParams] = useSearchParams();

	const searchCatalogType = searchParams.get("type");

	const isRedGrade = searchCatalogType === "3";

	const { request, loading: loadingMenu } = useRequest();

	const vkLink = siteOptions.find((option) => option.key === "vk_link")?.value;
	const tgLink = siteOptions.find((option) => option.key === "telegram_link")?.value;
	const youtubeLink = siteOptions.find((option) => option.key === "youtube_link")?.value;

	useEffect(() => {
		const fetchMenuList = async () => {
			const result = await request({ service: getMenuList });

			if (result) {
				const formattedPagesSeo: Record<string, PageSeoData> = {};

				result.forEach((item) => {
					formattedPagesSeo[item.urlApi] = {
						title: item.headerTitle,
						keywords: item.headerKeywords,
						description: item.headerDescription,
					};
				});

				onSetSeo({ seo: formattedPagesSeo });
				setMenuList(result);
			}
		};

		fetchMenuList();
	}, [request, onSetSeo]);

	useBodyOverflow({ active: isOpen });

	const cartCountContent = cartProductsCount !== 0 && (
		<div className="mobile-nav__count">{cartProductsCount}</div>
	);

	let authContent = (
		<li className="nav__item">
			<button
				className="nav__link"
				onClick={() => {
					onChangeActiveModal({ modal: ModalType.Login });
					onCloseBurger();
				}}
			>
				Вход
			</button>
			<div className="nav__del"></div>
			<button
				className="nav__link"
				onClick={() => {
					onChangeActiveModal({ modal: ModalType.Registration });
					onCloseBurger();
				}}
			>
				Регистрация
			</button>
		</li>
	);

	if (authenticated && userData) {
		const lastName = userData.lname;
		const firstName = userData.fname[0];

		authContent = (
			<li className="nav__item">
				<Link
					nav={true}
					to="/my"
					classes="nav__link nav__link--user"
					autoScrollable={false}
				>{`${lastName} ${firstName}.`}</Link>
			</li>
		);
	}

	if (loading && !authenticated) {
		authContent = (
			<li className="nav__item">
				<LoaderOverlay />
			</li>
		);
	}

	const menuItemClickHandler = () => {
		onCloseBurger();
	};

	return (
		<nav className={cn("nav", isOpen && "active", isRedGrade && "white")}>
			<ul className="nav__list">
				{(loadingMenu || menuList.length === 0) && (
					<div
						style={{
							width: "100%",
							minHeight: "6rem",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Loader />
					</div>
				)}
				{menuList.length !== 0 &&
					menuList.map((item) => (
						<MenuItem
							key={item.name + item.id}
							path={item.urlApi}
							text={item.name}
							onClick={menuItemClickHandler}
						/>
					))}
			</ul>
			{isOpen && createPortal(<Overlay />, document.body)}
			<ul className="nav__list">
				<li className="nav__item desktop"></li>
				<li className="nav__item desktop">
					<a
						href={vkLink}
						target="_blank"
						rel="noreferrer"
						className="nav__option"
					>
						{isRedGrade ? <VkWhite /> : <Vk />}
					</a></li>
				<li className="nav__item desktop">
					<a
						href={tgLink}
						target="_blank"
						rel="noreferrer"
						className="nav__option"
					>
						{isRedGrade ? <TgWhite /> : <Tg />}
					</a>
				</li>
				<li className="nav__item">
					<a
						href={youtubeLink}
						target="_blank"
						rel="noreferrer"
						className="nav__option"
					>
						{isRedGrade ? <YouTubeWhite /> : <YouTube />}
					</a>
				</li>
				<li className="nav__item desktop">
					<Link
						nav={true}
						to="/favorites"
						classes="nav__option"
						autoScrollable={false}
					>
						{isRedGrade ? <LikeWhite /> : <Like />}
					</Link>
				</li>
				<li className="nav__item desktop">
					<Link
						nav={true}
						to="/cart"
						classes="nav__option"
						autoScrollable={false}
					>
						<>
							{isRedGrade ? <CartWhite /> : <Cart />}
							{cartCountContent}
						</>
					</Link>
				</li>
				{authContent}
			</ul>
		</nav>
	);
}

export { DesktopNav };
