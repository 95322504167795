import { useContext, useEffect, useState, FormEvent } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";

import {
	useRequest,
	getPaymentTypes,
	checkPromocode,
	OptionData,
	isResponseErrorPredicate,
} from "api";

import { cartContext } from "features/cart";
import { authContext, authModalContext, ModalType } from "features/auth";
import { siteOptionsContext } from "features/site-options";

// import { useTab } from "hooks/useTab";
import { useAccordion } from "hooks/useAccordion";

import { FreeShippingScale } from "../FreeShippingScale";
import { OptionsTab } from "../OptionsTab";
import { WeightExceededModal } from "../WeightExceededModal";

import { Overlay } from "components/UI/Overlay";
import { Input, useInput } from "components/UI/Input";

import { Step, DELIVERY_DEPENDENCIES } from "features/cart/data/constants";

import { OrderTotalProps } from "./types";

import { ReactComponent as Cross } from "assets/vectors/cross.svg";

import "./OrderTotal.styles.scss";
import "features/faq/components/Faq/Faq.styles.scss";
// import "../../../../components/pages/Faq/FaqSection/FaqSectionRoot/FaqSectionRoot.styles.scss";

function OrderTotal({
	cartProductsCount,
	fetchedProducts,
	cartProducts,
	onSubmitForm, // onChangePaymentId,
}: OrderTotalProps) {
	const [paymentOptions, setPaymentOptions] = useState<OptionData[]>([]);
	const [weightExceededConfirmed, setWeightExceededConfirmed] = useState(false);
	const [promocodeError, setPromocodeError] = useState("");

	const { siteOptions } = useContext(siteOptionsContext);

	const {
		freeDeliveryOptionName,
		processingOrder,
		freeDeliveryFrom,
		deliveryCost,
		cartStep,
		deliveryCompanyId,
		paymentOptionId,
		promocode,
		promocodeDiscount,
		onChangePaymentOptionId,
		onChangeCartStep,
		onChangePromocode,
		onChangePromocodeDiscount,
	} = useContext(cartContext);
	const { authenticated, userData } = useContext(authContext);
	const { onChangeActiveModal } = useContext(authModalContext);

	const { request } = useRequest();

	const isPromoFormVisible = !promocode.length;
	// const isPromoApplied = !!promocode.length;

	// const {
	// 	activeTabIndex: activePaymentIndex,
	// 	changeActiveTabIndexHandler: changeActivePaymentIndexHandler,
	// } = useTab({
	// 	initIndex: 1,
	// });

	const {
		accordionDescriptionRef,
		isExpanded,
		currentAccordionBodyHeight,
		toggleAccordionHandler,
		recalculateAccordionHandler,
	} = useAccordion({ defaultExpanded: false });

	const {
		value: codeValue,
		errorVisible: codeErrorVisible,
		errors: codeErrors,
		// invalid: codeInvalid,
		onChangeValue: onCodeValueChange,
		onBlur: onCodeBlur,
		onTouch: onCodeTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Промокот является обязательным.",
			},
		],
		serverValidationErrors: promocodeError.length ? [promocodeError] : [],
	});

	useEffect(() => {
		const fetchPaymentOptions = async () => {
			const result = await request({
				service: getPaymentTypes,
			});

			if (result) {
				setPaymentOptions(result);
			}
		};

		fetchPaymentOptions();
	}, [request]);

	const [totalOrderProductsWeight, totalOrderPrice] = cartProducts.reduce(
		(prev, product) => {
			if (!fetchedProducts.length) return [0, 0];

			const foundProductIndex = fetchedProducts.findIndex(
				(fetchedProduct) => fetchedProduct.id === product.productId,
			);

			if (foundProductIndex !== -1) {
				const foundProduct = fetchedProducts[foundProductIndex];

				const foundCatalogProductIndex = foundProduct.items.findIndex(
					(catalogProduct) => catalogProduct.id === product.catalogProductId,
				);

				if (foundCatalogProductIndex !== -1) {
					const foundCatalogProduct =
						foundProduct.items[foundCatalogProductIndex];
					const formattedWeight = foundCatalogProduct.weight.value;
					const weight = formattedWeight * product.count;
					const price = foundCatalogProduct.price * product.count;

					return [prev[0] + weight, prev[1] + price];
				}
			}

			return [0, 0];
		},
		[0, 0],
	);

	let isFreeDelivery = false;

	const freeShippingStartPrice = siteOptions.find(
		(option) => option.key === freeDeliveryOptionName,
	)?.value;

	if (freeDeliveryFrom) {
		isFreeDelivery = totalOrderPrice >= freeDeliveryFrom;
	}

	if (freeShippingStartPrice && !freeDeliveryFrom && freeDeliveryFrom !== 0) {
		isFreeDelivery = totalOrderPrice >= +freeShippingStartPrice;
	}

	// let discountSum = 0;

	// const promoDiscount =
	// 	((totalOrderPrice + (isFreeDelivery ? 0 : deliveryCost)) / 100) *
	// 	promocodeDiscount;

	// if (userData) {
	// 	discountSum =
	// 		((totalOrderPrice + (isFreeDelivery ? 0 : deliveryCost) - promoDiscount) /
	// 			100) *
	// 		userData.loyalty.discount;
	// }

	let loyaltyDiscount = 1;
	let personalDiscount = 1;
	if (userData) {
		loyaltyDiscount = 1 - userData.loyalty.discount / 100;
	}
	const promoDiscount = 1 - promocodeDiscount / 100;

	if (userData && userData.personalDiscount) {
		personalDiscount = 1 - userData.personalDiscount / 100;
	}

	// const totalDiscount = loyaltyDiscount * promoDiscount * personalDiscount;

	// let loyaltyDiscount = 0;
	// let personalDiscount = 0;
	// if (userData) {
	// 	loyaltyDiscount = userData.loyalty.discount;
	// }

	// if (userData && userData.personalDiscount) {
	// 	personalDiscount = userData.personalDiscount;
	// }


	const maxDiscount = Math.min(loyaltyDiscount, promoDiscount, personalDiscount);



	// const totalDiscount = 1 - maxDiscount / 100;
	// console.log(totalDiscount);

	const discountSum = +(
		totalOrderPrice -
		totalOrderPrice * maxDiscount
	).toFixed(2);

	const changeCartStepHandler = () => {
		if (cartStep === Step.CartProducts && !authenticated) {
			onChangeActiveModal({ modal: ModalType.AuthOffer });
		} else if (cartStep === Step.CartProducts && authenticated) {
			onChangeCartStep();
		} else if (cartStep === Step.Delivery && onSubmitForm) {
			onSubmitForm();
		}
	};

	const isShippingInfoVisible = cartStep === Step.CartProducts;
	const isPaymentOptionsVisible = cartStep === Step.Delivery;
	const isDiscountPriceVisible = cartStep === Step.Delivery;
	const isPromocodeVisible = cartStep === Step.Delivery;

	let mainBtnText = "Перейти к оформлению";

	if (cartStep === Step.Delivery && paymentOptionId === 2) {
		mainBtnText = "Оформить";
	} else if (cartStep === Step.Delivery && paymentOptionId !== 2) {
		mainBtnText = "Оплатить онлайн";
	}

	const filteredPaymentOptions = paymentOptions.filter((option) =>
		DELIVERY_DEPENDENCIES[deliveryCompanyId].paymentMethodIds.includes(
			option.id,
		),
	);

	useEffect(() => {
		const dependency = DELIVERY_DEPENDENCIES[deliveryCompanyId];

		const isDeliveryPaymentAvailable =
			dependency.paymentMethodIds.includes(deliveryCompanyId);

		if (isDeliveryPaymentAvailable) return;

		onChangePaymentOptionId({ id: dependency.deliveryCompanyIds[0] });
	}, [deliveryCompanyId, onChangePaymentOptionId]);

	const submitFormHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onCodeTouch();

		const result = await request({
			service: () => checkPromocode({ promocode: codeValue }),
		});

		if (result && !isResponseErrorPredicate(result)) {
			onChangePromocode({ code: result.data.code });
			onChangePromocodeDiscount({ discount: result.data.discount });
			setPromocodeError("");
			return;
		}
		if (result) {
			setPromocodeError(result.message);
		}
	};

	useEffect(() => {
		if (isPromocodeVisible && isPromoFormVisible) {
			recalculateAccordionHandler();
		}
	}, [isPromocodeVisible, isPromoFormVisible, recalculateAccordionHandler]);

	// const changePaymentIdHandler = (id: number) => {
	// 	changeActivePaymentIndexHandler(id);
	// 	onChangePaymentId(id);
	// };

	// let deliveryPrice = 0;

	const deliveryMaxWeight = siteOptions.find(
		(option) => option.key === "delivery_max_weight",
	)?.value;

	let isWeightExceeded = false;

	if (deliveryMaxWeight) {
		isWeightExceeded = totalOrderProductsWeight >= +deliveryMaxWeight;
	}

	useEffect(() => {
		if (!isWeightExceeded) {
			setWeightExceededConfirmed(false);
		}
	}, [isWeightExceeded]);

	const confirmWeightExceededHandler = () => {
		setWeightExceededConfirmed(true);
	};

	const countDeps: Record<string, string> = {
		"1": "товар",
		"2": "товара",
		"3": "товара",
		"4": "товара",
	};

	let countLableContent = "товаров";

	if (cartProductsCount && cartProductsCount in countDeps) {
		countLableContent = countDeps[cartProductsCount];
	}

	return (
		<>
			{isWeightExceeded &&
				!weightExceededConfirmed &&
				createPortal(
					<WeightExceededModal
						onConfirmExceeded={confirmWeightExceededHandler}
					/>,
					document.body,
				)}
			{isWeightExceeded &&
				!weightExceededConfirmed &&
				createPortal(<Overlay />, document.body)}
			<div className="order">
				<div className="order__head">
					<div className="section-title">
						<span className="section-title__text">ваш заказ</span>
					</div>
					<span className="order__count">
						{cartProductsCount} {countLableContent}
					</span>
					<span className="order__weight">
						{totalOrderProductsWeight / 1000} кг
					</span>
				</div>
				<div className="order__body">
					<div className="order__line-wrap">
						{!!freeDeliveryFrom && freeDeliveryFrom !== 0 && (
							<FreeShippingScale
								productCount={cartProductsCount}
								totalPrice={totalOrderPrice}
								freeDeliveryFrom={freeDeliveryFrom}
							/>
						)}
						{!freeDeliveryFrom &&
							freeShippingStartPrice &&
							freeDeliveryFrom !== 0 && (
							<FreeShippingScale
								productCount={cartProductsCount}
								totalPrice={totalOrderPrice}
								freeDeliveryFrom={+freeShippingStartPrice}
							/>
						)}
						{isPaymentOptionsVisible && (
							<OptionsTab
								listKey="payment"
								options={filteredPaymentOptions}
								activeIndex={paymentOptionId}
								onChangeActiveIndex={(id: number) =>
									onChangePaymentOptionId({ id })
								}
							/>
						)}
						<div className="order__line">
							<span className="order__line-title">
								Товары ({cartProductsCount})
							</span>
							<span className="order__dots"></span>
							<span className="order__price">{totalOrderPrice},00 ₽</span>
						</div>

						{/* {isPromoApplied && (
							<div className="order__line">
								<span className="order__line-title">Промокот</span>
								<span className="order__dots"></span>
								<span className="order__price">
									{promoDiscount === 0 ? "" : "-"}
									{Math.round(promoDiscount)} ₽
								</span>
							</div>
						)} */}
						<div className="order__line">
							<span className="order__line-title">Скидка</span>
							<span className="order__dots"></span>
							<span className="order__price">
								{discountSum === 0 ? "" : "-"}
								{discountSum
									.toLocaleString("en", {
										useGrouping: false,
										minimumFractionDigits: 2,
									})
									.replace(".", ",")}{" "}
								₽
							</span>
						</div>
						{isDiscountPriceVisible && (
							<div className="order__line">
								<span className="order__line-title">Доставка</span>
								<span className="order__dots"></span>
								<span className="order__price">
									{isFreeDelivery ? 0 : deliveryCost},00 ₽
								</span>
							</div>
						)}
						{isPromocodeVisible && isPromoFormVisible && (
							<div>
								<div className={cn("faq__block", isExpanded && "active")}>
									<div
										className={cn("faq__block-head", isExpanded && "active")}
										onClick={toggleAccordionHandler}
									>
										<span className="faq__block-name">Промокот</span>
										<div className="faq__plus">
											<Cross />
										</div>
									</div>
									<div
										className="faq__block-body"
										style={{ maxHeight: currentAccordionBodyHeight }}
									>
										<div
											className="faq__block-descr"
											ref={accordionDescriptionRef}
										>
											<form className="promo-row" onSubmit={submitFormHandler}>
												<div>
													<Input
														id="code"
														type="text"
														classes="popup-form__input promo-row__input"
														placeholder="Введите код"
														value={codeValue}
														invalid={codeErrorVisible}
														errors={[]}
														onChangeValue={onCodeValueChange}
														onBlur={onCodeBlur}
													/>
													{codeErrorVisible && (
														<span className="promo-error">
															{codeErrors.join("")}
														</span>
													)}
												</div>
												<button className="btn primary">
													Применить промокот
												</button>
											</form>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="order__total">
						<span className="order__total-title">Общая стоимость </span>
						<span className="order__price">
							{(
								totalOrderPrice -
								discountSum +
								(isFreeDelivery ? 0 : deliveryCost)
							)
								.toLocaleString("en", {
									useGrouping: false,
									minimumFractionDigits: 2,
								})
								.replace(".", ",")}{" "}
							₽
						</span>
					</div>
					<button
						type="button"
						className="btn primary"
						onClick={changeCartStepHandler}
						disabled={processingOrder}
					>
						{mainBtnText}
					</button>
					{isShippingInfoVisible && (
						<p className="order__info">
							Доступные способы доставки можно выбрать при оформлении доставки
						</p>
					)}
				</div>
			</div>
		</>
	);
}

export { OrderTotal };
