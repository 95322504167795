import { ProductQuantityProps } from "./types";

import { ReactComponent as Minus } from "assets/vectors/minus.svg";
import { ReactComponent as Plus } from "assets/vectors/plus.svg";

function ProductQuantity({
	count,
	onIncrease,
	onDecrease,
}: ProductQuantityProps) {
	return <div className="cart-product__count-wrap">
		<button
			className="cart-product__count-btn"
			onClick={onDecrease}
		>
			<Minus />
		</button>
		<span className="cart-product__count-value">{count}</span>
		<button
			className="cart-product__count-btn"
			onClick={onIncrease}
		>
			<Plus />
		</button>
	</div>;
}

export { ProductQuantity };