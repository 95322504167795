import { useEffect } from "react";

import { useScrollbarWidth } from "./useScrollbarWidth";

function useBodyOverflow({active}: {active: boolean}) {
	const scrollbarWidth = useScrollbarWidth();

	useEffect(() => {
		if (active) {
			document.body.style.overflow = "hidden";
			document.body.style.paddingRight = scrollbarWidth + "px";
		}

		return () => {
			if (active) {
				document.body.style.overflow = "visible";
				document.body.style.paddingRight = "0px";
			}
		};
	}, [scrollbarWidth, active]);
}

export { useBodyOverflow };
