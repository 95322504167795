import { useContext } from "react";

import parse from "html-react-parser";

import { mainPageContext } from "features/main-page/context/mainPageContext";

import { DescriptionSection } from "components/common/DescriptionSection";
import { Link } from "components/common/Link";

import { ProductList } from "features/catalog";

import { ReactComponent as CatalogIcon } from "assets/vectors/catalog.svg";

function Catalog() {
	const { mainPageData } = useContext(mainPageContext);

	return (
		<>
			<DescriptionSection
				title={<>{parse(mainPageData!.catalog.title)}</>}
				step="03"
				text={parse(mainPageData!.catalog.text) as string}
				classes="section-second"
				render={() => (
					<Link
						nav={false}
						to={mainPageData!.catalog.link}
						classes="sub-link"
						autoScrollable={true}
					>
						<CatalogIcon />
						<span>{parse(mainPageData!.catalog.linkTitle)}</span>
					</Link>
				)}
			/>
			<section className="catalog-section index-catalog">
				<div className="container">
					<ProductList
						Wrapper={(children) => <>{children}</>}
						products={mainPageData!.catalog.catalog}
					/>
				</div>
			</section>
		</>
	);
}

export { Catalog };
