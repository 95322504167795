import { KOF_BASE_API_URL } from "api/constants";

const USER_API = "/api/v1/users";

export const AUTHORIZE_USER_THROUGH_EMAIL_URL = () =>
	KOF_BASE_API_URL + USER_API + "/loginEmail";
export const AUTHORIZE_USER_THROUGH_PHONE_URL = () =>
	KOF_BASE_API_URL + USER_API + "/loginPhone";

export const GET_USER_DATA_URL = () => KOF_BASE_API_URL + USER_API + "/me";

export const REGISTER_USER_URL = () =>
	KOF_BASE_API_URL + USER_API + "/registration";
export const SEND_REGISTRATION_PHONE_CODE_URL = () =>
	KOF_BASE_API_URL + USER_API + "/registrationPhoneCode";
export const SEND_REGISTRATION_EMAIL_CODE_URL = () =>
	KOF_BASE_API_URL + USER_API + "/registrationEmailCode";

export const RESET_PASSWORD_THROUGH_EMAIL = () =>
	KOF_BASE_API_URL + USER_API + "/passwordResetEmail";
export const RESET_PASSWORD_THROUGH_PHONE = () =>
	KOF_BASE_API_URL + USER_API + "/passwordResetPhone";
export const SET_NEW_PASSWORD_THROUGH_PHONE = () =>
	KOF_BASE_API_URL + USER_API + "/newPasswordPhone";
export const SET_NEW_PASSWORD_THROUGH_EMAIL = () =>
	KOF_BASE_API_URL + USER_API + "/newPasswordEmail";

export const SEND_UPDATE_PHONE_CODE_URL = () =>
	KOF_BASE_API_URL + USER_API + "/updatePhoneCode";
export const SEND_UPDATE_EMAIL_CODE_URL = () =>
	KOF_BASE_API_URL + USER_API + "/updateEmailCode";
export const UPDATE_USER_DATA_URL = () =>
	KOF_BASE_API_URL + USER_API + "/update";
