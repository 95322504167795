import { axiosRequest } from "api/lib/axios";

import {
	GET_PAGE_MENU_LIST_URL,
	GET_FAQ_LIST_URL,
	GET_CONTACT_LIST_URL,
	GET_MAIN_PAGE_DATA_URL,
	GET_PAGE_HTML_URL,
} from "./constants";

import { ResponseList, Response } from "api/types";
import {
	MenuItemData,
	GetFaqList,
	GetPageHtml,
	FaqItemData,
	GetContactList,
	ContactItemData,
	MainPageData,
	PageHtmlData,
} from "./types";

export async function getMenuList() {
	try {
		const result = await axiosRequest<ResponseList<MenuItemData>>({
			url: GET_PAGE_MENU_LIST_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Menu list loading error!");
	}
}

export async function getFaqList({ pageId }: GetFaqList) {
	try {
		const result = await axiosRequest<ResponseList<FaqItemData>>({
			url: GET_FAQ_LIST_URL(pageId),
		});

		return result.data;
	} catch (err) {
		throw new Error("Faq list loading error!");
	}
}

export async function getContactList({ pageId }: GetContactList) {
	try {
		const result = await axiosRequest<ResponseList<ContactItemData>>({
			url: GET_CONTACT_LIST_URL(pageId),
		});

		return result.data;
	} catch (err) {
		throw new Error("Contact list loading error!");
	}
}

export async function getMainPageData() {
	try {
		const result = await axiosRequest<Response<MainPageData>>({
			url: GET_MAIN_PAGE_DATA_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Main page data loading error!");
	}
}

export async function getPageHtml({ pageId }: GetPageHtml) {
	try {
		const result = await axiosRequest<Response<PageHtmlData>>({
			url: GET_PAGE_HTML_URL(pageId),
		});

		return result.data;
	} catch (err) {
		throw new Error("Page loading error!");
	}
}
