import { useContext, useState, useMemo } from "react";

import cn from "classnames";
import ReactSelect, { SingleValue } from "react-select";

import { cartContext, SelectOption } from "features/cart/context/cartContext";
import { favoritesContext } from "features/favorites";

import { Select } from "components/UI/Select";

import {
	ProductImagePlaceholder,
	getDeduplicatedOptions,
	getFormattedOptions,
} from "features/catalog";
import { ProductQuantity } from "../ProductQuantity";

import { CartProductProps } from "./types";

import { ReactComponent as Delete } from "assets/vectors/delete.svg";
import { ReactComponent as Like } from "assets/vectors/like.svg";

import "./CartProduct.styles.scss";

function CartProduct({
	cartProductData,
	productsData,
	selected,
}: CartProductProps) {
	const { productId, catalogProductId, count } = cartProductData;

	const productDetails = productsData.find(
		(product) => product.id === productId,
	)!;

	const { title, image, cooking, items: catalogProducts } = productDetails;

	const foundCatalogProduct = catalogProducts.find(
		(catalogProduct) => catalogProduct.id === catalogProductId,
	);

	const dedupedGrindOptions = useMemo(
		() => getDeduplicatedOptions(catalogProducts, "grind"),
		[catalogProducts],
	);

	const formattedGrindMethods = useMemo(
		() => getFormattedOptions(dedupedGrindOptions, "grind"),
		[dedupedGrindOptions],
	);

	const dedupedWeightOptions = useMemo(
		() => getDeduplicatedOptions(catalogProducts, "weight"),
		[catalogProducts],
	);

	const defaultSelectedProduct = catalogProducts.find(
		(catalogProduct) => catalogProduct.id === catalogProductId,
	)!;

	const [selectedGrindMethod, setSelectedGrindMethod] = useState(() => {
		const grindMethod = defaultSelectedProduct.grind;

		if (!grindMethod) {
			return null;
		}

		return {
			value: grindMethod.id,
			label: grindMethod.title,
		};
	});

	const [selectedWeight, setSelectedWeight] = useState({
		value: defaultSelectedProduct.weight.id,
		label: defaultSelectedProduct.weight.title,
	});

	const formattedWeightOptions = useMemo(
		() =>
			getFormattedOptions(dedupedWeightOptions, "weight", (id) => {
				const res = catalogProducts.findIndex((catalogProduct) => {
					const grindMethod = catalogProduct.grind;

					if (!grindMethod || !selectedGrindMethod) {
						return catalogProduct.weight.id === id;
					}

					return (
						catalogProduct.weight.id === id &&
						grindMethod.id === selectedGrindMethod.value
					);
				});

				return res === -1;
			}),
		[dedupedWeightOptions, catalogProducts, selectedGrindMethod],
	);

	const totalPrice = foundCatalogProduct
		? foundCatalogProduct.price * count
		: 0;

	const {
		onAddProductToCart,
		onRemoveProductFromCart,
		onClearProductFromCart,
		onChangeProductOptionInCart,
	} = useContext(cartContext);

	const { favoritesProducts, onToggleFavoriteProduct } =
		useContext(favoritesContext);

	const isFavorites = favoritesProducts.includes(productId);

	const toggleFavoritesProductHandler = () => {
		onToggleFavoriteProduct({ id: productId });
	};

	const addProductToCartHandler = () => {
		onAddProductToCart({
			productId,
			catalogProductId,
		});
	};

	const removeProductFromCartHandler = () => {
		onRemoveProductFromCart({
			productId,
			catalogProductId,
			count,
		});
	};

	const clearProductFromCartHandler = () => {
		onClearProductFromCart({
			productId,
			catalogProductId,
		});
	};

	const changeProductOptionInCartHandler = (
		newValue: SingleValue<SelectOption>,
		optionType: "weight" | "grind",
	) => {
		if (!newValue) return;

		let foundNewCatalogProductIndex = catalogProducts.findIndex(
			(catalogProduct) =>
				catalogProduct.grind?.id === selectedGrindMethod?.value &&
				catalogProduct.weight.id === newValue.value,
		);

		if (optionType === "weight") {
			if (foundNewCatalogProductIndex === -1) {
				foundNewCatalogProductIndex = catalogProducts.findIndex(
					(catalogProduct) => catalogProduct.grind?.id === newValue.value,
				);
			}

			onChangeProductOptionInCart({
				productId,
				oldCatalogProductId: catalogProductId,
				catalogProductId: catalogProducts[foundNewCatalogProductIndex].id,
			});
			return;
		}

		foundNewCatalogProductIndex = catalogProducts.findIndex(
			(catalogProduct) =>
				catalogProduct.grind?.id === newValue.value &&
				catalogProduct.weight.id === selectedWeight.value,
		);

		if (foundNewCatalogProductIndex === -1) {
			foundNewCatalogProductIndex = catalogProducts.findIndex(
				(catalogProduct) => catalogProduct.grind?.id === newValue.value,
			);
		}

		setSelectedWeight({
			value: catalogProducts[foundNewCatalogProductIndex].weight.id,
			label: catalogProducts[foundNewCatalogProductIndex].weight.title,
		});

		onChangeProductOptionInCart({
			productId,
			oldCatalogProductId: catalogProductId,
			catalogProductId: catalogProducts[foundNewCatalogProductIndex].id,
		});
	};

	const imageContent = image?.thumb ? (
		<img src={image.thumb} alt="" />
	) : (
		<ProductImagePlaceholder />
	);

	return (
		<div className={cn("cart-product", selected && "selected")}>
			<div className="cart-product__img desktop">{imageContent}</div>
			<div className="cart-product__info">
				<div className="cart-product__line">
					<span className="cart-product__name">{title}</span>
					<span className="cart-product__type">{cooking}</span>
				</div>
				<div className="cart-product__line">
					<div className="cart-product__img mobile">{imageContent}</div>
					<div className="cart-product__col">
						<ProductQuantity count={count}
							onIncrease={addProductToCartHandler}
							onDecrease={removeProductFromCartHandler}
						/>
						<div className="cart-product__option-wrap">
							<button
								type="button"
								className="cart-product__option"
								onClick={clearProductFromCartHandler}
							>
								<Delete />
							</button>
							<button
								type="button"
								className={cn("product__fav", isFavorites && "active")}
								onClick={toggleFavoritesProductHandler}
							>
								<Like />
							</button>
						</div>
					</div>
					<div className="cart-product__first-select">
						{selectedGrindMethod && (
							<Select
								defaultValue={selectedGrindMethod}
								options={formattedGrindMethods}
								onChangeValue={(newValue) => {
									if (!newValue) return;
									setSelectedGrindMethod(newValue);
									changeProductOptionInCartHandler(newValue, "grind");
								}}
							/>
						)}
					</div>
					<div className="cart-product__second-select">
						<ReactSelect
							className="product-select"
							classNamePrefix="product-selecast"
							components={{ IndicatorSeparator: () => null }}
							isSearchable={false}
							value={selectedWeight}
							options={formattedWeightOptions}
							onChange={(newValue) => {
								if (!newValue) return;
								setSelectedWeight(newValue);
								changeProductOptionInCartHandler(newValue, "weight");
							}}
						/>
					</div>
					<span className="cart-product__price">{totalPrice} ₽</span>
				</div>
			</div>
		</div>
	);
}

export { CartProduct };
