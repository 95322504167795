import cn from "classnames";

import { InputProps } from "./types";

function Input({
	id,
	type,
	classes,
	placeholder,
	labelText,
	value,
	invalid,
	errors,
	onBlur,
	onChangeValue,
}: InputProps) {
	return (
		<div className={cn("popup-form__valid", invalid && "invalid")}>
			{labelText && (
				<label htmlFor={id} className="popup-form__label">
					{labelText}
				</label>
			)}
			<input
				id={id}
				type={type}
				className={classes}
				placeholder={placeholder}
				value={value}
				onChange={(event) => onChangeValue(event.target.value)}
				onBlur={onBlur}
			/>
			{errors.map((error, index) => (
				<span key={id + index} className="popup-form__invalid">
					Ошибка: {error}
				</span>
			))}
		</div>
	);
}

export { Input };
