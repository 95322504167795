import { KOF_BASE_API_URL } from "api/constants";

const CDEK_API = "/api/v1/cdek";
const POST_RUSSIA_API = "/api/v1/pochta";

export const GET_CDEK_REGIONS_URL = () =>
	KOF_BASE_API_URL + CDEK_API + "/regions";
export const GET_CDEK_CITIES_URL = (regionId: number) =>
	KOF_BASE_API_URL + CDEK_API + "/cities/" + regionId;
export const GET_CDEK_DELIVERY_POINTS_URL = (cityId: number) =>
	KOF_BASE_API_URL + CDEK_API + "/deliverypoints/" + cityId;

export const CALCULATE_CDEK_DELIVERY_COST = (queryParams: string) =>
	KOF_BASE_API_URL + CDEK_API + "/calculator" + queryParams;

export const CALCULATE_POST_RUSSIA_DELIVERY_COST = (queryParams: string) =>
	KOF_BASE_API_URL + POST_RUSSIA_API + "/calculator" + queryParams;
