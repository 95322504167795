import { NotFoundSection } from "components/pages/NotFound/NotFoundSection/NotFoundSectionRoot";

function NotFound() {
	return (
		<>
			<NotFoundSection />
		</>
	);
}

export { NotFound };
