export const getLocalStorageItem = <T>(key: string) => {
	const receivedValue = localStorage.getItem(key);

	if (!receivedValue) return null;

	return JSON.parse(receivedValue) as T;
};

export const addLocalStorageItem = (key: string, value: unknown) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const deleteLocalStorageItem = (key: string) => {
	localStorage.removeItem(key);
};
