import { useContext, useEffect } from "react";

import { useSearchParams } from "react-router-dom";

import { cartContext } from "features/cart";

import "components/pages/Cart/CartSection/CartSectionRoot/CartSectionRoot.styles.scss";

function OrderSuccess() {
	const { onClearCart } = useContext(cartContext);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		onClearCart();
	}, [onClearCart]);

	const orderId = searchParams.get("orderId");

	return (
		<section className="cart-section">
			<div className="container">
				<div className="empty-cart">
					<div className="cart">
						<div className="cart__head">
							<div className="section-title">
								<span className="section-title__text">успех</span>
								<span className="section-title__num">01</span>
							</div>
						</div>
						<div className="cart__empty">
							Заказ успешно оформлен. Заказ №{orderId}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export { OrderSuccess };
