export function getStringFormattedQueryParams(
	params: Record<string, string[] | number[]>
) {
	const converdedParamsArr = Object.entries(params);
	const paramsLength = converdedParamsArr.length;
	
	let resultQueryParamsString = "?";

	converdedParamsArr.forEach((pair, index) => {
		const [key, value] = pair;

		if (!value.length) return;

		resultQueryParamsString +=
			key + "=" + value + (index === paramsLength - 1 ? "" : "&");
	});

	return resultQueryParamsString;
}
