import { RecommendationCatalog } from "features/catalog";

import "./RecomendationCatalogSection.styles.scss";

function RecomendationCatalogSection() {
	return (
		<section className="catalog-section">
			<div className="container">
				<RecommendationCatalog />
			</div>
		</section>
	);
}

export { RecomendationCatalogSection };
