import {
	createContext,
	useCallback,
	useState,
	useEffect,
	useContext,
} from "react";

import { authContext } from "features/auth";

import {
	useRequest,
	setFavorites,
	getFavorites,
	isResponseOperationSuccessPredicate,
	isResponseErrorPredicate,
} from "api";

import { getLocalStorageItem, addLocalStorageItem } from "utils/storage";

import {
	InitFavoritesContext,
	FavoritesContextProviderProps,
	FavoriteProduct,
	ToggleFavoriteProductPayload,
} from "./types";

const initFavoritesContext: InitFavoritesContext = {
	favoritesLoading: false,
	favoritesProducts: [],
	onToggleFavoriteProduct: () => {},
	onResetFavorites: () => {},
};

const favoritesContext = createContext(initFavoritesContext);

function FavoritesContextProvider({ children }: FavoritesContextProviderProps) {
	const localSavedAuthToken = getLocalStorageItem<string>("authToken");
	const localSavedFavorites =
		getLocalStorageItem<FavoriteProduct[]>("favorites");

	let initFavoritesState: FavoriteProduct[] = [];

	if (localSavedFavorites && !localSavedAuthToken) {
		initFavoritesState = localSavedFavorites;
	}

	const [favoritesProducts, setFavoritesProducts] =
		useState<FavoriteProduct[]>(initFavoritesState);

	const { authToken, authenticated } = useContext(authContext);

	const { request, loading: favoritesLoading } = useRequest();

	useEffect(() => {
		if (authenticated) {
			const fetchCart = async () => {
				const result = await request({
					service: () => getFavorites({ token: authToken, model: "catalog" }),
				});

				if (result && !isResponseErrorPredicate(result)) {
					setFavoritesProducts(result.ids);
					addLocalStorageItem("favorites", []);
				}
			};

			fetchCart();
		}
	}, [authToken, authenticated, request]);

	useEffect(() => {
		if (!authenticated) {
			addLocalStorageItem("favorites", favoritesProducts);
		}
	}, [authenticated, favoritesProducts]);

	const toggleFavoriteProductHandler = useCallback(
		async (payload: ToggleFavoriteProductPayload) => {
			const { id } = payload;

			if (authenticated) {
				const result = await request({
					service: () =>
						setFavorites({
							model: "catalog",
							id,
							token: authToken,
						}),
					loadingDelay: 200,
				});

				if (!result || !isResponseOperationSuccessPredicate(result)) return;
			}

			setFavoritesProducts((prevState) => {
				const favoritesCopy = prevState.slice();

				const foundFavoritesProductIndex = favoritesCopy.findIndex(
					(productId) => productId === id,
				);

				if (foundFavoritesProductIndex !== -1) {
					favoritesCopy.splice(foundFavoritesProductIndex, 1);

					return favoritesCopy;
				}

				favoritesCopy.push(id);

				return favoritesCopy;
			});
		},
		[authToken, authenticated, request],
	);

	const resetFavoritesHandler = useCallback(() => {
		setFavoritesProducts([]);
	}, []);

	return (
		<favoritesContext.Provider
			value={{
				favoritesLoading,
				favoritesProducts,
				onToggleFavoriteProduct: toggleFavoriteProductHandler,
				onResetFavorites: resetFavoritesHandler,
			}}
		>
			{children}
		</favoritesContext.Provider>
	);
}

export { FavoritesContextProvider, favoritesContext };
