import { createContext, useState, useCallback } from "react";

import { ModalType } from "features/auth/data/constants";

import {
	AuthModalContextProviderProps,
	InitAuthModalContext,
	ChangeActiveModalPayload,
} from "./types";

const modalsContext: InitAuthModalContext = {
	activeModal: null,
	onChangeActiveModal: () => {},
};

const authModalContext = createContext(modalsContext);

function AuthModalContextProvider({ children }: AuthModalContextProviderProps) {
	const [activeModal, setActiveModal] = useState<ModalType | null>(null);

	const changeActiveModalHandler = useCallback((payload: ChangeActiveModalPayload) => {
		setActiveModal(payload.modal);
	}, []);

	return (
		<authModalContext.Provider
			value={{
				activeModal,
				onChangeActiveModal: changeActiveModalHandler,
			}}
		>
			{children}
		</authModalContext.Provider>
	);
}

export { authModalContext, AuthModalContextProvider };
