import { Outlet } from "react-router-dom";

import { Header } from "components/layouts/Header";
import { Footer } from "components/layouts/Footer";

import { AuthModals } from "features/auth";

import "./App.styles.scss";

function App() {
	return (
		<>
			<div className="wrapper">
				<Header />
				<main className="main">
					<Outlet />
				</main>
				<Footer />

				<AuthModals />
			</div></>
	);
}

export { App };
