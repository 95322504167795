import { useContext, memo } from "react";

import { filterContext } from "features/filter/context/filterContext";

import { CheckboxProps } from "./types";

import "./Checkbox.styles.scss";

const Checkbox = memo(({ id, title, name, checked, onChangeState }: CheckboxProps) => {
	const { onChangeFilterParam } = useContext(filterContext);

	const changeParamStateHandler = () => {
		onChangeFilterParam(name, id);
		onChangeState(name, id);
	};

	const uniqueId = "option-" + name + "-id-" + id;
	const uniqueName = "option-" + name + "-" + id;

	return (
		<div className="accept">
			<input
				className="accept__input sr-only"
				type="checkbox"
				name={uniqueName}
				id={uniqueId}
				checked={checked}
				onChange={changeParamStateHandler}
			/>
			<label htmlFor={uniqueId} className="accept__label">
				<span className="accept__check"></span>
				<span className="accept__name">{title}</span>
			</label>
		</div>
	);
});

export { Checkbox };
