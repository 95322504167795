import { useContext } from "react";

import { cartContext } from "features/cart";
import { authModalContext, ModalType } from "features/auth";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

function AuthOffer() {
	const { onChangeCartStep } = useContext(cartContext);
	const { onChangeActiveModal } = useContext(authModalContext);

	useBodyOverflow({ active: true });
	useScrollToTop();

	const changeCartStepHandler = () => {
		onChangeCartStep();
		onChangeActiveModal({ modal: null });
	};

	return (
		<div className="popup popup--log-reg active">
			<div className="popup__wrap">
				<div className="popup__body">
					<div className="popup__step">
						<div className="section-title">
							<span className="section-title__text">Зарегистрируйтесь</span>
							<span className="section-title__num">01</span>
						</div>
						<div className="popup__block popup__block--row">
							<span className="popup__block-text">
								В нашей программе лояльности <br></br> KOF. Fellowship и
								получите скидку 5% <br className="tablet"></br> на первый заказ
							</span>
							<button
								type="button"
								className="btn secondary"
								onClick={() =>
									onChangeActiveModal({ modal: ModalType.Registration })
								}
							>
								Зарегистрироваться
							</button>
						</div>
					</div>
					<div className="popup__step">
						<div className="section-title">
							<span className="section-title__text">Войдите</span>
							<span className="section-title__num">02</span>
						</div>
						<div className="popup__block popup__block--row">
							<span className="popup__block-text">
								В нашей программе лояльности <br></br> KOF. Fellowship и
								получите скидку 5% <br className="tablet"></br> на первый заказ
							</span>
							<button
								type="button"
								className="btn primary mob-width"
								onClick={() => onChangeActiveModal({ modal: ModalType.Login })}
							>
								Войти
							</button>
						</div>
					</div>
				</div>
				<div className="popup__footer">
					<button className="popup__footer-text" onClick={changeCartStepHandler}>
						Оформить без входа и регистрации
					</button>
				</div>
			</div>
		</div>
	);
}

export { AuthOffer };
