import { KOF_BASE_API_URL } from "api/constants";

const CATALOG_API = "/api/v1/catalogs";

export const GET_CATALOG_FILTER_URL = (queryParams: string) =>
	KOF_BASE_API_URL + CATALOG_API + "/filter" + queryParams;
export const GET_FILTERED_PRODUCT_LIST_URL = (queryParams: string) =>
	KOF_BASE_API_URL + CATALOG_API + "/list" + queryParams;
export const GET_FILTERED_PRODUCTS_COUNT_URL = (queryParams: string) =>
	KOF_BASE_API_URL + CATALOG_API + "/listCount" + queryParams;
export const GET_PRODUCT_DETAILS_URL = (productId: number) =>
	KOF_BASE_API_URL + CATALOG_API + "/one/" + productId;
export const GET_CATALOG_TYPES_URL = () =>
	KOF_BASE_API_URL + CATALOG_API + "/types";
