import { createContext, useCallback, useState } from "react";

import { BurgerContextProviderProps } from "./types";

const initBurgerContext = {
	isOpen: false,
	onOpenBurger: () => {},
	onCloseBurger: () => {},
};

const burgerContext = createContext(initBurgerContext);

function BurgerContextProvider({ children }: BurgerContextProviderProps) {
	const [isOpen, setIsOpen] = useState(false);

	const openBurgerMenuHandler = useCallback(() => {
		setIsOpen(true);
	}, []);

	const closeBurgerMenuHandler = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<burgerContext.Provider
			value={{
				isOpen,
				onOpenBurger: openBurgerMenuHandler,
				onCloseBurger: closeBurgerMenuHandler,
			}}
		>
			{children}
		</burgerContext.Provider>
	);
}

export { BurgerContextProvider, burgerContext };
