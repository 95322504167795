import { isAxiosError } from "axios";

import { axiosRequest } from "api/lib/axios";

import {
	GET_CDEK_REGIONS_URL,
	GET_CDEK_CITIES_URL,
	GET_CDEK_DELIVERY_POINTS_URL,
	CALCULATE_CDEK_DELIVERY_COST,
	CALCULATE_POST_RUSSIA_DELIVERY_COST,
} from "./constants";

import {
	ResponseList,
	Response,
	ResponseError,
	ResponseValidationError,
} from "api/types";
import {
	CdekOptionData,
	GetCdekCities,
	GetCdekDeliveryPoints,
	CdekDeliveryPointData,
	CalculateCdekDeliveryCost,
	CalculatePostRussiaDeliveryCost,
	PostRussiaDeliveryCostData,
	CdekDeliveryCostData,
} from "./types";

export async function getCdekRegions() {
	try {
		const result = await axiosRequest<ResponseList<CdekOptionData>>({
			url: GET_CDEK_REGIONS_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Cdek regions loading error!");
	}
}

export async function getCdekCities({ regionId }: GetCdekCities) {
	try {
		const result = await axiosRequest<ResponseList<CdekOptionData>>({
			url: GET_CDEK_CITIES_URL(regionId),
		});

		return result.data;
	} catch (err) {
		throw new Error("Cdek cities loading error!");
	}
}

export async function getCdekDeliveryPoints({ cityId }: GetCdekDeliveryPoints) {
	try {
		const result = await axiosRequest<ResponseList<CdekDeliveryPointData>>({
			url: GET_CDEK_DELIVERY_POINTS_URL(cityId),
		});

		return result.data;
	} catch (err) {
		return {
			message: "No offices found!",
			errors: { cdekCity: ["В данный город доставка невозможна"] },
		};
	}
}

export async function calculateCdekDeliveryCost(
	params: CalculateCdekDeliveryCost,
): Promise<ResponseValidationError | CdekDeliveryCostData> {
	const converdedParamsArr = Object.entries(params);
	const paramsLength = converdedParamsArr.length;

	let queryString = "?";

	converdedParamsArr.forEach((pair, index) => {
		const [key, value] = pair;

		if (!value.length) return;

		queryString += key + "=" + value + (index === paramsLength - 1 ? "" : "&");
	});

	try {
		const result = await axiosRequest<Response<CdekDeliveryCostData>>({
			url: CALCULATE_CDEK_DELIVERY_COST(queryString),
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData) {
				return {
					message: errorData.message,
					errors: { postcode: [errorData.message] },
				};
			}
		}
		throw new Error("Cdek calcuting delivery cost error!");
	}
}

export async function calculatePostRussiaDeliveryCost(
	params: CalculatePostRussiaDeliveryCost,
): Promise<ResponseValidationError | PostRussiaDeliveryCostData> {
	const converdedParamsArr = Object.entries(params);
	const paramsLength = converdedParamsArr.length;

	let queryString = "?";

	converdedParamsArr.forEach((pair, index) => {
		const [key, value] = pair;

		if (!value.length) return;

		queryString += key + "=" + value + (index === paramsLength - 1 ? "" : "&");
	});

	try {
		const result = await axiosRequest<Response<PostRussiaDeliveryCostData>>({
			url: CALCULATE_POST_RUSSIA_DELIVERY_COST(queryString),
		});

		return result.data;
	} catch (err) {
		if (isAxiosError<ResponseError>(err)) {
			const errorData = err.response?.data;

			if (errorData) {
				return {
					message: errorData.message,
					errors: { postcode: [errorData.message] },
				};
			}
		}
		throw new Error("Post Russia calcuting delivery cost error!");
	}
}
