import { createContext, useState, useCallback } from "react";

import {
	InitForgotPasswordContext,
	ForgotPasswordContextProviderProps,
	SetForgotPasswordPhonePayload,
	SetForgotPasswordEmailPayload,
} from "./types";

const initForgotPasswordContext: InitForgotPasswordContext = {
	phone: "",
	email: "",
	onSetForgotPasswordPhone: () => {},
	onSetForgotPasswordEmail: () => {},
};

const forgotPasswordContext = createContext(initForgotPasswordContext);

function ForgotPasswordContextProvider({
	children,
}: ForgotPasswordContextProviderProps) {
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");

	const setPhoneHandler = useCallback(
		(payload: SetForgotPasswordPhonePayload) => {
			setPhone(payload.phone);
		},
		[],
	);

	const setEmailHandler = useCallback(
		(payload: SetForgotPasswordEmailPayload) => {
			setEmail(payload.email);
		},
		[],
	);

	return (
		<forgotPasswordContext.Provider
			value={{
				phone,
				email,
				onSetForgotPasswordPhone: setPhoneHandler,
				onSetForgotPasswordEmail: setEmailHandler,
			}}
		>
			{children}
		</forgotPasswordContext.Provider>
	);
}

export { forgotPasswordContext, ForgotPasswordContextProvider };
