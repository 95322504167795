import { axiosRequest } from "api/lib/axios";

import { SET_NEWS_SUBSCRIPTION_URL, GET_SITE_OPTIONS_URL } from "./constants";

import { Response, ResponseList } from "api/types";
import { SetNewsSubscription, NewsSubscriptionData, SiteOption } from "./types";

export async function setNewsSubscription({ email }: SetNewsSubscription) {
	try {
		const result = await axiosRequest<Response<NewsSubscriptionData>>({
			url: SET_NEWS_SUBSCRIPTION_URL(),
			method: "post",
			body: { email },
		});

		return result.data;
	} catch (err) {
		throw new Error("Setting news subscription error!");
	}
}

export async function getSiteOptions() {
	try {
		const result = await axiosRequest<ResponseList<SiteOption>>({
			url: GET_SITE_OPTIONS_URL(),
		});

		return result.data;
	} catch (err) {
		throw new Error("Site options loading error!");
	}
}