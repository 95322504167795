import { useContext, useState, FormEvent } from "react";

import {
	useRequest,
	setNewPasswordThroughEmail,
	setNewPasswordThroughPhone,
	isResponseValidateErrorPredicate,
	ServerValidationError,
	ResponseOperationSuccess,
	ResponseValidationError,
} from "api";

import {
	authModalContext,
	forgotPasswordContext,
	ModalType,
} from "features/auth";

import { Input, useInput } from "components/UI/Input";
import { Loader } from "components/UI/Loader";

import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";

function CreatePassword() {
	const [serverValidationErrors, setServerValidationErrors] =
		useState<ServerValidationError>({ errors: {} });
	const [sending, setSending] = useState(false);

	const { onChangeActiveModal } = useContext(authModalContext);
	const { email, phone, onSetForgotPasswordEmail, onSetForgotPasswordPhone } =
		useContext(forgotPasswordContext);

	const { request } = useRequest();

	const {
		value: codeValue,
		errorVisible: codeErrorVisible,
		errors: codeErrors,
		onChangeValue: onCodeValueChange,
		onBlur: onCodeBlur,
		onTouch: onCodeTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Код является обязательным.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{7,}$/,
				errorMessage: "Количество символов в поле code не может превышать 6.",
			},
			{
				reverseCheck: true,
				regexp: /^[0-9]{1,3}$/,
				errorMessage: "Количество символов в поле code должно быть не менее 4.",
			},
		],
		serverValidationErrors:
			serverValidationErrors.errors["code"] ||
			serverValidationErrors.errors["codePhone"] ||
			serverValidationErrors.errors["codeEmail"],
	});

	const {
		value: passwordValue,
		errorVisible: passwordErrorVisible,
		errors: passwordErrors,
		onChangeValue: onPasswordValueChange,
		onBlur: onPasswordBlur,
		onTouch: onPasswordTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Пароль является обязательным.",
			},
			{
				reverseCheck: false,
				regexp: /^.{8,}$/,
				errorMessage:
					"Количество символов в поле Пароль должно быть не менее 8.",
			},
			{
				reverseCheck: false,
				regexp: /^[a-zA-Z0-9@$!%*#?&]+$/,
				errorMessage: "Некорректные символы в поле Пароль.",
			},
		],
		serverValidationErrors: serverValidationErrors.errors["password"],
	});

	const {
		value: passwordConfirmationValue,
		errorVisible: passwordConfirmationErrorVisible,
		errors: passwordConfirmationErrors,
		onChangeValue: onPasswordConfirmationValueChange,
		onBlur: onPasswordConfirmationBlur,
		onTouch: onPasswordConfirmationTouch,
	} = useInput({
		checks: [
			{
				reverseCheck: false,
				regexp: /^.+$/i,
				errorMessage: "Поле Пароль является обязательным.",
			},
			{
				reverseCheck: false,
				regexp: /^.{8,}$/,
				errorMessage:
					"Количество символов в поле Пароль должно быть не менее 8.",
			},
			{
				reverseCheck: false,
				regexp: /^[a-zA-Z0-9@$!%*#?&]+$/,
				errorMessage: "Некорректные символы в поле Пароль.",
			},
		],
		serverValidationErrors:
			serverValidationErrors.errors["password"] ||
			serverValidationErrors.errors["password_confirmation"],
	});

	useBodyOverflow({ active: true });
	useScrollToTop();

	const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		onCodeTouch();
		onPasswordTouch();
		onPasswordConfirmationTouch();

		setSending(true);

		let result: ResponseValidationError | ResponseOperationSuccess | undefined;

		const isEmailLogin = email !== "" && phone === "";

		if (isEmailLogin) {
			result = await request({
				service: () =>
					setNewPasswordThroughEmail({
						email,
						password: passwordValue,
						passwordConfirmation: passwordConfirmationValue,
						code: codeValue,
					}),
			});
		} else {
			result = await request({
				service: () =>
					setNewPasswordThroughPhone({
						phone,
						password: passwordValue,
						passwordConfirmation: passwordConfirmationValue,
						code: codeValue,
					}),
			});
		}

		setSending(false);

		if (result && isResponseValidateErrorPredicate(result)) {
			setServerValidationErrors(result);
			return;
		}

		if (result) {
			onChangeActiveModal({ modal: null });
		}
	};

	const closeRegistrationModalHandler = () => {
		onChangeActiveModal({ modal: ModalType.Login });
		onSetForgotPasswordPhone({ phone: "" });
		onSetForgotPasswordEmail({ email: "" });
	};

	return (
		<div className="popup popup--new-pass active">
			<button className="close-cross" onClick={closeRegistrationModalHandler}>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">придумайте пароль</span>
					</div>
				</div>

				<div className="popup__body">
					<form className="popup-form" onSubmit={formSubmitHandler}>
						<Input
							id="code"
							type="number"
							classes="popup-form__input"
							placeholder="Код"
							value={codeValue}
							invalid={codeErrorVisible}
							errors={codeErrors}
							onChangeValue={onCodeValueChange}
							onBlur={onCodeBlur}
						/>
						<Input
							id="password"
							type="password"
							classes="popup-form__input"
							placeholder="Придумайте пароль"
							value={passwordValue}
							invalid={passwordErrorVisible}
							errors={passwordErrors}
							onChangeValue={onPasswordValueChange}
							onBlur={onPasswordBlur}
						/>
						<Input
							id="passwordConfirmation"
							type="password"
							classes="popup-form__input"
							placeholder="Повторите пароль"
							value={passwordConfirmationValue}
							invalid={passwordConfirmationErrorVisible}
							errors={passwordConfirmationErrors}
							onChangeValue={onPasswordConfirmationValueChange}
							onBlur={onPasswordConfirmationBlur}
						/>

						<div className="popup-form__btns">
							<button
								type="submit"
								className="btn secondary"
								disabled={sending}
							>
								{sending ? <Loader /> : "Продолжить"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export { CreatePassword };
