import { ValidateInputValue } from "../types";

export function validateInputValue({ value, checks }: ValidateInputValue) {
	const errors: string[] = [];

	checks.forEach( check => {
		const isReverseCheck = check.reverseCheck;

		let isValid = check.regexp.test(value);

		if (isReverseCheck) {
			isValid = !check.regexp.test(value);
		}

		if (!isValid) {
			errors.push(check.errorMessage);
		}
	} );

	return errors;
}